import * as React from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import {Button, H4} from "@blueprintjs/core";

import {CardType, IItem, RecommendType, ICategoryInfo, BoardCategory} from "../common/Domains";
import Recommend from "./view/Recommend";
import {RecommendCategory as SpecialRecommendCategory} from "../special/RecommendCategory";
import {RecommendCategory as ItemRecommendCategory} from "../item/view/RecommendCategory";

import "./Home.css";
import 'pure-react-carousel/dist/react-carousel.es.css';
import {SITE_TITLE} from "../common/Constant";
import {Helmet} from "react-helmet";
import {PureCarousel} from "../../plugins/PureCarousel";
import {CardAlign, ICardData, ICardTag} from "../card/CardDomains";
import HBox from "../card/HBox";

interface IItemProps extends RouteComponentProps {
    id: number,
    item: IItem
}

class Home extends React.Component<IItemProps, {}> {
    constructor(props: IItemProps) {
        super(props);
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public render() {
        return (
            <div className="home-container">
                <Helmet>
                    <title>{ SITE_TITLE }</title>
                    <link rel="canonical" href={ window.location.href } />
                    <meta name="description" content={"Home"} />
                    <meta property="og:url" content={ window.location.href } />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={"Home, " + SITE_TITLE } />
                    <meta property="og:description" content={"Home"} />
                </Helmet>
                <div className="grid-container">
                    <div className="default-grid-container">
                        <PureCarousel width={100} height={350} totalSlide={5} isPlaying={true} interval={7000} >
                            <div className="topic-container pad-t-10">
                                <div className="main-topic">Hello</div>
                                <div className="sub-topic">소중한 공간을 꾸밀 수 있는 다양한 제품들을 만나보세요.</div>
                                <div className="main-topic-description group-justify pad-w-1">
                                    작은 선반에 올려 놓을 수 있는 소품부터 주방, 테이블에 사용할 수 있는 다양한 제품들을 만나보세요.
                                </div>
                            </div>
                            <HBox data={{
                                id: "1",
                                link: {
                                    path: "",
                                    localParams: {}
                                },
                                images: [{
                                    fullPath: "/slider/serax_surface_dish_1.jpg"
                                }],
                                date: "",
                                title: "Serax, Surface Green Dishes",
                                subTitle: "",
                                subSubTitle: "",
                                description: "밝거나 어두운 식기들을 조화롭게 배치한 후, 봄에 어울리는 그린으로 포인트를 줍니다. 자연을 닮은 거친 도자기의 표면을 손으로 느끼며 즐거운 시간을 보내보세요.",
                                author: "",
                                tags: [] as ICardTag[]
                            } as ICardData} cover={true} large={true} titleLine={3} descriptionLine={5} align={CardAlign.BOTTOM_LEFT} />
                            <HBox data={{
                                id: "2",
                                link: {
                                    path: "",
                                    localParams: {}
                                },
                                images: [{
                                    fullPath: "/slider/serax_merci_round_bowl.jpg"
                                }],
                                date: "",
                                title: "Serax, Merci Round Bowls",
                                subTitle: "",
                                subSubTitle: "",
                                description: "동해 바다, 강릉을 닮은 볼입니다. 유약의 흐름대로 매끄러움과 거친 느낌이 불규칙적인 식기는 모래사장에 부서지는 파도를 닮았습니다. 식기 하나 하나가 다른 모습으로 탄생합니다.",
                                author: "",
                                tags: [] as ICardTag[]
                            } as ICardData} cover={true} large={true} titleLine={3} descriptionLine={5} align={CardAlign.BOTTOM_RIGHT} />
                            <HBox data={{
                                id: "2",
                                link: {
                                    path: "",
                                    localParams: {}
                                },
                                images: [{
                                    fullPath: "/slider/serax_pure_rosegold_cutlery.jpg"
                                }],
                                date: "",
                                title: "Copper Stonewashed Pure Series",
                                subTitle: "",
                                subSubTitle: "",
                                description: "디자이너 Pascale Naessens이 디자인한 퓨어 시리즈는 중후한 톤과 색상으로 식탁을 한층 차분하고, 고급스럽게 만들어 줍니다. ",
                                author: "",
                                tags: [] as ICardTag[]
                            } as ICardData} cover={true} large={true} titleLine={3} descriptionLine={5} align={CardAlign.BOTTOM_LEFT} />
                            <HBox data={{
                                id: "2",
                                link: {
                                    path: "",
                                    localParams: {}
                                },
                                images: [{
                                    fullPath: "/slider/serax_double_wall_tea_cup.jpg"
                                }],
                                date: "",
                                title: "Serax, Double Wall Glass + Tea Strainer",
                                subTitle: "",
                                subSubTitle: "",
                                description: "차 본래의 맛을 우려주는 세락스만의 더블월 글라스는 유리필터로 투명하고 순수한 차를 즐길 수 있게 합니다.",
                                author: "",
                                tags: [] as ICardTag[]
                            } as ICardData} cover={true} large={true} titleLine={3} descriptionLine={5} align={CardAlign.BOTTOM_RIGHT} />
                        </PureCarousel>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container pad-t-3">
                        <div className="header-wrapper-2">
                            <H4 className="title">Recommended</H4>
                            <div className="shops-wrapper">
                                <Recommend />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="topic-container">
                            <div className="sub-topic group-center pad-h-1 pad-w-1-half">오브제의 관계, 어울림으로 집안을 채우다.</div>
                            <div className="sub-topic-description group-center pad-w-1">
                                우리가 소비하는 다양한 제품이 한 공간에서 어울리도록 배치될 때 더욱 만족감 느끼게 됩니다. 우리가 바라보는 제품들 간의 미묘한 느낌을 차분히 느껴보세요.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container pad-t-4">
                        <div className="article-container grid-w-4 grid-right pad-l-2">
                            <div className="header-wrapper-2">
                                <H4 className="title">Our Picks</H4>
                            </div>
                            <div className="items-wrapper">
                                <ItemRecommendCategory recommendType={RecommendType.DISCOVER} categoryInfo={{} as ICategoryInfo} cardType={CardType.VERTICAL_BOX} dataLimit={3} descriptionLine={4} interactive={false} width={100} />
                            </div>
                        </div>
                        <div className="items-container header-wrapper-1 grid-w-6 grid-left">
                            <H4 className="title">
                                <Link to="/item/Home">Products</Link>
                            </H4>
                            <div className="items-wrapper">
                                <ItemRecommendCategory recommendType={RecommendType.STAFF} categoryInfo={{} as ICategoryInfo} cardType={CardType.SMALL_HORIZON_BOX} dataLimit={15} descriptionLine={4} interactive={false} width={23} />
                            </div>
                        </div>
                        <div className="grid-clear-both">&nbsp;</div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container pad-h-3">
                        <div className="topic-container">
                            <div className="sub-topic group-center pad-h-1 pad-w-1-half">제품에서 환경을 생각해 봅니다.</div>
                            <div className="sub-topic-description group-center pad-w-1">
                                우리의 가치는 변화하고, 삶을 지속 가능하게 해주는 것들을 생각해 볼때 우리가 사용하는 작은 것에서 큰 변화를 이룹니다. 우리의 색은 그린입니다.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container pad-h-6">
                        <div className="header-wrapper-2">
                            <H4 className="title">
                                <Link to="/shop/Home">Special</Link>
                            </H4>
                            <div className="items-wrapper">
                                <SpecialRecommendCategory recommendType={RecommendType.PICK} categoryId={BoardCategory.SPECIAL} cardType={CardType.VERTICAL_BOX} width={100} dataLimit={4} descriptionLine={4} interactive={false} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container pad-b-2">
                        <div className="topic-container">
                            <div className="sub-topic group-center pad-h-1 pad-w-1-half">Discover The Collection</div>
                            <div className="group-center">
                                <Link to="/items/tableware"><Button className="button-s-2" minimal={true} large={true}>둘러보기</Button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;