import * as React from "react";

import "node_modules/react-quill/dist/quill.snow.css";
import "../../common/css/quill-editor.css";
import "./AddItem.css";
import EditItem from "./EditItem";
import {IItem, ItemStatus} from "../common/Domains";
import {EDIT_DEFAULT_ITEM_CONTENT} from "../common/Constant";
import {RouteComponentProps, withRouter} from "react-router";
import {Mutation} from "react-apollo";
import {H5, IToastProps, Position, Toast, Toaster} from "@blueprintjs/core";
import {gql} from "apollo-boost";
import {ITEM_QUERY} from "./Item";
import {UPDATE_ITEM_QUERY} from "./ModifyItem";
import {getUserInfo, isBlockedUser} from "../common/UserInfo";
import {redirectCustom, redirectRoot} from "../utils/PageFolwUtils";

const isEnableAutoSave = false;

const ADD_ITEM_QUERY = gql `
    mutation AddItem(
        $item: InputItem
    ) {
        addItem(
            item: $item
        ) {
            itemId
            groupId
            category {
                categoryId
                name
            }
            itemItemId
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            brand {
                brandId
                korName
                engName
                desc
            }
            title
            description
            content
            quantity
            price
            discountPrice
            discount
            discountUnit
            status
            isDelete
            itemEnv {
                envId
                model
                origin
                manufacturer
                material
                size
                composition
                url
            }
            itemImages {
                itemImageId
                image {
                    imageId
                    realName
                }
                isDefault
                createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

interface IProps extends RouteComponentProps {
    groupId?: number
    authorNo?: number
}

interface IState {
    itemId: number
    toasters: IToastProps[]
}

class AddItem extends React.Component<IProps, IState> {
    private toaster: Toaster;

    private refToasterHandler = {
        toaster: (ref: Toaster) => this.toaster = ref
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            itemId: -1,
            toasters: []
        }
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public render() {
        if(isBlockedUser()) {
            redirectRoot();
        }

        const { groupId } = this.props.match.params as IProps;
        const { authorNo } = (this.props.location.state || { authorNo: null }) as IProps;

        const { itemId } = this.state;

        const currentUser = getUserInfo();

        if(groupId && groupId > 0 && authorNo !== currentUser.no) {
            return <div className="pad-t-3">error... notice! invalid author</div>;
        }

        const item = {
            groupId: groupId ? groupId : -1,
            title: "",
            brand: {
                korName: ""
            },
            description: "",
            category: {
                categoryId: -1
            },
            content: EDIT_DEFAULT_ITEM_CONTENT,
            quantity: 0,
            price: 0,
            discountPrice: 0,
            discount: 0,
            discountUnit: "%",
            itemEnv: {
                model: "",
                origin: "",
                manufacturer: "",
                material: "",
                size: "",
                composition: "",
                url: "",
            }
        } as IItem;

        return (
            <div className="default-grid-container add-item-container">
                <div className="pad-h-1">
                    <div className="header-wrapper-2">
                        <H5 className="title">New item</H5>
                    </div>
                </div>
                <Mutation
                    mutation={itemId > 0 ? UPDATE_ITEM_QUERY : ADD_ITEM_QUERY}
                    update={(cache: any, {data: {addItem, updateItem}}: any) => {
                        const cachedItemId = addItem ? addItem.itemId : updateItem.itemId;

                        cache.writeQuery({
                            query: ITEM_QUERY,
                            variables: {itemId: cachedItemId},
                            data: { item: addItem }
                        });
                    }}
                    onCompleted={this.onCompleteSave}
                >
                    {(saveItem: any) => (
                        <EditItem
                            isTemporary={false}
                            itemId={this.state.itemId}
                            item={item}
                            saveHandler={saveItem}
                            isEnableAutoSave={isEnableAutoSave}
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                        />
                    )}
                </Mutation>
                <Toaster position={Position.BOTTOM} ref={this.refToasterHandler.toaster}>
                    {
                        this.state.toasters.map((toast, idx) => {
                            return <Toast key={idx} message={toast.message} />;
                        })
                    }
                </Toaster>
            </div>
        );
    }

    private onCompleteSave = (data: any) => {
        this.toaster.show({message: "Success"});

        const item = data.addItem || data.updateItem as IItem;

        if(item.status === ItemStatus.TEMPORARY) {
            redirectCustom("/temporaryItem/" + item.itemId);
        }
        else {
            redirectCustom("/item/" + item.itemId);
        }
    }
}

export default withRouter(AddItem);