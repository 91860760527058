import * as React from "react";
import {Link} from "react-router-dom";

import "./Footer.css";

class Footer extends React.Component {
    public render() {
        return (
            <div className="footer-container margin-t-3">
                <div className="grid-container">
                    <div className="default-grid-container">
                        <section className="about-wrapper site-map grid-left">
                            <span className="main-menu">Info</span>
                            <ul>
                                <li><Link to="/aboutus">About</Link></li>
                                <li><Link to="/privacyPolicy">Privacy policy</Link></li>
                                <li><Link to="/board/news">News</Link></li>
                            </ul>
                        </section>
                        <section className="about-wrapper site-map grid-left">
                            <span className="main-menu">Sns</span>
                            <ul>
                                <li><a href="https://www.instagram.com/jiyun_shop/" target="_blank">Instagram</a></li>
                                <li><a href="https://www.youtube.com/channel/UCBvy1lrTI_c04BWM6xRGBnQ" target="_blank">Youtube</a></li>
                            </ul>
                        </section>
                        <section className="about-wrapper site-map grid-left">
                            <span className="main-menu">Special</span>
                            <ul>
                                <li><Link to="/special/home">product</Link></li>
                            </ul>
                        </section>
                        <section className="about-wrapper site-map grid-left">
                            <span className="main-menu">Stories</span>
                            <ul>
                                <li><Link to="/stories/home">Stories</Link></li>
                            </ul>
                        </section>
                        <section className="about-wrapper site-map grid-left">
                            <span className="main-menu">New In</span>
                            <ul>
                                <li><Link to="/item/home">New In</Link></li>
                            </ul>
                        </section>
                        <section className="about-wrapper site-map grid-left">
                            <span className="main-menu">Products</span>
                            <ul>
                                <li><Link to="/items/tableware">Tableware</Link></li>
                                <li><Link to="/items/kitchen">Kitchen</Link></li>
                                <li><Link to="/items/bathroom">Bathroom</Link></li>
                                <li><Link to="/items/home">Home</Link></li>
                                <li><Link to="/items/object">Object</Link></li>
                                <li><Link to="/items/outdoor">Outdoor</Link></li>
                            </ul>
                        </section>
                        <section className="about-wrapper site-map grid-left">
                            <span className="main-menu">Brands</span>
                            <ul>
                                <li><Link to="/">brand</Link></li>
                            </ul>
                        </section>
                        <div style={{clear: "left"}} />
                    </div>
                </div>
                <div className="company-info">
                Owner 양철오 Privacy Officer 양철오, 010-3395-5143 yco2000@naver.com 충청남도 천안시 서북구 차암동 550 114-1904<br />
                License 470-50-00577, 2020-충남천안-22
                </div>
                <div className="copyright pad-t-2">
                    Copyright © 2020 Jiyunshop. All rights reserved.
                </div>
            </div>
        );
    }
}

export default Footer;