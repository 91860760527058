import * as React from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {Button, H3, H5, HTMLSelect, Intent, Popover, PopoverInteractionKind, Tab, TabId, Tabs} from "@blueprintjs/core";
import {distanceInWordsToNow} from "date-fns";
import {Helmet} from 'react-helmet';
import * as numeral from "numeral";
import "./Item.css";
import Summary from "./view/sub/summary/Summary";
import Comments from "./view/sub/comment/Comments";
import Environment from "./Environment";
import {gql} from "apollo-boost";
import {Mutation, Query} from "react-apollo";
import {Profile, ProfileType} from "../user/Profile";
import {ToggleItemLikeWrapper} from "./Like";
import {getUserInfo, isBlockedUser} from "../common/UserInfo";
import {HOST_BASE_URL, SITE_TITLE, THOUSANDS_COMMA_FORMAT} from "../common/Constant";
import {SharePopover} from "../share/SharePopover";
// import {UserReport} from "../report/UserReport";
// import {IItem, IItemImage} from "../common/Domains";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";
import {CardAlign, getPreviewImageIds, ICardData} from "../card/CardDomains";
import {ShoppingGuide} from "./ShoppingGuide";
import ItemHBox from "../card/ItemHBox";
import {ICategoryInfo, IItemImage, IItemOption} from "../common/Domains";

export const ITEM_QUERY = gql `
    query Item($itemId: Int) {
        item(itemId: $itemId) {
            itemId
            groupId
            category {
                categoryId
                name
            }
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            brand {
                brandId
                korName
                engName
                desc
            }
            itemItemId
            title
            description
            content
            quantity
            price
            discountPrice
            discount
            discountUnit
            status
            isDelete
            itemEnv {
                envId
                model
                origin
                manufacturer
                material
                size
                composition
                url
            }
            itemOptions {
                optionId
                name
                price
                quantity
                order
                isEnable
            }
            itemImages {
                itemImageId
               image {
                   imageId
                   realName
               }
               isDefault
               createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

export const DELETE_ITEM_QUERY = gql `
    mutation DeleteItem($item: InputItem) {
        deleteItem(item: $item) {
            itemId
        }
    }
`;

interface IItemProps extends RouteComponentProps {
    itemId: number,
    categoryInfo?: ICategoryInfo
}

interface IItemState {
    selectedSubTabId: TabId
}

class Item extends React.Component<IItemProps, IItemState> {
    constructor(props: IItemProps) {
        super(props);

        this.state = {
            selectedSubTabId: "comment"
        };

        this.goBackHandler = this.goBackHandler.bind(this);
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public render() {
        const { itemId } = this.props.match.params as IItemProps;
        // const { categoryInfo } = this.props.location.state as IItemProps;

        if(!itemId) {
            return <div>error...</div>;
        }

        const newItemId = Number(itemId);

        return (
            <Query
                query={ITEM_QUERY}
                variables={{ itemId: newItemId }}
                notifyOnNetworkStatusChange={true}
                fetchPolicy="cache-and-network"
            >
                {({loading, error, data}: any) => {
                    if(loading) {
                        return <LoadingPage />;
                    }

                    if(error) {
                        return <ErrorPage />;
                    }

                    const { item } = data || { item: null };

                    if(!item || item.itemId <= 0) {
                        return <ErrorPage />;
                    }

                    const imagePath = item.author.imageUrl;
                    const distanceCreatedDate = distanceInWordsToNow(item.createdDate);
                    // const distanceModifiedDate = distanceInWordsToNow(item.modifiedDate);

                    const defaultImages = item.itemImages.filter((itemImage: IItemImage) => {
                        return itemImage.isDefault;
                    })
                        .map((itemImage: IItemImage) => {
                            return itemImage.image;
                        });

                    const images = getPreviewImageIds(defaultImages);

                    return (
                        <div className="item-wrapper">
                            <Helmet>
                                <title>{item.title + ", " + SITE_TITLE }</title>
                                <link rel="canonical" href={ window.location.href } />
                                <meta name="description" content={item.title} />
                                <meta property="og:url" content={ window.location.href } />
                                <meta property="og:type" content="website" />
                                <meta property="og:title" content={item.title + ", " + SITE_TITLE } />
                                <meta property="og:description" content={item.description} />
                                <meta property="og:image" content={imagePath} />
                            </Helmet>
                            <div className="grid-container pad-t-3">
                                <div className="default-grid-container">
                                    <div className="header pad-h-1">
                                        <div className="name-wrapper">
                                            <H5 className="name">
                                                <span className="korName">{item.brand.korName}</span> / <span
                                                className="desc">{item.brand.desc}</span>
                                            </H5>
                                        </div>
                                        <H3 className="title">
                                            {item.title}
                                        </H3>
                                        <div className="description">
                                            {item.description}
                                        </div>
                                        <div className="author-item-container pad-h-1">
                                            <Profile user={item.author}
                                                     profileType={ProfileType.SIMPLE}
                                                     large={false} isForceUserDetail={true} disableDescription={true}
                                            >
                                                    <span className="date" title="Created date">{distanceCreatedDate}</span>
                                                    {/*{(distanceCreatedDate !== distanceModifiedDate) && (*/}
                                                    {/*    <span className="date" title="Modified date">,&nbsp;{distanceModifiedDate}</span>*/}
                                                    {/*)}*/}
                                                    {/*<span className="divider"> / </span>*/}
                                                    {/*<span className="counter" title="View Count">{numeral(item.counter.count).format(HIT_COUNTER_FORMAT)}</span>*/}
                                                    <span className="divider"> / </span>
                                                    <span className="like" title="Like">
                                                        <ToggleItemLikeWrapper item={item} />
                                                    </span>
                                                    <span className="divider"> / </span>
                                                    <SharePopover url={HOST_BASE_URL + "/item/" +  itemId} title={item.title} />
                                                    {/*<span className="divider"> / </span>*/}
                                                    {/*<UserReport targetType={UserReportTargetType.ITEM} targetCategoryId={item.category.categoryId} targetId={itemId} />*/}
                                            </Profile>
                                        </div>
                                        <div className="grid-container">
                                            <div className="default-grid-container" >
                                                <div className="pad-h-1 pad-w-1">
                                                    <ItemHBox data={{
                                                        images,
                                                    } as ICardData} align={CardAlign.LEFT} large={true}>
                                                        <div className="item-info-wrapper pad-t-2">
                                                            <span className="price pad-h-1">₩{numeral(item.price).format(THOUSANDS_COMMA_FORMAT)}</span> <span className="discount-price">₩{numeral(item.discountPrice).format(THOUSANDS_COMMA_FORMAT)}</span> <span className="discount">({item.discount}</span><span className="discount-unit">{item.discountUnit})</span>
                                                        </div>
                                                        <div className="pad-h-1">{item.quantity > 0 ? "재고 있음" : "재고 없음"}</div>
                                                        <div className="pad-h-1">
                                                            배송비 3,000원 (50,000원 이상 구매 시 무료)<br />
                                                            제주 및 도서 산간 4,000원 추가
                                                        </div>
                                                        <div className="pad-t-1">
                                                            <OptionWrapper options={item.itemOptions} quantity={item.quantity}/>
                                                        </div>
                                                        <div className="pad-t-1">
                                                            {item.itemEnv.url &&
                                                                <a href={item.itemEnv.url} target="_blank">
                                                                    <Button className="button-s-3" minimal={true} large={true} disabled={true} fill={true}>스마트스토어 상품 보기</Button>&nbsp;
                                                                </a>
                                                            }
                                                            <Button className="button-s-2" minimal={true} large={true} disabled={true} fill={true}>BUY NOW (준비 중)</Button>&nbsp;
                                                            <Button className="button-s-2" minimal={true} large={true} disabled={true} fill={true}>ADD TO SHOPPING BAG (준비 중)</Button>
                                                        </div>
                                                        <div className="pad-t-1">
                                                            * 제품 구매는 네이버 스마트스토어에서 가능합니다.<br />
                                                            * <a href={item.itemEnv.url} target="_blank">상품 바로가기</a>
                                                            * <a href="https://smartstore.naver.com/jiyunshop" target="_blank">지윤샵 바로가기</a>
                                                        </div>
                                                    </ItemHBox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container" >
                                    <div className="pad-h-1 pad-w-1-half">
                                        <Summary content={item.content}>
                                            <Environment env={item.itemEnv}/>
                                            <ShoppingGuide />
                                            <UserActions groupId={item.groupId} itemId={item.itemId} userNo={item.author.no} />
                                        </Summary>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container border-top">
                                    <div className="pad-h-1 pad-w-1-half">
                                        <Profile user={item.author} profileType={ProfileType.DEFAULT} large={false} isForceUserDetail={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container">
                                    <div className="sub-tap-wrapper pad-h-1 pad-w-1-half">
                                        <Tabs id="sub-info" large={true} animate={true} selectedTabId={this.state.selectedSubTabId}
                                              onChange={this.subInfoChanger}>
                                            <Tab id="comment" title={"Review"} panel={<Comments itemId={item.itemId} />} />
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    }

    public subInfoChanger = (selectedSubTabId: TabId) => {
       this.setState({
           selectedSubTabId
       });
    };

    private goBackHandler = () => {
        this.props.history.back();
    };
}

export default withRouter(Item);

interface IOptionProps {
    options: [ IItemOption ]
    quantity: number
}

const OptionWrapper = ({ options, quantity } : IOptionProps) => {
    if(options && options.length > 0) {
        return (
            <HTMLSelect large={true} minimal={false} fill={true} disabled={false}>
                <optgroup>
                    <option value="">Select</option>
                    {(options || []).map((option: IItemOption) => {
                        return <option key={option.optionId} value={option.optionId}>{option.name}</option>
                    })}
                </optgroup>
            </HTMLSelect>
        );
    }

    if(quantity > 0) {
        return (
            <HTMLSelect large={true} minimal={false} fill={true} disabled={false}>
                <optgroup>
                    <option value="">Select</option>
                    {(Array.from(Array(quantity).keys()) || []).map((option: number) => {
                        return <option key={option} value={option + 1}>{option + 1}</option>
                    })}
                </optgroup>
            </HTMLSelect>
        );
    }

    return (
       <></>
    );
}

interface IUserActions {
    groupId: number
    itemId: number
    userNo: number
    categoryInfo?: ICategoryInfo
}

const UserActions = (props: IUserActions) => {
    const currentUser = getUserInfo();

    const { itemId, userNo, categoryInfo} = props;

    // const newGroupId = groupId > 0 ? groupId : itemId;
    const isEnableAction = currentUser.no === userNo;

    if(isBlockedUser()) {
        return (
            <div>
                <div className="group-center pad-h-3">
                    { isEnableAction &&
                    <>
                        <Button minimal={true} disabled={true}>List</Button>
                        {/*<Button minimal={true} disabled={true}>Add</Button>*/}
                        <Button minimal={true} disabled={true}>Modify</Button>
                        <Button minimal={true} disabled={true}>Delete</Button>
                    </>
                    }
                </div>
            </div>
        );
    }

    const categoryUrl = categoryInfo ? ((categoryInfo.largeCategory ? "/" + categoryInfo.largeCategory : "")
                        + (categoryInfo.middleCategory ? "/" + categoryInfo.middleCategory : "")
                        + (categoryInfo.smallCategory ? "/" + categoryInfo.smallCategory : "")) : "";

    return (
        <div>
            <div className="group-center pad-h-3">
            { isEnableAction &&
            <>
                <Link to={"/items" + categoryUrl}>
                    <Button minimal={true}>List</Button>
                </Link>
                {/*<Link to={{ pathname: "/item/add/" + newGroupId, state: { authorNo: userNo }}}>*/}
                {/*    <Button minimal={true}>Add</Button>*/}
                {/*</Link>*/}
                <Link to={"/item/update/" + itemId}>
                    <Button minimal={true}>Modify</Button>
                </Link>
                <DeletePopover itemId={itemId} userNo={userNo} />
            </>
            }
            </div>
        </div>
    );
};

interface IDeletePopover {
    itemId: number
    userNo: number
}

export const DeletePopover = (props: IDeletePopover) => {
    const {itemId, userNo } = props;

    const isDisabled = isBlockedUser();

    return (
        <Mutation
            mutation={DELETE_ITEM_QUERY}
            onCompleted={(data: any) => {
                // window.location.href = HOST_BASE_URL + "/items";
                window.history.back();
            }}
        >
        {(deleteItem: any) => (
            <Popover
                interactionKind={PopoverInteractionKind.CLICK}
                popoverClassName="bp3-popover-content-sizing"
            >
                <Button minimal={true} disabled={isDisabled}>Delete</Button>
                <div>
                    <H5>삭제</H5>
                    <p>삭제하시겠습니까?</p>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
                        <Button className="bp3-popover-dismiss" style={{ marginRight: 10 }}>
                            Cancel
                        </Button>
                        <Button intent={Intent.PRIMARY} className="bp3-popover-dismiss" disabled={isDisabled} onClick={() => {
                            deleteItem({
                                variables: {
                                    item: {
                                        itemId,
                                        author: {
                                            no: userNo
                                        }
                                    }
                                }
                            });
                        }}>
                            Delete
                        </Button>
                    </div>
                </div>
            </Popover>
        )}
        </Mutation>
    );
};