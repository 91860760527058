import * as React from "react";
import {UnSignupUserMenu} from "../menus/UnSignupUserMenu";
import {getUserInfo, isUserLogin} from "../common/UserInfo";
import {redirectRoot} from "../utils/PageFolwUtils";

import "./Login.css";

interface IProps {
    isOpenUserInfoHandler?: (isOpen: boolean) => void
}

interface IState {
    dummy?: number
}

class Login extends React.Component<IProps, IState> {
    public render() {
        const currentUser = getUserInfo();
        const isLogined = isUserLogin(currentUser);

        if(isLogined) {
            redirectRoot();
        }

        return (
            <div className="login-container pad-h-10">
                <UnSignupUserMenu />
            </div>
        );
    }
}

export default Login;