import * as React from "react";
import {
    CardType,
    ShopCategory,
    IShop,
    IShopImage, RecommendType, IShopRecommend
} from "../../common/Domains";
import {Query} from "react-apollo";
import {ICardData} from "../../card/CardDomains";
import {GetCard} from "../../item/view/RecommendCategory";
import {RECOMMEND_SHOP_QUERY} from "./Recommend";
import {ErrorPage} from "../../utils/LoadingAndError";

interface IProps {
    recommendType: RecommendType
    categoryId: ShopCategory
    cardType: CardType
    dataLimit: number
    titleLine?: number
    descriptionLine?: number
    interactive: boolean
    width?: number
}

export const RecommendCategory = (props: IProps) => {
    const { recommendType, categoryId, cardType, dataLimit, titleLine, descriptionLine, interactive, width } = props;

    return (
        <Query
            query={RECOMMEND_SHOP_QUERY}
            variables={{
                recommendType,
                categoryId,
                offset: 0,
                limit: dataLimit
            }}

            fetchPolicy="cache-and-network"
        >
            {({loading, error, data, fetchMore}: any) => {
                if(error) {
                    return <ErrorPage />;
                }

                const { recommendShops } = data || { recommendShops: [] };

                if(!recommendShops || recommendShops.length === 0) {
                    const emptyData = {
                        id: "0",
                        link: {
                            path: "/shop/home"
                        },
                        title: "No results"
                    } as ICardData;

                    return (
                        <>
                            <GetCard key={emptyData.id} data={emptyData} cardType={cardType} interactive={interactive} width={width} isEmpty={true} />
                            <div style={{clear: "left"}} />
                        </>
                    );
                }

                return (
                    <>
                        {
                            (recommendShops || []).map((shopRecommend: IShopRecommend) => {
                                const shop = shopRecommend.shop as IShop;

                                const images = shop.shopImages.filter((shopImage: IShopImage) => {
                                    return shopImage.isDefault;
                                })
                                .map((shopImage: IShopImage) => {
                                    return shopImage.image;
                                });

                                const boxData = {
                                    id: shop.shopId + "",
                                    link: {
                                        path: "/shop/" + shop.shopId,
                                        localParams: {}
                                    },
                                    images,
                                    date: shop.createdDate,
                                    title: shop.name,
                                    description: shop.description,
                                    author: shop.author.displayName
                                } as ICardData;

                                return <GetCard key={boxData.id} data={boxData} cardType={cardType} titleLine={titleLine} descriptionLine={descriptionLine} interactive={interactive} width={width} isEmpty={false} />
                            })
                        }
                        <div style={{clear: "left"}} />
                    </>
                );
            }}
        </Query>
    );
};