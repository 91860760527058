import {useQuery} from "react-apollo-hooks";
import {ICategory} from "../common/Domains";
import {LOAD_DATA_COUNT} from "../common/Constant";
import * as InfiniteScroll from "react-infinite-scroller";
import * as React from "react";
import {
    Button,
    Classes,
    ControlGroup,
    Dialog,
    FormGroup,
    InputGroup
} from "@blueprintjs/core";
import {gql} from "apollo-boost";
import {useState} from "react";

import "./Categories.css";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";
//
// const CATEGORIES_QUERY = gql `
//     query Categories($keyword: String, $offset: Int, $limit: Int) {
//         categories(keyword: $keyword, offset: $offset, limit: $limit) {
//             categoryId
//             name
//             desc
//         }
//     }
// `;

const PATH_CATEGORIES_QUERY = gql `
    query PathCategories($keyword: String, $offset: Int, $limit: Int) {
        pathCategories(keyword: $keyword, offset: $offset, limit: $limit) {
            categoryId
            key
            name
            desc
        }
    }
`;

interface ISearchCategoryProps {
    onSearch: (category: ICategory) => void;
}

export const SearchCategory = (props: ISearchCategoryProps) => {
    const { onSearch } = props;

    const [ isDialogOpen, setIsDialogOpen ] = useState(false);
    const [ currentKeyword, setCurrentKeyword] = useState("");
    const [ keyword, setKeyword] = useState("");

    const openHandler = () => {
        setIsDialogOpen(!isDialogOpen);
    };

    const closeHandler = () =>  {
        setIsDialogOpen(!isDialogOpen);
    };

    const keywordKeyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === "Enter") {
            searchHandler();
        }
    };

    const searchHandler = () => {
        setKeyword(currentKeyword);
    };

    const selectCategoryItemHandler = (category: ICategory) => {
        onSearch(category);

        closeHandler();
    };

    return (
        <>
            <Button onClick={openHandler}>카테고리 검색</Button>
            <Dialog
                onClose={closeHandler}
                title="카테고리 검색"
                isOpen={isDialogOpen}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="category"
                        label="카테고리를 검색해주세요."
                    >
                        <ControlGroup fill={false}>
                            <InputGroup name="category"
                                        className="input-category"
                                        placeholder="카테고리를 입력해주세요."
                                        large={true}
                                        onKeyDown={keywordKeyPressHandler}
                                        onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                            setCurrentKeyword(event.currentTarget.value);
                                        }}
                                        defaultValue=""
                            />
                            <Button
                                onClick={searchHandler}
                            >
                                Search
                            </Button>
                        </ControlGroup>
                    </FormGroup>
                    <div className="category-list-container">
                        <Categories categoryName={keyword} selectItemHandler={selectCategoryItemHandler} />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

interface ISearchCategoryItemsProps {
    categoryName: string
    selectItemHandler: (category: ICategory) => void
}

export const Categories = (props: ISearchCategoryItemsProps) => {
    const { categoryName, selectItemHandler } = props;

    if(!categoryName) {
        return <CategoryBox />;
    }

    const { loading, error, data, fetchMore, networkStatus } = useQuery(PATH_CATEGORIES_QUERY, {
        variables: {
            keyword: categoryName,
            offset: 0,
            limit: 12
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: false
    });

    if(loading || networkStatus === 4) {
        return <LoadingPage />;
    }

    if(error) {
        return <ErrorPage />;
    }

    const { pathCategories } = data || { pathCategories: null };

    if(!pathCategories || pathCategories.length === 0) {
        return <CategoryBox />;
    }

    const offset = pathCategories ? pathCategories.length : 0;
    const hasMore = (offset !== 0 && offset % LOAD_DATA_COUNT === 0) ? true : false;

    const loadMoreHandler = () => {
        fetchMore({
            variables: {
                offset
            },
            updateQuery: (prev: any, { fetchMoreResult}: any) => {
                if(!fetchMoreResult) {
                    return prev;
                }
                return Object.assign({}, prev, {
                    pathCategories: [...prev.pathCategories, ...fetchMoreResult.pathCategories]
                });
            }
        });
    };

    return (
        <div className="category-list-wrapper">
            <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={loadMoreHandler}
                hasMore={hasMore}
                loader={<div key="0">loading...</div>}
                useWindow={false}
            >
                {
                    (pathCategories || []).map((category: ICategory) => {
                        return <CategoryBox key={category.categoryId + "d"} category={category} selectItemHandler={selectItemHandler} />;
                    })
                }
            </InfiniteScroll>
            <LoadMore loadMoreHandler={loadMoreHandler} />
        </div>
    );
};

interface ICategoryBoxProps {
    category?: ICategory
    selectItemHandler?: (category: ICategory) => void
}

const CategoryBox = (props: ICategoryBoxProps) => {
    const { category, selectItemHandler } = props;

    if(!category) {
        return (
            <div className="category-box-wrapper group-center">
                No Results
            </div>
        );
    }

    return (
        <div className="category-box-wrapper" onClick={() => {
            if(selectItemHandler) {
                selectItemHandler(category);
            }
        }}>
            {category.name}
        </div>
    );
};

const LoadMore = ({ loadMoreHandler }: any) => {
    return (
        <div className="group-center pad-h-3">
            <Button minimal={true} onClick={loadMoreHandler} >Load more</Button>
        </div>
    );
};