import {IUser, UserAuthorityType} from "./Domains";

const STORE_KEY_USER_NO = "USER_NO";
const STORE_KEY_USER_NAME = "USER_NAME";
const STORE_KEY_USER_ROLE = "USER_ROLE";

// STORE/CLEAR USER
export const setUserInfo = (user: IUser) => {
    localStorage.setItem(STORE_KEY_USER_NO, user.no + "");
    localStorage.setItem(STORE_KEY_USER_NAME, user.displayName);
    if(user.userAuthorities.length > 0) {
        localStorage.setItem(STORE_KEY_USER_ROLE, user.userAuthorities[0].authorityName);
    }
};

export const getUserInfo = () => {
    return {
        no: Number(localStorage.getItem(STORE_KEY_USER_NO)),
        name: localStorage.getItem(STORE_KEY_USER_NAME),
        userAuthorities : [
            { authorityName: localStorage.getItem(STORE_KEY_USER_ROLE) }
        ]
    } as IUser;
};

export const resetUserInfo = () => {
    localStorage.setItem(STORE_KEY_USER_NO, "");
    localStorage.setItem(STORE_KEY_USER_NAME, "");
    localStorage.setItem(STORE_KEY_USER_ROLE, "");
};

// LOGIN
export const isUserLogin = (user: IUser) => {
    if(user) {
        return user.no ? true : false;
    }
    else {
        return getUserInfo().no ? true : false;
    }
};

// GRANTS
export const isBlockedUser = () => {
    const currentUser = getUserInfo();

    if(currentUser.userAuthorities[0].authorityName === UserAuthorityType.BLOCKED) {
        return true;
    }

    return false;
};

export const isAdminUser = () => {
    const currentUser = getUserInfo();

    if(currentUser.userAuthorities[0].authorityName === UserAuthorityType.ADMIN) {
        return true;
    }

    return false;
};