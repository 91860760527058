import * as React from "react";

import "node_modules/react-quill/dist/quill.snow.css";
import "../../common/css/quill-editor.css";
import "./AddItem.css";
import {Mutation, Query} from "react-apollo";
import {gql} from "apollo-boost";
import {RouteComponentProps} from "react-router";
import EditItem from "./EditItem";
import {H5, IToastProps, Position, Toast, Toaster} from "@blueprintjs/core";
import {ITEM_QUERY} from "./Item";
import {isBlockedUser} from "../common/UserInfo";
import {redirectCustom, redirectRoot} from "../utils/PageFolwUtils";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";
import {ItemStatus} from "../common/Domains";

const isEnableAutoSave = false;

export const UPDATE_ITEM_QUERY = gql `
    mutation UpdateItem(
        $item: InputItem
    ) {
        updateItem(
            item: $item
        ) {
            itemId
            groupId
            category {
                categoryId
            }
            itemItemId
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            brand {
                brandId
                korName
                engName
                desc
            }
            title
            description
            content
            quantity
            price
            discountPrice
            discount
            discountUnit
            status
            isDelete
            itemEnv {
                envId
                model
                origin
                manufacturer
                material
                size
                composition
                url
            }
            itemImages {
                itemImageId
                image {
                    imageId
                    realName
                }
                isDefault
                createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

interface IProps extends RouteComponentProps {
    itemId: number
}

interface IState {
    toasters: IToastProps[]
}

class ModifyItem extends React.Component<IProps, IState> {
    private toaster: Toaster;

    private refToasterHandler = {
        toaster: (ref: Toaster) => this.toaster = ref
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            toasters: []
        }
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public render() {
        if(isBlockedUser()) {
            redirectRoot();
        }

        const { itemId } = this.props.match.params as IProps;

        if(!itemId) {
            return <div>error...</div>;
        }

        const newItemId = Number(itemId);

        return (
            <div className="default-grid-container add-item-container">
                <div className="pad-h-1">
                    <div className="header-wrapper-2">
                        <H5 className="title">Modify item</H5>
                    </div>
                </div>
                <Query
                    query={ITEM_QUERY}
                    variables={{ itemId: newItemId }}
                    fetchPolicy="cache-and-network"
                >
                    {({loading, error, data}: any) => {
                        if(loading) {
                            return <LoadingPage />;
                        }

                        if(error) {
                            return <ErrorPage />;
                        }

                        const { item } = data || { item: null };

                        return (
                            <Mutation
                                mutation={UPDATE_ITEM_QUERY}
                                update={(cache: any, {data: {updateItem}}: any) => {
                                    const tempItem = cache.readQuery({
                                        query: ITEM_QUERY,
                                        variables: { itemId: updateItem.itemId }
                                    });

                                    if(tempItem) {
                                        cache.writeQuery({
                                            query: ITEM_QUERY,
                                            variables: {itemId: updateItem.itemId},
                                            data: { item: updateItem}
                                        });
                                    }
                                }}
                                onCompleted={this.onCompleteSave}
                            >
                                {(saveItem: any) => (
                                    <EditItem
                                        isTemporary={false}
                                        item={item}
                                        itemId={newItemId}
                                        saveHandler={saveItem}
                                        isEnableAutoSave={isEnableAutoSave}
                                        history={this.props.history}
                                        location={this.props.location}
                                        match={this.props.match}
                                    />
                                )}
                            </Mutation>
                        );
                    }}
                </Query>
                <Toaster position={Position.BOTTOM} ref={this.refToasterHandler.toaster}>
                    {
                        this.state.toasters.map((toast, idx) => {
                            return <Toast key={idx} message={toast.message} />;
                        })
                    }
                </Toaster>
            </div>
        );
    }

    private onCompleteSave = (data: any) => {
        this.toaster.show({message: "Success"});

        if(data.updateItem.status === ItemStatus.TEMPORARY) {
            redirectCustom("/temporaryItem/" + data.updateItem.itemId);
        }
        else {
            redirectCustom("/item/" + data.updateItem.itemId);
        }
    }
}

export default ModifyItem;