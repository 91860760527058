import * as React from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {Button, H3, Tab, TabId, Tabs} from "@blueprintjs/core";
import {distanceInWordsToNow} from "date-fns";
import Summary from "./view/sub/summary/Summary";
import Comments from "./view/sub/comment/Comments";
import Environment from "./Environment";
import {gql} from "apollo-boost";
import {Query} from "react-apollo";
import {Profile, ProfileType} from "../user/Profile";
import GoogleMap, {GoogleMapStyle} from "../map/GoogleMap";
import {getUserInfo, isBlockedUser} from "../common/UserInfo";
import {Helmet} from "react-helmet";

import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";
import {DeletePopover} from "./Shop";

import "./Shop.css";
import {SITE_TITLE} from "../common/Constant";

export const TEMPORARY_SHOP_QUERY = gql `
    query TemporaryShop($shopId: Int, $userNo: Int) {
        temporaryShop(shopId: $shopId, userNo: $userNo) {
            shopId
            groupId
            category {
                categoryId
            }
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            name
            description
            content
            type
            status
            isDelete
            shopEnv {
                envId
                location
                latitude
                longitude
                country
                isClosed
                openingTime
                closingTime
                homePageUrl
                telNumber
                keywords
            }
            shopImages {
                shopImageId
                image {
                    imageId
                    realName
                }
                isDefault
                createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

interface IShopProps extends RouteComponentProps {
    shopId: number
}

interface IShopState {
    selectedSubTabId: TabId
}

class TemporaryShop extends React.Component<IShopProps, IShopState> {
    constructor(props: IShopProps) {
        super(props);

        this.state = {
            selectedSubTabId: "comment"
        }

        this.goBackHandler = this.goBackHandler.bind(this);
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public render() {
        const currentUser = getUserInfo();
        const { shopId } = this.props.match.params as IShopProps;

        if(!shopId) {
            return <ErrorPage />;
        }

        const newShopId = Number(shopId);

        return (
            <Query
                query={TEMPORARY_SHOP_QUERY}
                variables={{ shopId: newShopId, userNo: currentUser.no }}
                notifyOnNetworkStatusChange={true}
                fetchPolicy="cache-and-network"
            >
                {({loading, error, data}: any) => {
                    if(loading) {
                        return <LoadingPage />;
                    }

                    if(error) {
                        return <ErrorPage />;
                    }

                    const { temporaryShop } = data || { temporaryShop: null };

                    if(!temporaryShop || temporaryShop.shopId <= 0) {
                        return <ErrorPage />;
                    }

                    const imagePath = temporaryShop.author.imageUrl;
                    const distanceCreatedDate = distanceInWordsToNow(temporaryShop.createdDate);
                    const distanceModifiedDate = distanceInWordsToNow(temporaryShop.modifiedDate);

                    return (
                        <div className="shop-wrapper">
                            <Helmet>
                                <title>{temporaryShop.title + ", " + SITE_TITLE }</title>
                                <link rel="canonical" href={ window.location.href } />
                                <meta name="description" content={temporaryShop.title} />
                                <meta property="og:url" content={ window.location.href } />
                                <meta property="og:type" content="website" />
                                <meta property="og:title" content={temporaryShop.title + ", " + SITE_TITLE } />
                                <meta property="og:description" content={temporaryShop.description} />
                                <meta property="og:image" content={imagePath} />
                            </Helmet>
                            <div className="grid-container pad-t-3">
                                <div className="default-grid-container">
                                    <div className="header pad-h-1">
                                        <H3 className="title">
                                            {temporaryShop.name}
                                        </H3>
                                        <div className="description">
                                            {temporaryShop.description}
                                        </div>
                                        <div className="author-item-container pad-h-1">
                                            <Profile user={temporaryShop.author}
                                                     profileType={ProfileType.SIMPLE}
                                                     large={false} isForceUserDetail={true} disableDescription={true}
                                            >
                                                    <span className="date" title="Created date">{distanceCreatedDate}</span>
                                                    {(distanceCreatedDate !== distanceModifiedDate) && (
                                                        <>
                                                            ,&nbsp;
                                                            <span className="date" title="Modified date">{distanceModifiedDate}</span>
                                                        </>
                                                    )}
                                            </Profile>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container">
                                    <div className="pad-h-1">
                                        <Environment env={temporaryShop.shopEnv}/>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container" >
                                    <div className="pad-h-1">
                                        <Summary content={temporaryShop.content}>
                                            <div className="pad-t-3">
                                                <GoogleMap mapId={temporaryShop.shopId}
                                                           latitude={Number(temporaryShop.shopEnv.latitude)}
                                                           longitude={Number(temporaryShop.shopEnv.longitude)}
                                                           level={11}
                                                           title={temporaryShop.name}
                                                           width={100}
                                                           widthUnit="%"
                                                           height={360}
                                                           heightUnit="px"
                                                           mapStyle={GoogleMapStyle.DEFAULT}
                                                />
                                            </div>
                                            <UserActions shopId={temporaryShop.shopId} userNo={temporaryShop.author.no} />
                                        </Summary>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container border-top">
                                    <div className="pad-h-1">
                                        <Profile user={temporaryShop.author} profileType={ProfileType.DEFAULT} large={false} isForceUserDetail={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container">
                                    <div className="pad-h-1">
                                        <Tabs id="sub-info" large={true} animate={true} selectedTabId={this.state.selectedSubTabId}
                                              onChange={this.subInfoChanger}>
                                            <Tab id="comment" title={"Review"} panel={<Comments shopId={temporaryShop.shopId} />} />
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    }

    public subInfoChanger = (selectedSubTabId: TabId) => {
       this.setState({
           selectedSubTabId
       });
    }

    private goBackHandler = () => {
        this.props.history.back();
    }
}

export default withRouter(TemporaryShop);

interface IUserActions {
    shopId: number
    userNo: number
}

const UserActions = (props: IUserActions) => {
    const currentUser = getUserInfo();

    const { shopId, userNo} = props;

    const isEnableAction = currentUser.no === userNo;

    if(isBlockedUser()) {
        return (
            <div>
                <div className="group-center pad-h-3">
                    { isEnableAction &&
                    <>
                        <Button minimal={true} disabled={true}>Modify</Button>
                        <Button minimal={true} disabled={true}>Delete</Button>
                    </>
                    }
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="group-center pad-h-3">
                { isEnableAction &&
                <>
                    <Link to={"/temporaryShop/update/" + shopId}>
                        <Button minimal={true}>Modify</Button>
                    </Link>
                    <DeletePopover shopId={shopId} userNo={userNo} />
                </>
                }
            </div>
        </div>
    );
};