import * as React from "react";

import "node_modules/react-quill/dist/quill.snow.css";
import "../../common/css/quill-editor.css";
import "./AddBoard.css";
import EditBoard from "./EditBoard";
import {BoardCategory, BoardType, getBoardCategoryId, IBoard} from "../common/Domains";
import {EDIT_DEFAULT_BOARD_CONTENT} from "../common/Constant";
import {RouteComponentProps, withRouter} from "react-router";
import {Mutation} from "react-apollo";
import {H5, IToastProps, Position, Toast, Toaster} from "@blueprintjs/core";
import {gql} from "apollo-boost";
import {BOARD_QUERY} from "./Board";
import {isBlockedUser} from "../common/UserInfo";
import {redirectCustom, redirectRoot} from "../utils/PageFolwUtils";
import {ErrorPage} from "../utils/LoadingAndError";

const isEnableAutoSave = false;

const ADD_BOARD_QUERY = gql `
    mutation AddBoard(
        $board: InputBoard
    ) {
        addBoard(
            board: $board
        ) {
            boardId
            groupId
            category {
                categoryId
            }
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            title
            description
            content
            type
            status
            isDelete
            boardEnv {
                envId
                isDisplay
                displayStartDate
                displayEndDate
            }
            boardImages {
                boardImageId
                image {
                    imageId
                    realName
                }
                isDefault
                createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

interface IProps extends RouteComponentProps {
    category: string
}

interface IState {
    toasters: IToastProps[]
}

class AddBoard extends React.Component<IProps, IState> {
    private toaster: Toaster;

    private refToasterHandler = {
        toaster: (ref: Toaster) => this.toaster = ref
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            toasters: []
        }
    }

    public render() {
        if(isBlockedUser()) {
            redirectRoot();
        }

        const { category } = this.props.match.params as IProps;

        if(!category) {
            return <ErrorPage message="Invalid request path" />;
        }

        const categoryId = getBoardCategoryId(category);

        const board = {
            title: "",
            description: "",
            category: {
                categoryId
            },
            type: categoryId === BoardCategory.NEWS ? BoardType.NEWS : BoardType.NONE,
            content: EDIT_DEFAULT_BOARD_CONTENT,
            boardEnv: {
                isDisplay: false,
                displayStartDate: "",
                displayEndDate: "",
            }
        } as IBoard;

        return (
            <div className="default-grid-container add-board-container">
                <div className="pad-h-1">
                    <div className="header-wrapper-2">
                        <H5 className="title">New {category}</H5>
                    </div>
                </div>
                <Mutation
                    mutation={ADD_BOARD_QUERY}
                    update={(cache: any, {data: {addBoard, updateBoard}}: any) => {
                        const cachedBoardId = addBoard ? addBoard.boardId : updateBoard.boardId;

                        cache.writeQuery({
                            query: BOARD_QUERY,
                            variables: {boardId: cachedBoardId},
                            data: { board: addBoard }
                        });
                    }}
                    onCompleted={this.onCompleteSave}
                >
                    {(saveBoard: any) => (
                        <EditBoard
                            isTemporary={false}
                            category={category}
                            boardId={-1}
                            board={board}
                            saveHandler={saveBoard}
                            isEnableAutoSave={isEnableAutoSave}
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                        />
                    )}
                </Mutation>
                <Toaster position={Position.BOTTOM} ref={this.refToasterHandler.toaster}>
                    {
                        this.state.toasters.map((toast, idx) => {
                            return <Toast key={idx} message={toast.message} />;
                        })
                    }
                </Toaster>
            </div>
        );
    }

    private onCompleteSave = (data: any) => {
        this.toaster.show({message: "Success"});

        const { category } = this.props.match.params as IProps;

        redirectCustom("/board/view/" + category + "/" + data.addBoard.boardId);
    }
}

export default withRouter(AddBoard);