import * as React from "react";
import {Redirect} from "react-router";
import {getToken} from "../../store/cache/Token";

interface IProps {
    dummy?: number
}

interface IState {
    loading: boolean,
    redirect: boolean
}

export const WithAuth = (ComponentToProtect: any) => {
    return class extends React.Component<IProps, IState> {
        public constructor(props: IProps) {
            super(props);

            this.state = {
                loading: true,
                redirect: false
            };
        }

        public componentDidMount() {
            const token = getToken();

            if(token) {
                this.setState({
                    loading: false,
                    redirect: false
                });
            }
            else {
                this.setState({
                    loading: false,
                    redirect: true
                });
            }
        }

        public render() {
            const { loading, redirect } = this.state;

            if(loading) {
                return null;
            }

            if(redirect) {
                return <Redirect to="/login" />;
            }

            return (
                <ComponentToProtect {...this.props} />
            );
        }
    }
};
