 import * as React from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import {H4} from "@blueprintjs/core";
import "./ShopHome.css";
import 'pure-react-carousel/dist/react-carousel.es.css';

import {CardType, IShop, RecommendType, ShopCategory} from "../common/Domains";
import Recommend from "./view/Recommend";
import {RecommendCategory} from "./view/RecommendCategory";
 import {SITE_TITLE} from "../common/Constant";
 import {Helmet} from "react-helmet";

interface IShopProps extends RouteComponentProps {
    id: number,
    shop: IShop
}

class ShopHome extends React.Component<IShopProps, {}> {
    constructor(props: IShopProps) {
        super(props);
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public render() {
        return (
            <div className="shop-home-container">
                <Helmet>
                    <title>{"Shops, " + SITE_TITLE }</title>
                    <link rel="canonical" href={ window.location.href } />
                    <meta name="description" content={"Shops"} />
                    <meta property="og:url" content={ window.location.href } />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={"Shops, " + SITE_TITLE } />
                    <meta property="og:description" content={"Shops"} />
                </Helmet>
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="default-grid-container">
                            <div className="header-wrapper-1">
                                <H4 className="title">Shops</H4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="sub-menu-wrapper">
                            <ul>
                                <li><Link to="/shops">All shops</Link></li>
                                <li><Link to="/shops/item">Item shops</Link></li>
                                <li><Link to="/shops/flower">Flower shops</Link></li>
                                <li><Link to="/shops/cafe">Coffee shops</Link></li>
                                <li><Link to="/shops/farm">Farms</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container pad-t-3">
                        <div className="header-wrapper-2">
                            <H4 className="title">
                                <Link to="/shops">Recommended</Link>
                            </H4>
                        </div>
                        <div>
                            <Recommend />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container pad-t-3">
                        <div className="header-wrapper-2">
                            <H4 className="title">Item shops</H4>
                        </div>
                        <div>
                            <RecommendCategory recommendType={RecommendType.STAFF} categoryId={ShopCategory.ITEM} cardType={CardType.SMALL_VERTICAL_BOX} width={23} dataLimit={4} descriptionLine={4} interactive={false} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container pad-t-6">
                        <div className="header-wrapper-2">
                            <H4 className="title">Flower shops</H4>
                        </div>
                        <div>
                            <RecommendCategory recommendType={RecommendType.STAFF} categoryId={ShopCategory.FLOWER} cardType={CardType.SMALL_VERTICAL_BOX} width={23} dataLimit={4} descriptionLine={4} interactive={false} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container pad-h-6">
                        <div className="header-wrapper-2">
                            <H4 className="title">Coffee shops</H4>
                        </div>
                        <div>
                            <RecommendCategory recommendType={RecommendType.STAFF} categoryId={ShopCategory.CAFE} cardType={CardType.SMALL_VERTICAL_BOX} width={23} dataLimit={4} descriptionLine={4} interactive={false} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container pad-h-6">
                        <div className="header-wrapper-2">
                            <H4 className="title">Farms</H4>
                        </div>
                        <div>
                            <RecommendCategory recommendType={RecommendType.STAFF} categoryId={ShopCategory.FARM} cardType={CardType.SMALL_VERTICAL_BOX} width={23} dataLimit={4} descriptionLine={4} interactive={false} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ShopHome;