import * as React from "react";
import {
    IItem,
    IItemRecommend,
    IItemImage,
    RecommendType
} from "../../common/Domains";
import {Query} from "react-apollo";
import HBox from "../../card/HBox";
import {CardAlign, ICardData, ICardTag} from "../../card/CardDomains";
import {ErrorPage} from "../../utils/LoadingAndError";
import {RECOMMEND_ITEM_QUERY} from "../../item/view/Recommend";
import {PriceWrapper} from "../../item/view/RecommendCategory";

class Recommend extends React.Component {

    public render() {
        return (
            <Query
                query={RECOMMEND_ITEM_QUERY}
                variables={{
                    recommendType: RecommendType.FEATURED,
                    categoryInfo: {},
                    offset: 0,
                    limit: 1
                }}

                fetchPolicy="cache-and-network"
            >
                {({loading, error, data, fetchMore}: any) => {
                    if(error) {
                        return <ErrorPage />;
                    }

                    const { recommendItems } = data || { recommendItems: [] };

                    return (
                        <>
                            {
                                (recommendItems || []).map((itemRecommend: IItemRecommend) => {
                                    const item = itemRecommend.item as IItem;

                                    const images = item.itemImages.filter((ItemImage: IItemImage) => {
                                        return ItemImage.isDefault;
                                    })
                                    .map((ItemImage: IItemImage) => {
                                        return ItemImage.image;
                                    });

                                    const boxData = {
                                        id: item.itemId + "",
                                        link: {
                                            path: "/Item/" + item.itemId,
                                            localParams: {}
                                        },
                                        images,
                                        date: item.createdDate,
                                        title: item.title,
                                        subTitle: "",
                                        subSubTitle: "",
                                        description: item.description,
                                        author: item.author.displayName,
                                        price: {
                                            price: item.price,
                                            discountPrice: item.discountPrice,
                                            discount: item.discount,
                                            discountUnit: item.discountUnit
                                        },
                                        tags: [] as ICardTag[]
                                    } as ICardData;

                                    return (
                                        <HBox key={boxData.id} data={boxData} large={true} titleLine={3} descriptionLine={5} align={CardAlign.LEFT}>
                                            <PriceWrapper price={data.price} />
                                        </HBox>
                                    );
                                })
                            }
                            <div style={{clear: "left"}} />
                        </>
                    );
                }}
            </Query>
        );
    };
}

export default Recommend;