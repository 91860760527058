import {IImage} from "../common/Domains";
import {IMAGE_BASE_PATH} from "../common/Constant";

export const getImagePath = (image?: IImage, width: number = -1, height: number = -1) => {
    if(!image) {
        return IMAGE_BASE_PATH + "/image/0/UNKNOWN.jpg";
    }

    if(image.fullPath) {
        return "/images/" + image.fullPath;
    }

    if(width > 0 && height > 0) {
        return IMAGE_BASE_PATH + "/image/"
            + width
            + "/" + height
            + "/" + image.imageId
            + "/" + image.realName;
    }
    else {
        return IMAGE_BASE_PATH + "/image/"
            + image.imageId
            + "/" + image.realName;
    }
};

export const getImagePaths = (image: IImage[], width: number = -1, height: number = -1) => {
    if(!image || image.length === 0) {
        return "unknown.jpg";
    }

    return image.map((tempImage) => {
        return getImagePath(tempImage, width, height);
    });
};

export const getDirectImagePath = (image?: string, width: number = -1, height: number = -1) => {
    if(!image) {
        return IMAGE_BASE_PATH + "/image/0/UNKNOWN.jpg";
    }

    if(width > 0 && height > 0) {
        return "/image/" + image;
    }
    else {
        return "/image/" + image;
    }
};

export const getDirectImagePaths = (image: string[], width: number = -1, height: number = -1) => {
    if(!image || image.length === 0) {
        return "unknown.jpg";
    }

    return image.map((tempImage) => {
        return getDirectImagePath(tempImage, width, height);
    });
};

export const uploadFile = (options: any) => {
    const {
        url,
        method,
        data,
        headers,
        withCredentials,
        callbackOK,
        callbackKO
    } = options;

    const fd = new FormData();

    for(const index in data) {
        if(data[index]) {
            fd.append(index, data[index]);
        }
    }

    if (options.csrf) {
        fd.append(options.csrf.token, options.csrf.hash);
    }

    const xhr = new XMLHttpRequest();
    // init http query
    xhr.open(method, url, true);
    // add custom headers
    for(const index in headers) {
        if(headers[index]) {
            xhr.setRequestHeader(index, headers[index]);
        }
    }

    // listen callback
    xhr.onload = () => {
        if (xhr.status === 200) {
            callbackOK(JSON.parse(xhr.responseText));
        } else {
            callbackKO({
                code: xhr.status,
                type: xhr.statusText,
                body: xhr.responseText
            });
        }
    };

    if (withCredentials) {
        xhr.withCredentials = true;
    }

    xhr.send(fd);
};