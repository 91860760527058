import * as React from "react";
import {ItemPredicate, ItemRenderer, Select} from "@blueprintjs/select";
import {Button, MenuItem} from "@blueprintjs/core";

export interface ICountry {
    id: string
    title: string
}

const CountrySelect = Select.ofType<ICountry>();

const renderCountry: ItemRenderer<ICountry> = (country, { handleClick, modifiers, query}) => {
    if(!modifiers.matchesPredicate) {
        return null;
    }

    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            label={country.title}
            key={country.id}
            onClick={handleClick}
            text={highlightText(country.title, query)}
        />
    );
};

const filterCountry: ItemPredicate<ICountry> = (query, country, index, exactMatch) => {
    const normalizedTitle = country.title.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if(exactMatch) {
        return normalizedTitle === normalizedQuery;
    }
    else {
        return normalizedTitle.indexOf(normalizedQuery) >= 0;
    }
}

const highlightText = (text: string, query: string) => {
    let lastIndex = 0;

    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);

    if (words.length === 0) {
        return [text];
    }

    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];

    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }

        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }

        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }

    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }

    return tokens;
};

const escapeRegExpChars = (text: string) => {
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
};

const arrayContainsCountry = (countries: ICountry[], countryToFind: ICountry) => {
    return countries.some((country: ICountry) => country.title === countryToFind.title);
};

const addCountryToArray = (countries: ICountry[], countryToAdd: ICountry) => {
    return [...countries, countryToAdd];
};

const deleteCountryFromArray = (countries: ICountry[], countryToDelete: ICountry) => {
    return countries.filter((country: ICountry) => country !== countryToDelete);
}

const maybeAddCreatedCountryToArrays = (items: ICountry[], createdItems: ICountry[], country: ICountry) => {
    const isNewlyCreatedItem = !arrayContainsCountry(items, country);

    return {
        createdItems: isNewlyCreatedItem ? addCountryToArray(createdItems, country) : createdItems,
        items: isNewlyCreatedItem ? addCountryToArray(items, country) : items
    };
};

const maybeDeleteCreatedCountryFromArrays = (items: ICountry[], createdItems: ICountry[], country: ICountry) => {
    const wasItemCreatedByUser = arrayContainsCountry(createdItems, country);

    return {
        createdItems: wasItemCreatedByUser ? deleteCountryFromArray(createdItems, country) : createdItems,
        items: wasItemCreatedByUser ? deleteCountryFromArray(items, country) : items
    };
};

interface ICountrySelectionProps {
    country: ICountry
    filterable?: boolean
    hasInitialContent?: boolean
    items: ICountry[]
    minimal?: boolean
    resetOnClose?: boolean
    resetOnQuery?: boolean
    resetOnSelect?: boolean
    disableItems?: boolean
    disable?: boolean,
    valueChangeHandler: (country: ICountry) => void
}

interface ICountrySelectionState {
    createdItems: ICountry[]
    country: ICountry
    items: ICountry[]
}

class CountrySelection extends React.Component<ICountrySelectionProps, ICountrySelectionState> {

    constructor(props: ICountrySelectionProps) {
        super(props);

        this.state = {
            createdItems: [],
            country: props.country,
            items: props.items || []
        }
    }

    public render() {
        const { country, disable, filterable, resetOnQuery, resetOnSelect, resetOnClose, hasInitialContent, minimal } = this.props;

        const initialContent = hasInitialContent ? (
            <MenuItem disabled={true} text={`${COUNTRY_ITEMS.length} items loaded.`} />
        ) : (
            undefined
        );

        return (
            <CountrySelect
                popoverProps={{minimal}}
                disabled={disable}
                filterable={filterable}
                resetOnQuery={resetOnQuery}
                resetOnSelect={resetOnSelect}
                resetOnClose={resetOnClose}
                initialContent={initialContent}
                items={this.state.items}
                itemRenderer={renderCountry}
                itemPredicate={filterCountry}
                itemsEqual={this.countryEqual}
                onItemSelect={this.handleValueChange}
                noResults={<MenuItem disabled={true} text="No results." />}
            >
                <Button text={country.title} rightIcon="double-caret-vertical" />
            </CountrySelect>
        );
    }

    private countryEqual = (countryA: ICountry, countryB: ICountry) => {
        return countryA.title.toLowerCase() === countryB.title.toLowerCase();
    }

    private handleValueChange = (country: ICountry) => {
        const { createdItems, items } = maybeDeleteCreatedCountryFromArrays(
            this.state.items,
            this.state.createdItems,
            this.state.country
        );

        const { createdItems: nextCreatedItems, items: nextItems } = maybeAddCreatedCountryToArrays(
            items,
            createdItems,
            country
        );

        this.setState({
            createdItems: nextCreatedItems,
            country,
            items: nextItems
        });

        if(this.props.valueChangeHandler) {
            this.props.valueChangeHandler(country);
        }
    }
}

export default CountrySelection;

export const COUNTRY_ITEMS: ICountry[] = [
    { id: "Afghanistan", title: "Afghanistan" },
    { id: "Åland Islands", title: "Åland Islands" },
    { id: "Albania", title: "Albania" },
    { id: "Algeria", title: "Algeria" },
    { id: "American Samoa", title: "American Samoa" },
    { id: "Andorra", title: "Andorra" },
    { id: "Angola", title: "Angola" },
    { id: "Anguilla", title: "Anguilla" },
    { id: "Antarctica", title: "Antarctica" },
    { id: "Antigua and Barbuda", title: "Antigua and Barbuda" },
    { id: "Argentina", title: "Argentina" },
    { id: "Armenia", title: "Armenia" },
    { id: "Aruba", title: "Aruba" },
    { id: "Australia", title: "Australia" },
    { id: "Austria", title: "Austria" },
    { id: "Azerbaijan", title: "Azerbaijan" },
    { id: "Bahamas", title: "Bahamas" },
    { id: "Bahrain", title: "Bahrain" },
    { id: "Bangladesh", title: "Bangladesh" },
    { id: "Barbados", title: "Barbados" },
    { id: "Belarus", title: "Belarus" },
    { id: "Belgium", title: "Belgium" },
    { id: "Belize", title: "Belize" },
    { id: "Benin", title: "Benin" },
    { id: "Bermuda", title: "Bermuda" },
    { id: "Bhutan", title: "Bhutan" },
    { id: "Bolivia", title: "Bolivia" },
    { id: "Bosnia and Herzegovina", title: "Bosnia and Herzegovina" },
    { id: "Botswana", title: "Botswana" },
    { id: "Bouvet Island", title: "Bouvet Island" },
    { id: "Brazil", title: "Brazil" },
    { id: "British Indian Ocean Territory", title: "British Indian Ocean Territory" },
    { id: "Brunei Darussalam", title: "Brunei Darussalam" },
    { id: "Bulgaria", title: "Bulgaria" },
    { id: "Burkina Faso", title: "Burkina Faso" },
    { id: "Burundi", title: "Burundi" },
    { id: "Cambodia", title: "Cambodia" },
    { id: "Cameroon", title: "Cameroon" },
    { id: "Canada", title: "Canada" },
    { id: "Cape Verde", title: "Cape Verde" },
    { id: "Cayman Islands", title: "Cayman Islands" },
    { id: "Central African Republic", title: "Central African Republic" },
    { id: "Chad", title: "Chad" },
    { id: "Chile", title: "Chile" },
    { id: "China", title: "China" },
    { id: "Christmas Island", title: "Christmas Island" },
    { id: "Cocos (Keeling) Islands", title: "Cocos (Keeling) Islands" },
    { id: "Colombia", title: "Colombia" },
    { id: "Comoros", title: "Comoros" },
    { id: "Congo", title: "Congo" },
    { id: "Congo, The Democratic Republic of The", title: "Congo, The Democratic Republic of The" },
    { id: "Cook Islands", title: "Cook Islands" },
    { id: "Costa Rica", title: "Costa Rica" },
    { id: "Cote D'ivoire", title: "Cote D'ivoire" },
    { id: "Croatia", title: "Croatia" },
    { id: "Cuba", title: "Cuba" },
    { id: "Cyprus", title: "Cyprus" },
    { id: "Czech Republic", title: "Czech Republic" },
    { id: "Denmark", title: "Denmark" },
    { id: "Djibouti", title: "Djibouti" },
    { id: "Dominica", title: "Dominica" },
    { id: "Dominican Republic", title: "Dominican Republic" },
    { id: "Ecuador", title: "Ecuador" },
    { id: "Egypt", title: "Egypt" },
    { id: "El Salvador", title: "El Salvador" },
    { id: "Equatorial Guinea", title: "Equatorial Guinea" },
    { id: "Eritrea", title: "Eritrea" },
    { id: "Estonia", title: "Estonia" },
    { id: "Ethiopia", title: "Ethiopia" },
    { id: "Falkland Islands (Malvinas)", title: "Falkland Islands (Malvinas)" },
    { id: "Faroe Islands", title: "Faroe Islands" },
    { id: "Fiji", title: "Fiji" },
    { id: "Finland", title: "Finland" },
    { id: "France", title: "France" },
    { id: "French Guiana", title: "French Guiana" },
    { id: "French Polynesia", title: "French Polynesia" },
    { id: "French Southern Territories", title: "French Southern Territories" },
    { id: "Gabon", title: "Gabon" },
    { id: "Gambia", title: "Gambia" },
    { id: "Georgia", title: "Georgia" },
    { id: "Germany", title: "Germany" },
    { id: "Ghana", title: "Ghana" },
    { id: "Gibraltar", title: "Gibraltar" },
    { id: "Greece", title: "Greece" },
    { id: "Greenland", title: "Greenland" },
    { id: "Grenada", title: "Grenada" },
    { id: "Guadeloupe", title: "Guadeloupe" },
    { id: "Guam", title: "Guam" },
    { id: "Guatemala", title: "Guatemala" },
    { id: "Guernsey", title: "Guernsey" },
    { id: "Guinea", title: "Guinea" },
    { id: "Guinea-bissau", title: "Guinea-bissau" },
    { id: "Guyana", title: "Guyana" },
    { id: "Haiti", title: "Haiti" },
    { id: "Heard Island and Mcdonald Islands", title: "Heard Island and Mcdonald Islands" },
    { id: "Holy See (Vatican City State)", title: "Holy See (Vatican City State)" },
    { id: "Honduras", title: "Honduras" },
    { id: "Hong Kong", title: "Hong Kong" },
    { id: "Hungary", title: "Hungary" },
    { id: "Iceland", title: "Iceland" },
    { id: "India", title: "India" },
    { id: "Indonesia", title: "Indonesia" },
    { id: "Iran, Islamic Republic of", title: "Iran, Islamic Republic of" },
    { id: "Iraq", title: "Iraq" },
    { id: "Ireland", title: "Ireland" },
    { id: "Isle of Man", title: "Isle of Man" },
    { id: "Israel", title: "Israel" },
    { id: "Italy", title: "Italy" },
    { id: "Jamaica", title: "Jamaica" },
    { id: "Japan", title: "Japan" },
    { id: "Jersey", title: "Jersey" },
    { id: "Jordan", title: "Jordan" },
    { id: "Kazakhstan", title: "Kazakhstan" },
    { id: "Kenya", title: "Kenya" },
    { id: "Kiribati", title: "Kiribati" },
    // { id: "Korea, Democratic People's Republic of", title: "Korea, Democratic People's Republic of" },
    { id: "Korea, Republic of", title: "Korea, Republic of" },
    { id: "Kuwait", title: "Kuwait" },
    { id: "Kyrgyzstan", title: "Kyrgyzstan" },
    { id: "Lao People's Democratic Republic", title: "Lao People's Democratic Republic" },
    { id: "Latvia", title: "Latvia" },
    { id: "Lebanon", title: "Lebanon" },
    { id: "Lesotho", title: "Lesotho" },
    { id: "Liberia", title: "Liberia" },
    { id: "Libyan Arab Jamahiriya", title: "Libyan Arab Jamahiriya" },
    { id: "Liechtenstein", title: "Liechtenstein" },
    { id: "Lithuania", title: "Lithuania" },
    { id: "Luxembourg", title: "Luxembourg" },
    { id: "Macao", title: "Macao" },
    { id: "Macedonia, The Former Yugoslav Republic of", title: "Macedonia, The Former Yugoslav Republic of" },
    { id: "Madagascar", title: "Madagascar" },
    { id: "Malawi", title: "Malawi" },
    { id: "Malaysia", title: "Malaysia" },
    { id: "Maldives", title: "Maldives" },
    { id: "Mali", title: "Mali" },
    { id: "Malta", title: "Malta" },
    { id: "Marshall Islands", title: "Marshall Islands" },
    { id: "Martinique", title: "Martinique" },
    { id: "Mauritania", title: "Mauritania" },
    { id: "Mauritius", title: "Mauritius" },
    { id: "Mayotte", title: "Mayotte" },
    { id: "Mexico", title: "Mexico" },
    { id: "Micronesia, Federated States of", title: "Micronesia, Federated States of" },
    { id: "Moldova, Republic of", title: "Moldova, Republic of" },
    { id: "Monaco", title: "Monaco" },
    { id: "Mongolia", title: "Mongolia" },
    { id: "Montenegro", title: "Montenegro" },
    { id: "Montserrat", title: "Montserrat" },
    { id: "Morocco", title: "Morocco" },
    { id: "Mozambique", title: "Mozambique" },
    { id: "Myanmar", title: "Myanmar" },
    { id: "Namibia", title: "Namibia" },
    { id: "Nauru", title: "Nauru" },
    { id: "Nepal", title: "Nepal" },
    { id: "Netherlands", title: "Netherlands" },
    { id: "Netherlands Antilles", title: "Netherlands Antilles" },
    { id: "New Caledonia", title: "New Caledonia" },
    { id: "New Zealand", title: "New Zealand" },
    { id: "Nicaragua", title: "Nicaragua" },
    { id: "Niger", title: "Niger" },
    { id: "Nigeria", title: "Nigeria" },
    { id: "Niue", title: "Niue" },
    { id: "Norfolk Island", title: "Norfolk Island" },
    { id: "Northern Mariana Islands", title: "Northern Mariana Islands" },
    { id: "Norway", title: "Norway" },
    { id: "Oman", title: "Oman" },
    { id: "Pakistan", title: "Pakistan" },
    { id: "Palau", title: "Palau" },
    { id: "Palestinian Territory, Occupied", title: "Palestinian Territory, Occupied" },
    { id: "Panama", title: "Panama" },
    { id: "Papua New Guinea", title: "Papua New Guinea" },
    { id: "Paraguay", title: "Paraguay" },
    { id: "Peru", title: "Peru" },
    { id: "Philippines", title: "Philippines" },
    { id: "Pitcairn", title: "Pitcairn" },
    { id: "Poland", title: "Poland" },
    { id: "Portugal", title: "Portugal" },
    { id: "Puerto Rico", title: "Puerto Rico" },
    { id: "Qatar", title: "Qatar" },
    { id: "Reunion", title: "Reunion" },
    { id: "Romania", title: "Romania" },
    { id: "Russian Federation", title: "Russian Federation" },
    { id: "Rwanda", title: "Rwanda" },
    { id: "Saint Helena", title: "Saint Helena" },
    { id: "Saint Kitts and Nevis", title: "Saint Kitts and Nevis" },
    { id: "Saint Lucia", title: "Saint Lucia" },
    { id: "Saint Pierre and Miquelon", title: "Saint Pierre and Miquelon" },
    { id: "Saint Vincent and The Grenadines", title: "Saint Vincent and The Grenadines" },
    { id: "Samoa", title: "Samoa" },
    { id: "San Marino", title: "San Marino" },
    { id: "Sao Tome and Principe", title: "Sao Tome and Principe" },
    { id: "Saudi Arabia", title: "Saudi Arabia" },
    { id: "Senegal", title: "Senegal" },
    { id: "Serbia", title: "Serbia" },
    { id: "Seychelles", title: "Seychelles" },
    { id: "Sierra Leone", title: "Sierra Leone" },
    { id: "Singapore", title: "Singapore" },
    { id: "Slovakia", title: "Slovakia" },
    { id: "Slovenia", title: "Slovenia" },
    { id: "Solomon Islands", title: "Solomon Islands" },
    { id: "Somalia", title: "Somalia" },
    { id: "South Africa", title: "South Africa" },
    { id: "South Georgia and The South Sandwich Islands", title: "South Georgia and The South Sandwich Islands" },
    { id: "Spain", title: "Spain" },
    { id: "Sri Lanka", title: "Sri Lanka" },
    { id: "Sudan", title: "Sudan" },
    { id: "Suriname", title: "Suriname" },
    { id: "Svalbard and Jan Mayen", title: "Svalbard and Jan Mayen" },
    { id: "Swaziland", title: "Swaziland" },
    { id: "Sweden", title: "Sweden" },
    { id: "Switzerland", title: "Switzerland" },
    { id: "Syrian Arab Republic", title: "Syrian Arab Republic" },
    { id: "Taiwan, Province of China", title: "Taiwan, Province of China" },
    { id: "Tajikistan", title: "Tajikistan" },
    { id: "Tanzania, United Republic of", title: "Tanzania, United Republic of" },
    { id: "Thailand", title: "Thailand" },
    { id: "Timor-leste", title: "Timor-leste" },
    { id: "Togo", title: "Togo" },
    { id: "Tokelau", title: "Tokelau" },
    { id: "Tonga", title: "Tonga" },
    { id: "Trinidad and Tobago", title: "Trinidad and Tobago" },
    { id: "Tunisia", title: "Tunisia" },
    { id: "Turkey", title: "Turkey" },
    { id: "Turkmenistan", title: "Turkmenistan" },
    { id: "Turks and Caicos Islands", title: "Turks and Caicos Islands" },
    { id: "Tuvalu", title: "Tuvalu" },
    { id: "Uganda", title: "Uganda" },
    { id: "Ukraine", title: "Ukraine" },
    { id: "United Arab Emirates", title: "United Arab Emirates" },
    { id: "United Kingdom", title: "United Kingdom" },
    { id: "United States", title: "United States" },
    { id: "United States Minor Outlying Islands", title: "United States Minor Outlying Islands" },
    { id: "Uruguay", title: "Uruguay" },
    { id: "Uzbekistan", title: "Uzbekistan" },
    { id: "Vanuatu", title: "Vanuatu" },
    { id: "Venezuela", title: "Venezuela" },
    { id: "Viet Nam", title: "Viet Nam" },
    { id: "Virgin Islands, British", title: "Virgin Islands, British" },
    { id: "Virgin Islands, U.S.", title: "Virgin Islands, U.S." },
    { id: "Wallis and Futuna", title: "Wallis and Futuna" },
    { id: "Western Sahara", title: "Western Sahara" },
    { id: "Yemen", title: "Yemen" },
    { id: "Zambia", title: "Zambia" },
    { id: "Zimbabwe", title: "Zimbabwe" },
];