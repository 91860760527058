import * as React from "react";

export interface ILoadingProps {
    message?: string
    isOnlyMessage?: boolean
}

export const LoadingPage = (props: ILoadingProps) => {
    const { message, isOnlyMessage } = props;

    if(isOnlyMessage) {
        return (
            <span>{ message || "Loading..." }</span>
        );
    }

    return (
        <div className="grid-container">
            <div className="default-grid-container pad-h-3 group-center">
                { message || "Loading..." }
            </div>
        </div>
    );
};

export interface IErrorProps {
    message?: string
    isOnlyMessage?: boolean
}

export const ErrorPage = (props: IErrorProps) => {
    const { message, isOnlyMessage } = props;

    if(isOnlyMessage) {
        return (
            <span>{ message || "Error..." }</span>
        );
    }

    return (
        <div className="grid-container">
            <div className="default-grid-container pad-h-3 group-center">
                { message || "Error..." }
            </div>
        </div>
    );
};