import * as React from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import {Button, H4} from "@blueprintjs/core";
import "./SpecialHome.css";
import 'pure-react-carousel/dist/react-carousel.es.css';

import {BoardCategory, CardType, IBoard, RecommendType} from "../common/Domains";
import {SITE_TITLE} from "../common/Constant";
import {Helmet} from "react-helmet";
import {RecommendCategory} from "./RecommendCategory";
import Recommend from "./Recommend";

interface IBoardProps extends RouteComponentProps {
    id: number,
    item: IBoard,
    category: number
}

interface IBoardState {
    categoryId: number
}

class SpecialHome extends React.Component<IBoardProps, IBoardState> {
    constructor(props: IBoardProps) {
        super(props);

        const { category } = this.props.match.params as IBoardProps;
        const categoryId = category;

        this.state = {
            categoryId
        }
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public componentDidUpdate(prevProps: Readonly<IBoardProps>, prevState: Readonly<IBoardState>, snapshot?: any): void {
        const prevData = prevProps.match.params as IBoardProps;
        const currData = this.props.match.params as IBoardProps;

        if(prevData.category !== currData.category) {
            this.setState({
                categoryId: currData.category
            })
        }
    }

    public render() {
        return (
            <div className="special-home-container">
                <Helmet>
                    <title>{"Products, " + SITE_TITLE }</title>
                    <link rel="canonical" href={ window.location.href } />
                    <meta name="description" content={"Products"} />
                    <meta property="og:url" content={ window.location.href } />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={"Products, " + SITE_TITLE } />
                    <meta property="og:description" content={"Products"} />
                </Helmet>
                <div className="grid-container">
                    <div className="default-grid-container pad-t-3">
                        <div className="header-wrapper-2">
                            <H4 className="title group-center">SPECIAL</H4>
                            <div className="group-center">독특한 디자인, 삶을 풍성하게하는 공간</div>
                        </div>
                        <div className="main-special-wrapper">
                            <Recommend />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container pad-h-2">
                        <div className="topic-container">
                            <div className="sub-topic group-center pad-h-1 pad-w-1-half">더 많은 스페셜 제품을 만나보세요!</div>
                            <div className="group-center">
                                <Link to="/board/special"><Button className="button-s-2" minimal={true} large={true}>Show All Special</Button></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container pad-t-3">
                        <div className="header-wrapper-2">
                            <H4 className="title group-center">OUR PICKS</H4>
                        </div>
                        <div>
                            <RecommendCategory recommendType={RecommendType.PICK} categoryId={BoardCategory.SPECIAL} cardType={CardType.SMALL_VERTICAL_BOX} width={23} dataLimit={4} descriptionLine={4} interactive={false} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SpecialHome;