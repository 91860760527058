import * as React from "react";
import {Redirect, RouteComponentProps} from "react-router";
import {setToken, STORE_KEY_TOKEN} from "../../store/cache/Token";

interface IProps extends RouteComponentProps {
    token: string
}

class OAuth2RedirectHandler extends React.Component<IProps, {}> {
    public render() {
        const urlParams = new URLSearchParams(this.props.location.search);
        const token = urlParams.get(STORE_KEY_TOKEN);

        if(token) {
            setToken(token);
        }

        return (
            <Redirect to="/" />
        );
    }
}

export default OAuth2RedirectHandler;