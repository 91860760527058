import * as React from "react";
import {
    RecommendType,
    CardType,
    IItem,
    IItemImage, ICategoryInfo
} from "../../common/Domains";
import {Query} from "react-apollo";
import {CardAlign, ICardData, IPrice} from "../../card/CardDomains";
import VCard from "../../card/VCard";
import HCard from "../../card/HCard";
import VBox from "../../card/VBox";
import HBox from "../../card/HBox";
import {ErrorPage} from "../../utils/LoadingAndError";
import numeral from "numeral";
import {THOUSANDS_COMMA_FORMAT} from "../../common/Constant";
import {ITEMS_QUERY} from "../Items";

interface IProps {
    recommendType: RecommendType
    categoryInfo: ICategoryInfo
    cardType: CardType
    titleLine?: number
    descriptionLine?: number
    dataLimit: number
    interactive: boolean
    width: number
}

export const NewRecommand = (props: IProps) => {
    const { categoryInfo, cardType, dataLimit, titleLine, descriptionLine, interactive, width } = props;

    return (
        <Query
            query={ITEMS_QUERY}
            variables={{
                keyword: "",
                categoryInfo,
                userNo: -1,
                offset: 0,
                limit: dataLimit
            }}

            fetchPolicy="cache-and-network"
        >
            {({loading, error, data, fetchMore}: any) => {
                if(error) {
                    return <ErrorPage isOnlyMessage={true} />;
                }

                const { items } = data|| { items: [] };

                if(!items || items.length === 0) {
                    const emptyData = {
                        id: "0",
                        link: {
                            path: ""
                        },
                        title: "No results"
                    } as ICardData;

                    return (
                        <>
                            <GetCard key={emptyData.id} data={emptyData} cardType={cardType} interactive={interactive} width={width} isEmpty={true} />
                            <div style={{clear: "left"}} />
                        </>
                    );
                }

                return (
                    <>
                        {
                            (items || []).map((item: IItem) => {
                                const images = item.itemImages.filter((itemImage: IItemImage) => {
                                    return itemImage.isDefault;
                                })
                                .map((itemImage: IItemImage) => {
                                    return itemImage.image;
                                });

                                const boxData = {
                                    id: item.itemId + "",
                                    link: {
                                        path: "/item/" + item.itemId,
                                        localParams: {}
                                    },
                                    images,
                                    date: item.createdDate,
                                    title: item.title,
                                    subTitle: item.brand.korName,
                                    subSubTitle: item.brand.desc,
                                    description: item.description,
                                    author: item.author.displayName,
                                    price: {
                                        price: item.price,
                                        discountPrice: item.discountPrice,
                                        discount: item.discount,
                                        discountUnit: item.discountUnit
                                    }
                                } as ICardData;

                                return <GetCard key={boxData.id} data={boxData} cardType={cardType} titleLine={titleLine} descriptionLine={descriptionLine}  interactive={interactive} width={width} isEmpty={false} />
                            })
                        }
                        <div style={{clear: "left"}} />
                    </>
                );
            }}
        </Query>
    );
};

interface ICardProps {
    data: ICardData
    cardType: CardType
    titleLine?: number
    descriptionLine?: number
    interactive: boolean
    width?: number
    isEmpty: boolean
}

export const GetCard = ({data, cardType, titleLine, descriptionLine, interactive, width, isEmpty}: ICardProps) => {
    // CARD
    if(CardType.HORIZON_CARD === cardType) {
        return (
            <HCard data={data} large={true} titleLine={titleLine} descriptionLine={descriptionLine} interactive={interactive} align={CardAlign.RIGHT} isEmpty={isEmpty}>
                <PriceWrapper price={data.price} />
            </HCard>
        );
    }
    else if(CardType.VERTICAL_CARD === cardType) {
        return (
            <VCard data={data} large={true} titleLine={titleLine} descriptionLine={descriptionLine} width={width} interactive={interactive} isEmpty={isEmpty} >
                <PriceWrapper price={data.price} />
            </VCard>
        );
    }
    else if(CardType.SMALL_HORIZON_CARD === cardType) {
        return (
            <HCard data={data} titleLine={titleLine} descriptionLine={descriptionLine} interactive={interactive} align={CardAlign.RIGHT} isEmpty={isEmpty} >
                <PriceWrapper price={data.price} />
            </HCard>
        );
    }
    else if(CardType.SMALL_VERTICAL_CARD === cardType) {
        return (
            <VCard data={data} titleLine={titleLine} descriptionLine={descriptionLine} width={width} interactive={interactive} isEmpty={isEmpty}>
                <PriceWrapper price={data.price} />
            </VCard>
        );
    }

    // BOX
    if(CardType.HORIZON_BOX === cardType) {
        return (
            <HBox data={data} titleLine={titleLine} descriptionLine={descriptionLine} align={CardAlign.RIGHT} isEmpty={isEmpty}>
                <PriceWrapper price={data.price} />
            </HBox>
        );
    }
    else if(CardType.VERTICAL_BOX === cardType) {
        return (
            <VBox width={width} titleLine={titleLine} descriptionLine={descriptionLine} data={data} isEmpty={isEmpty} >
                <PriceWrapper price={data.price} />
            </VBox>
        );
    }
    else if(CardType.SMALL_HORIZON_BOX === cardType) {
        return (
            <HBox data={data} titleLine={titleLine} descriptionLine={descriptionLine} align={CardAlign.RIGHT} isEmpty={isEmpty}>
                <PriceWrapper price={data.price} />
            </HBox>
        );
    }
    else if(CardType.SMALL_VERTICAL_BOX === cardType) {
        return (
            <VBox width={width} data={data} titleLine={titleLine} descriptionLine={descriptionLine} isEmpty={isEmpty}>
                <PriceWrapper price={data.price} />
            </VBox>
        );
    }

    return (
        <VCard data={data} width={width} titleLine={titleLine} descriptionLine={descriptionLine} interactive={interactive} isEmpty={isEmpty}>
            <PriceWrapper price={data.price} />
        </VCard>
    );
};

interface IPriceProps {
    price?: IPrice
}

export const PriceWrapper = ({ price }: IPriceProps) => {
    if(!price || !price.price) {
        return <></>;
    }

    return (
        <div className="price-info-wrapper pad-t-1">
            <span className="price pad-h-1">₩{numeral(price.price).format(THOUSANDS_COMMA_FORMAT)}</span>
            &nbsp;
            <span
                className="discount-price">₩{numeral(price.discountPrice).format(THOUSANDS_COMMA_FORMAT)}</span>
            <span className="discount">({price.discount}</span><span
            className="discount-unit">{price.discountUnit})</span>
        </div>
    );
}