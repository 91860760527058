export const redirectRoot = () => {
    redirectCustom("/");
};

export const redirectLogin = () => {
    redirectCustom("/login");
};

export const redirectCustom = (pathname: string) => {
    window.location.assign(pathname);
};
