import * as React from "react";

import "node_modules/react-quill/dist/quill.snow.css";
import "../../common/css/quill-editor.css";
import "./AddShop.css";
import {Mutation, Query} from "react-apollo";
import {gql} from "apollo-boost";
import {RouteComponentProps} from "react-router";
import EditShop from "./EditShop";
import {H5, IToastProps, Position, Toast, Toaster} from "@blueprintjs/core";
import {SHOP_QUERY} from "./Shop";
import {isBlockedUser} from "../common/UserInfo";
import {redirectCustom, redirectRoot} from "../utils/PageFolwUtils";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";
import {ShopStatus} from "../common/Domains";

const isEnableAutoSave = false;

export const UPDATE_SHOP_QUERY = gql `
    mutation UpdateShop(
        $shop: InputShop
    ) {
        updateShop(
            shop: $shop
        ) {
            shopId
            groupId
            category {
                categoryId
            }
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            name
            description
            content
            type
            status
            isDelete
            shopEnv {
                envId
                location
                latitude
                longitude
                country
                isClosed
                openingTime
                closingTime
                homePageUrl
                telNumber
                keywords
            }
            shopImages {
                shopImageId
                image {
                    imageId
                    realName
                }
                isDefault
                createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

interface IProps extends RouteComponentProps {
    shopId: number
}

interface IState {
    toasters: IToastProps[]
}

class ModifyShop extends React.Component<IProps, IState> {
    private toaster: Toaster;

    private refToasterHandler = {
        toaster: (ref: Toaster) => this.toaster = ref
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            toasters: []
        }
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public render() {
        if(isBlockedUser()) {
            redirectRoot();
        }

        const { shopId } = this.props.match.params as IProps;

        if(!shopId) {
            return <div>error...</div>;
        }

        const newShopId = Number(shopId);

        return (
            <div className="default-grid-container add-shop-container">
                <div className="pad-h-1">
                    <div className="header-wrapper-2">
                        <H5 className="title">Modify shop</H5>
                    </div>
                </div>
                <Query
                    query={SHOP_QUERY}
                    variables={{ shopId: newShopId }}
                    fetchPolicy="cache-and-network"
                >
                    {({loading, error, data, networkStatus}: any) => {
                        if(loading) {
                            return <LoadingPage />;
                        }

                        if(error) {
                            return <ErrorPage />;
                        }

                        const { shop } = data || { shop: null };

                        return (
                            <Mutation
                                mutation={UPDATE_SHOP_QUERY}
                                update={(cache: any, {data: {updateShop}}: any) => {
                                    const tempShop = cache.readQuery({
                                        query: SHOP_QUERY,
                                        variables: { shopId: updateShop.shopId }
                                    });

                                    if(tempShop) {
                                        cache.writeQuery({
                                            query: SHOP_QUERY,
                                            variables: {shopId: updateShop.shopId},
                                            data: { shop: updateShop}
                                        });
                                    }
                                }}
                                onCompleted={this.onCompleteSave}
                            >
                                {(saveShop: any) => (
                                <EditShop
                                    isTemporary={false}
                                    shop={shop}
                                    shopId={newShopId}
                                    saveHandler={saveShop}
                                    isEnableAutoSave={isEnableAutoSave}
                                    history={this.props.history}
                                    location={this.props.location}
                                    match={this.props.match}
                                />
                                )}
                            </Mutation>
                        );
                    }}
                </Query>
                <Toaster position={Position.BOTTOM} ref={this.refToasterHandler.toaster}
                >
                    {
                        this.state.toasters.map((toast, idx) => {
                            return <Toast key={idx} message={toast.message}/>;
                        })

                    }
                </Toaster>
            </div>
        );
    }

    private onCompleteSave = (data: any) => {
        this.toaster.show({message: "Success"});

        if(data.updateShop.status === ShopStatus.TEMPORARY) {
            redirectCustom("/temporaryShop/" + data.updateShop.shopId);
        }
        else {
            redirectCustom("/shop/" + data.updateShop.shopId);
        }
    }
}

export default ModifyShop;