import * as React from "react";
import { RouteComponentProps} from "react-router-dom";
import {H5} from "@blueprintjs/core";
import {SITE_TITLE} from "../common/Constant";
import {Helmet} from "react-helmet";

import "./AboutUs.css";

interface IItemProps extends RouteComponentProps {
    id: number
}

class AboutUs extends React.Component<IItemProps, {}> {
    constructor(props: IItemProps) {
        super(props);
    }

    public render() {
        return (
            <div className="aboutus-container">
                <Helmet>
                    <title>{"About, " + SITE_TITLE }</title>
                    <link rel="canonical" href={ window.location.href } />
                    <meta name="description" content={"About"} />
                    <meta property="og:url" content={ window.location.href } />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={"About, " + SITE_TITLE } />
                    <meta property="og:description" content={"About"} />
                </Helmet>
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="header-wrapper-1">
                            <H5 className="title">About</H5>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container content">
                        <div className="topics">
                            <div className="main-topics">의미있는 공간, 즐거운 인테리어, 생활소품</div>
                        </div>
                        <div className="main-image pad-t-1">
                            <img src={"/images/serax_iki_glass_vase.jpg"} width="100%"/>
                            <div className="description">Serax, Iki Doll Glass Vase (2020) </div>
                        </div>
                        <p className="pad-t-1">
                            안녕하세요. Jiyunshop에 오신 것을 환영합니다.
                        </p>
                        <p>
                            주변을 아름답게 꾸민하는 것은 즐거운 일입니다.
                            공간을 채우는 것과 비우는 것의 조화가 중요합니다.<br />
                            여러분의 삶이 좀 더 풍성하고, 의미있는 공간이 될 수 있도록 고민하여 다양한 제품을 소개해 드리고 있습니다.
                        </p>
                        <p>
                            Jiyunshop를 통해 주변을 좀 더 아름답게 꾸미길 바랍니다.
                        </p>
                        <p>
                            감사합니다.
                        </p>
                        <p>
                            Jiyunshop
                        </p>
                        <div className="grid-clear-right">&nbsp;</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutUs;