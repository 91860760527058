import * as React from "react";
import "./Profile.css";
import {Image} from "grommet";
import {
    Button,
    FormGroup,
    H5, Icon,
    InputGroup,
    IToastProps,
    Label,
    Position,
    TextArea,
    Toast,
    Toaster
} from "@blueprintjs/core";
import {useRef} from "react";
import {useState} from "react";
import {Mutation} from "react-apollo";
import {gql} from "apollo-boost";
import {Link} from "react-router-dom";
import Dotdotdot from 'react-dotdotdot';
import {IUser} from "../common/Domains";
import {getUserInfo, isUserLogin} from "../common/UserInfo";
import {useMutation, useQuery} from "react-apollo-hooks";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";

const GET_HAS_FOLLOWING_QUERY = gql `
    query HasFollowing( $userNo: Int, $followerNo: Int) {
        hasFollowing(userNo: $userNo, followerNo: $followerNo)
    }
`;

const ADD_USER_FOLLOWER_QUERY = gql `
    mutation AddUserFollower( $userNo: Int, $followerNo: Int, $description: String) {
        addUserFollower(userNo: $userNo, followerNo: $followerNo, description: $description) {
            userFollowerId
            author {
                no
            }
            follower {
                no
            }
            description
            createdDate
        }
    }
`;

const DELETE_USER_FOLLOWER_QUERY = gql `
    mutation DeleteUserFollower( $userNo: Int, $followerNo: Int) {
        deleteUserFollower(userNo: $userNo, followerNo: $followerNo) {
            userFollowerId
            author {
                no
            }
            follower {
                no
            }
            description
            createdDate
        }
    }
`;

export enum ProfileType {
    ONLY_PHOTO,
    PHOTO_AND_NAME,
    DEFAULT,
    SIMPLE,
    EDIT
}

interface IProfileProps {
    user: IUser
    large: boolean
    profileType: ProfileType
    disableDescription?: boolean
    descriptionLength?: number
    isForceUserDetail?: boolean
    children?: any
}

export const Profile = (props: IProfileProps) => {
    const { user, large, profileType, disableDescription, descriptionLength, isForceUserDetail, children } = props;

    return profileType !== ProfileType.EDIT ? <ViewProfile user={user}
                                 large={large}
                                 profileType={profileType}
                                 disableDescription={disableDescription}
                                 descriptionLength={descriptionLength}
                                 isForceUserDetail={isForceUserDetail}
                    >
                        {children}
                    </ViewProfile>
        : <EditProfile user={user} >
            { children }
        </EditProfile>;
};

// VIEW
interface IViewProfileProps {
    user: IUser
    large: boolean
    profileType: ProfileType
    disableDescription?: boolean
    descriptionLength?: number
    isForceUserDetail?: boolean
    children?: any
}

const ViewProfile = (props: IViewProfileProps) => {
    const { user, large, profileType, disableDescription, descriptionLength, isForceUserDetail, children } = props;

    const currentUser = getUserInfo();
    const isLogined = isUserLogin(currentUser);

    const isIam = (!isForceUserDetail && (currentUser.no === user.no));

    const imagePath = user.imageUrl;

    return (
        <div className="profile-container margin-h-1">
            <div className={large ? "large": ""}>
                <div className="img">
                    <Link to={isIam ? "/iam" : "/follow/" + user.no}>
                        {imagePath && large && <Image className="image author-cover" fit="cover" src={imagePath} width={100} height={100}/>}
                        {imagePath && !large && <Image className="image author-cover" fit="cover" src={imagePath} width={40} height={40}/>}
                        {!imagePath && large && <Icon icon="user" iconSize={100} />}
                        {!imagePath && !large && <Icon icon="user" iconSize={40} />}
                    </Link>
                </div>
                <div className="attr-wrapper">
                    <div className="name">
                        <Link to={isIam ? "/iam" : "/follow/" + user.no}>{ user.displayName }</Link>
                    </div>
                    {!(disableDescription || false) && (
                        <div className="description">
                            { (!descriptionLength || descriptionLength <= 0) && user.description }
                            { descriptionLength && descriptionLength > 0 && <Dotdotdot clamp={descriptionLength}>{user.description}</Dotdotdot> }
                        </div>
                    )}
                    { children && <div>{ children }</div> }
                    {(isLogined && !isIam && (profileType !== ProfileType.SIMPLE)) && (
                        <div className="follow pad-h-1">
                            <Follow userNo={currentUser.no} followerNo={user.no} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

interface IFollowProps {
    userNo: number
    followerNo: number
}

const Follow = (props: IFollowProps) => {
    const { userNo, followerNo } = props;

    if(userNo === followerNo) {
        return <></>;
    }

    const { addUserFollower } = doAddUserFollower(userNo, followerNo);
    const { deleteUserFollower } = doDeleteUserFollower(userNo, followerNo);

    const {loading, errors, data, networkStatus } = useQuery(GET_HAS_FOLLOWING_QUERY, {
        variables: {
            userNo,
            followerNo
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: false
    });

    if(loading || networkStatus === 4) {
        return <LoadingPage isOnlyMessage={true} />
    }

    if(errors) {
        return <ErrorPage isOnlyMessage={true} />
    }

    const { hasFollowing } = data || { hasFollowing: null };

    if(hasFollowing) {
        return (
            <Button className="button-s-1" minimal={true} onClick={deleteUserFollower}>Following</Button>
        );
    }
    else {
        return (
            <Button className="button-s-1" minimal={true} onClick={addUserFollower}>Follow</Button>
        );
    }
};

const doAddUserFollower = (userNo: number, followerNo: number) => {
    const [ addUserFollower ]: any = useMutation(ADD_USER_FOLLOWER_QUERY, {
        variables: {
            userNo,
            followerNo,
            description: ""
        },
        update: (proxy, {data}: any) => {
            if(data.addUserFollower) {
                // ADD
                proxy.writeQuery({
                    query: GET_HAS_FOLLOWING_QUERY,
                    variables: { userNo, followerNo },
                    data: { hasFollowing: true }
                })
            }
        }
    });

    return { addUserFollower };
};

const doDeleteUserFollower = (userNo: number, followerNo: number) => {
    const [ deleteUserFollower ]: any = useMutation(DELETE_USER_FOLLOWER_QUERY, {
        variables: {
            userNo,
            followerNo
        },
        update: (proxy, {data}: any) => {
            if(data.deleteUserFollower) {
                // ADD
                proxy.writeQuery({
                    query: GET_HAS_FOLLOWING_QUERY,
                    variables: { userNo, followerNo },
                    data: { hasFollowing: false }
                })
            }
        }
    });

    return { deleteUserFollower };
};

// EDIT
const UPDATE_USER_QUERY = gql `
    mutation UpdateUser(
        $user: InputUser
    ) {
        updateUser(
            user: $user
        ) {
            displayName
            description
        }
    }
`;

interface IEditProfileProps {
    user: IUser
    children?: any
}

const EditProfile = (props: IEditProfileProps) => {
    const { user } = props;

    const toasterRef = useRef<Toaster>();

    const refToasterHandler = {
        toaster: (ref: Toaster) => toasterRef.current = ref
    };

    const [displayName, setDisplayName] = useState(user.displayName);
    const [description, setDescription] = useState(user.description);
    const [toasters] = useState([]);

    const onChangeDisplayName = (event: React.FormEvent<HTMLInputElement>) => {
        setDisplayName(event.currentTarget.value);
    };

    const onChangeDescription = (event: React.FormEvent<HTMLTextAreaElement>) => {
        setDescription(event.currentTarget.value);
    }

    return (
        <div className="user-container">
            <div className="grid-container">
                <div className="default-grid-container">
                    <div className="pad-w-1 pad-h-1">
                        <Mutation
                            mutation={UPDATE_USER_QUERY}
                        >
                            {(updateUser: any) => (
                                <form onSubmit={event => {
                                    event.preventDefault();

                                    updateUser({
                                        variables: {
                                            user: {
                                                no: user.no,
                                                displayName,
                                                description
                                            }
                                        }
                                    });

                                    if(toasterRef && toasterRef.current) {
                                        toasterRef.current.show({message: "Success"});
                                    }
                                }}>
                                    <Label>
                                        <H5>Name</H5>
                                        <FormGroup labelFor="userName">
                                            <InputGroup className="input-user-name"
                                                        placeholder="User Name"
                                                        large={false}
                                                        fill={false}
                                                        disabled={true}
                                                        value={user.name}
                                            />
                                        </FormGroup>
                                    </Label>
                                    <Label>
                                        <H5>Display name</H5>
                                        <FormGroup labelFor="displayName">
                                            <InputGroup className="input-display-name"
                                                        placeholder="Display Name"
                                                        large={false}
                                                        fill={false}
                                                        onChange={onChangeDisplayName}
                                                        value={displayName}
                                            />
                                        </FormGroup>
                                    </Label>
                                    <Label>
                                        <H5>Description</H5>
                                        <FormGroup labelFor="description">
                                            <TextArea growVertically={true}
                                                      large={false}
                                                      fill={true}
                                                      onChange={onChangeDescription}
                                                      value={description}
                                            />
                                        </FormGroup>
                                    </Label>
                                    <Label>
                                        <H5>Email</H5>
                                        <FormGroup labelFor="email">
                                            <InputGroup className="input-email"
                                                        placeholder="Email"
                                                        large={false}
                                                        fill={false}
                                                        disabled={true}
                                                        value={user.email}
                                            />
                                        </FormGroup>
                                    </Label>
                                    <Label>
                                        <H5>Account Provider</H5>
                                        <FormGroup labelFor="provider">
                                            <InputGroup className="input-provider"
                                                        placeholder="Provider"
                                                        large={false}
                                                        fill={false}
                                                        disabled={true}
                                                        value={user.provider}
                                            />
                                        </FormGroup>
                                    </Label>
                                    <div className="group-center pad-w-1 pad-h-3">
                                        <Button type="submit" minimal={true}>Save</Button>
                                    </div>
                                </form>
                            )}
                        </Mutation>
                        <Toaster position={Position.BOTTOM} ref={refToasterHandler.toaster}>
                            {
                                toasters.map((toast: IToastProps, idx: number) => {
                                    return <Toast key={idx} message={toast.message} />
                                })
                            }
                        </Toaster>
                    </div>
                </div>
            </div>
        </div>
    );
};