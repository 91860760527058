import * as React from "react";

import "node_modules/react-quill/dist/quill.snow.css";
import "../../common/css/quill-editor.css";
import "./AddShop.css";
import EditShop from "./EditShop";
import {ShopCategory, IShop} from "../common/Domains";
import {EDIT_DEFAULT_SHOP_CONTENT} from "../common/Constant";
import {RouteComponentProps, withRouter} from "react-router";
import {Mutation} from "react-apollo";
import {H5, IToastProps, Position, Toast, Toaster} from "@blueprintjs/core";
import {gql} from "apollo-boost";
import {SHOP_QUERY} from "./Shop";
import {UPDATE_SHOP_QUERY} from "./ModifyShop";
import {getUserInfo, isBlockedUser} from "../common/UserInfo";
import {redirectCustom, redirectRoot} from "../utils/PageFolwUtils";

const isEnableAutoSave = false;

const ADD_SHOP_QUERY = gql `
    mutation AddShop(
        $shop: InputShop
    ) {
        addShop(
            shop: $shop
        ) {
            shopId
            groupId
            category {
                categoryId
            }
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            name
            description
            content
            type
            status
            isDelete
            shopEnv {
                envId
                location
                latitude
                longitude
                country
                isClosed
                openingTime
                closingTime
                homePageUrl
                telNumber
                keywords
            }
            shopImages {
                shopImageId
                image {
                    imageId
                    realName
                }
                isDefault
                createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

interface IProps extends RouteComponentProps {
    groupId?: number
    authorNo?: number
}

interface IState {
    shopId: number
    toasters: IToastProps[]
}

class AddShop extends React.Component<IProps, IState> {
    private toaster: Toaster;

    private refToasterHandler = {
        toaster: (ref: Toaster) => this.toaster = ref
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            shopId: -1,
            toasters: []
        }
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public render() {
        if(isBlockedUser()) {
            redirectRoot();
        }

        const { groupId } = this.props.match.params as IProps;
        const { authorNo } = (this.props.location.state || { authorNo: null }) as IProps;

        const { shopId } = this.state;

        const currentUser = getUserInfo();

        if(groupId && groupId > 0 && authorNo !== currentUser.no) {
            return <div className="pad-t-3">error... notice! invalid author</div>;
        }

        const shop = {
            groupId: groupId ? groupId : -1,
            name: "",
            description: "",
            category: {
                categoryId: ShopCategory.ITEM
            },
            content: EDIT_DEFAULT_SHOP_CONTENT,
            shopEnv: {
                location: "",
                latitude: "",
                longitude: "",
                country: "",
                isClosed: false,
                openingTime: "",
                closingTime: "",
                homePageUrl: "",
                telNumber: "",
                keywords: ""
            }
        } as IShop;

        return (
            <div className="default-grid-container add-shop-container">
                <div className="pad-h-1">
                    <div className="header-wrapper-2">
                        <H5 className="title">New shop</H5>
                    </div>
                </div>
                <Mutation
                    mutation={shopId > 0 ? UPDATE_SHOP_QUERY : ADD_SHOP_QUERY}
                    update={(cache: any, {data: {addShop, updateShop}}: any) => {
                        const cachedShopId = addShop ? addShop.shopId : updateShop.shopId;

                        cache.writeQuery({
                            query: SHOP_QUERY,
                            variables: {shopId: cachedShopId},
                            data: {shop: addShop}
                        });
                    }}
                    onCompleted={this.onCompleteSave}
                >
                    {(saveShop: any) => (
                        <EditShop
                            isTemporary={false}
                            shopId={this.state.shopId}
                            shop={shop}
                            saveHandler={saveShop}
                            isEnableAutoSave={isEnableAutoSave}
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                        />
                    )}
                </Mutation>
                <Toaster position={Position.BOTTOM} ref={this.refToasterHandler.toaster}>
                    {
                        this.state.toasters.map((toast, idx) => {
                            return <Toast key={idx} message={toast.message}/>;
                        })

                    }
                </Toaster>
            </div>
        );
    }

    private onCompleteSave = (data: any) => {
        this.toaster.show({message: "Success"});

        const shop = data.addShop || data.updateShop as IShop;

        redirectCustom("/shop/" + shop.shopId);
    }
}

export default withRouter(AddShop);
