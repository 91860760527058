import {gql} from "apollo-boost";
import {IBoard, IBoardLike, IUser} from "../common/Domains";
import {getUserInfo, isUserLogin} from "../common/UserInfo";
import {useMutation, useQuery}from "react-apollo-hooks";
import {Icon} from "@blueprintjs/core";
import * as React from "react";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";

const BOARD_LIKES_QUERY = gql `
    query BoardLikes($boardId: Int) {
        boardLikes(boardId: $boardId) {
            likeId
            board {
                boardId
            }
            userNo
            createdDate
        }
    }
`;

const TOGGLE_BOARD_LIKE_QUERY = gql `
    mutation ToggleBoardLike($boardLike: InputBoardLike) {
        toggleBoardLike(boardLike: $boardLike) {
            likeId
            board {
                boardId
            }
            userNo
            createdDate
        }
    }
`;

interface IToggleBoardLikeProps {
    board: IBoard
}

export const ToggleBoardLikeWrapper = (props: IToggleBoardLikeProps) => {
    const currentUser = getUserInfo();

    const isExistsMyLike = (likes: IBoardLike[]) => {
        const myLike = likes.filter((like: IBoardLike) => {
            return like.userNo === currentUser.no;
        });

        return (myLike && myLike.length > 0 ? true : false);
    }

    const { toggleBoardLike } = doToggleBoardLike(currentUser, props.board);

    const {loading, error, data } = useQuery(BOARD_LIKES_QUERY, { variables: { boardId: props.board.boardId }});

    if(loading) {
        return <LoadingPage isOnlyMessage={true} />;
    }

    if(error) {
        return <ErrorPage />;
    }

    const { boardLikes } = data || { boardLikes: null };

    if(!boardLikes) {
        return <ErrorPage />;
    }

    const isMarked = isExistsMyLike(boardLikes);

    return (
        <>
            { isUserLogin(currentUser) && <Icon icon="heart" iconSize={15} className={isMarked ? "marked" : ""} onClick={toggleBoardLike} /> }
            { !isUserLogin(currentUser) && <Icon icon="heart" iconSize={15} /> }
            &nbsp;
            {boardLikes.length}
            {/*{boardLikes.map((boardLike: IBoardLike) => {*/}
            {/*    return <span key={boardLike.userNo}>{boardLike.userNo}</span>;*/}
            {/*})}*/}
        </>
    );
}

const doToggleBoardLike = (user: IUser, board: IBoard) => {
    const [ toggleBoardLike ]: any = useMutation(TOGGLE_BOARD_LIKE_QUERY, {
        variables: {
            boardLike: {
                board: {
                    boardId: board.boardId
                },
                userNo: user.no
            }
        },
        update: (proxy, { data }: any) => {
            const { boardLikes }: any = proxy.readQuery({
                query: BOARD_LIKES_QUERY,
                variables: {
                    boardId: board.boardId
                }
            });

            if(data.toggleBoardLike) {
                // ADD
                proxy.writeQuery({
                    query: BOARD_LIKES_QUERY,
                    variables: { boardId: board.boardId },
                    data: { boardLikes: boardLikes.concat([ data.toggleBoardLike ]) }
                });
            }
            else {
                // DELETE
                const newBoardLikes = boardLikes.filter((boardLike: IBoardLike) => {
                    return boardLike.userNo !== user.no;
                });

                proxy.writeQuery({
                    query: BOARD_LIKES_QUERY,
                    variables: { boardId: board.boardId },
                    data: { boardLikes: newBoardLikes }
                });
            }
        }
    });

    return { toggleBoardLike };
};