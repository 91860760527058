import * as React from "react";
import MainMenu from "../menus/MainMenu";

class Header extends React.Component {
    public render() {
        return (
            <MainMenu />
        );
    }
}

export default Header;