import {Button, FormGroup, InputGroup, Label} from "@blueprintjs/core";
import * as React from "react";
import {gql} from "apollo-boost";
import {Mutation} from "react-apollo";
import {AuthError} from "../common/Domains";
import {EMAIL_PATTERN} from "../common/Constant";
import {setToken} from "../../store/cache/Token";
import {redirectRoot} from "../utils/PageFolwUtils";
import {useForm} from "react-hook-form";

const LOGIN_QUERY = gql `
    mutation Login($email: String, $password: String) {
        login(email: $email, password: $password) {
            tokenType
            accessToken
            error
        }
    }
`;

interface ILoginFormProps {
    dummy?: number
}

interface ILoginFormState {
    error?: AuthError
}

class LoginForm extends React.Component<ILoginFormProps, ILoginFormState> {
    constructor(props: ILoginFormProps) {
        super(props);

        this.state = {
            error: undefined
        }
    }

    public render() {
        return (
            <Mutation
                mutation={LOGIN_QUERY}
                update={(cache: any, {data: {login}}: any) => {
                    const {accessToken} = login;

                    if (accessToken) {
                        setToken(accessToken);
                    }
                }}
                onCompleted={this.onCompletedLogin}
            >
                {(login: any) => (
                    <>
                        <EditLoginForm loginHandler={login} error={this.state.error} />
                    </>
                )}
            </Mutation>
        );
    }

    private onCompletedLogin = (data: any) => {
        if(!data || !data.login) {
            this.setState({
                error: AuthError.INVALID_USER
            });

            return ;
        }

        if(!data.login.error) {
            redirectRoot();
        }
        else {
            this.setState({
                error: data.login.error
            });
        }
    }
}

export default LoginForm;

interface IUserFormData {
    email: string
    password: string
}

interface ILoginFormFormProps {
    loginHandler: any
    error?: AuthError
}

const EditLoginForm = (props: ILoginFormFormProps) => {
    const { loginHandler, error } = props;

    const { register, handleSubmit, errors } = useForm<IUserFormData>();

    const submitHandler = (formData: any) => {
        if(loginHandler) {
            loginHandler({
                variables: {
                    email: formData.email,
                    password: formData.password
                }
            });
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit(submitHandler)}>
                <div>
                    <Label>
                        <FormGroup
                            labelFor="email"
                        >
                            <InputGroup name="email" className="input-email" type="text"
                                        placeholder="Email"
                                        inputRef={register({ required: true, minLength: 3, maxLength: 100, pattern: EMAIL_PATTERN })}
                            />
                            <div className="valid-error-wrapper">
                                {errors.email && errors.email.type === "required" && (
                                    <p className="valid-error">이메일을 입력해주세요.</p>
                                )}
                                {errors.email && errors.email.type === "minLength" && (
                                    <p className="valid-error">이메일을 3자 이상 입력해주세요.</p>
                                )}
                                {errors.email && errors.email.type === "maxLength" && (
                                    <p className="valid-error">이메일을 100자 이하 입력해주세요.</p>
                                )}
                                {errors.email && errors.email.type === "pattern" && (
                                    <p className="valid-error">이메일형식에 맞게 입력해주세요.</p>
                                )}
                            </div>
                        </FormGroup>
                    </Label>
                    <Label>
                        <FormGroup
                            labelFor="password"
                        >
                            <InputGroup name="password" className="input-password"  type="password"
                                        placeholder="Password"
                                        inputRef={register({ required: true, minLength: 1, maxLength: 200 })}
                            />
                            <div className="valid-error-wrapper">
                                {errors.password && errors.password.type === "required" && (
                                    <p className="valid-error">패스워드을 입력해주세요.</p>
                                )}
                                {errors.password && errors.password.type === "minLength" && (
                                    <p className="valid-error">패스워드을 1자 이상 입력해주세요.</p>
                                )}
                                {errors.password && errors.password.type === "maxLength" && (
                                    <p className="valid-error">패스워드을 200자 이하 입력해주세요.</p>
                                )}
                            </div>
                        </FormGroup>
                    </Label>
                    <div className="group-center">
                        <Button type="submit" className="button-s-1" minimal={true} large={true}>Sign in</Button>
                    </div>
                    <div className="valid-error-wrapper">
                        { error && (error === AuthError.INVALID_USER || error === AuthError.NOT_EXISTS_USER) && (
                            <p className="valid-error">정확한 이메일 비밀번호를 입력 후 다시 로그인해주세요.</p>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};