import * as React from "react";
import {
    IBoard,
    IBoardImage,
    RecommendType, IBoardRecommend, BoardCategory
} from "../common/Domains";
import {Query} from "react-apollo";
import HBox from "../card/HBox";
import {CardAlign, ICardData, ICardTag} from "../card/CardDomains";
import {ErrorPage} from "../utils/LoadingAndError";
import {RECOMMEND_BOARD_QUERY} from "../special/Recommend";

class Recommend extends React.Component {

    public render() {

        return (
            <Query
                query={RECOMMEND_BOARD_QUERY}
                variables={{
                    recommendType: RecommendType.PICK,
                    categoryId: BoardCategory.STORIES,
                    offset: 0,
                    limit: 1
                }}

                fetchPolicy="cache-and-network"
            >
                {({loading, error, data, fetchMore}: any) => {
                    if(error) {
                        return <ErrorPage isOnlyMessage={true} />;
                    }

                    const { recommendBoards } = data || { recommendBoards: [] };

                    return (
                        <>
                            {
                                (recommendBoards || []).map((boardRecommend: IBoardRecommend) => {
                                    const board = boardRecommend.board as IBoard;

                                    const images = board.boardImages.filter((boardImage: IBoardImage) => {
                                        return boardImage.isDefault;
                                    })
                                    .map((boardImage: IBoardImage) => {
                                        return boardImage.image;
                                    });

                                    const boxData = {
                                        id: board.boardId + "",
                                        link: {
                                            path: "/board/view/stories/" + board.boardId,
                                            localParams: {}
                                        },
                                        images,
                                        date: board.createdDate,
                                        title: board.title,
                                        subTitle: "",
                                        subSubTitle: "",
                                        description: board.description,
                                        author: board.author.displayName,
                                        tags: [] as ICardTag[] // getTags(ITEM_ENV_METAS, board.boardEnv)
                                    } as ICardData;


                                    return (
                                        <HBox key={boxData.id} data={boxData} large={true} cover={true} titleLine={3} descriptionLine={5} align={CardAlign.BOTTOM_LEFT} />
                                    );
                                })
                            }
                            <div style={{clear: "left"}} />
                        </>
                    );
                }}
            </Query>
        );
    };
}

export default Recommend;