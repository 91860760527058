import * as React from "react";
import {IItemComment} from "../../../../common/Domains";
import {Button, ButtonGroup} from "@blueprintjs/core";
import {gql} from "apollo-boost";
import {Query} from "react-apollo";
import {getUserInfo} from "../../../../common/UserInfo";
import {useState} from "react";
import {distanceInWordsToNow} from "date-fns";
import EditableComment, {AddComment, DeleteButton, ModifyButton} from "./EditableComment";
import {Profile, ProfileType} from "../../../../user/Profile";

import "../../../../common/css/Comments.css";
import {ErrorPage} from "../../../../utils/LoadingAndError";

export const ITEM_COMMENTS_QUERY = gql `
    query ItemComments(
        $itemId: Int,
        $offset: Int,
        $limit: Int
    ) {
        itemComments(
            itemId: $itemId,
            offset: $offset,
            limit: $limit
        ) {
            commentId
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            content
            commentType
            item {
                itemId
            }
        }
    }
`;

interface ICommentsProps {
    itemId: number
}

class Comments extends React.Component<ICommentsProps, {}> {
    public render() {
        const { itemId } = this.props;

        return (
            <div className="comments-wrapper">
                <Query
                    query={ITEM_COMMENTS_QUERY}
                    variables={{
                        itemId,
                        offset: 0,
                        limit: 10
                    }}
                    fetchPolicy="cache-and-network"
                >
                    {({ loading, error, data, fetchMore } : any) => {
                        if(error) {
                            return <ErrorPage />;
                        }

                        const { itemComments } = data || { itemComments: null };
                        const offset = itemComments ? itemComments.length : 0;

                        return (
                            <>
                                <Comment itemComments={itemComments} itemId={itemId} />
                                { offset > 0 &&
                                    <LoadMore fetchMore={fetchMore} offset={offset} />
                                }
                            </>
                        );
                    }}
                </Query>
            </div>
        );
    }
}

const LoadMore = ({ fetchMore, offset }: any) => {
    const loadMoreHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        fetchMore({
            variables: {
                offset
            },
            updateQuery: (prev: any, {fetchMoreResult}: any) => {
                if(!fetchMoreResult) {
                    return prev;
                }

                return Object.assign({}, {
                    itemComments: [...prev.itemComments, ...fetchMoreResult.itemComments]
                });
            }
        });
    };

    return (
        <div className="group-center pad-h-3">
            <Button minimal={true} onClick={loadMoreHandler} >Load more</Button>
        </div>
    );
};

export default Comments;

interface ICommentProps {
    itemComments: IItemComment[]
    itemId: number
}

const Comment = (props: ICommentProps) => {
    const { itemComments, itemId } = props;

    return (
        <div className="comments-container">
            <div className="add-comment-wrapper">
                <AddComment itemId={itemId} />
            </div>
            <div className="comment-item-list">
                <ul>
                    { (itemComments || []).map((itemComment: IItemComment) => {
                        return (
                            <CommentWrapper key={itemComment.commentId} itemComment={itemComment} />
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

interface ICommentWrapper {
    itemComment: IItemComment
}

const CommentWrapper = (props: ICommentWrapper) => {
    const { itemComment } = props;

    const currentUser = getUserInfo();

    const distanceCreatedDate = distanceInWordsToNow(itemComment.createdDate);
    const isMyComment = currentUser.no === itemComment.author.no;

    const [isEditableComment, setIsEditableComment] = useState(false);

    const onChangeModify = (isEnable: boolean) => {
        setIsEditableComment(isEnable);
    };

    return (
        <li>
            <div className="comment-item-container">
                <Profile user={itemComment.author}
                         profileType={ProfileType.SIMPLE}
                         large={false} isForceUserDetail={true} disableDescription={true}
                >
                    <div className="date">{distanceCreatedDate}</div>
                    { isMyComment && <EditableComment commentId={itemComment.commentId} content={itemComment.content} userNo={currentUser.no} isEditable={isEditableComment} onChangeEditable={setIsEditableComment} /> }
                    { !isMyComment && <EditableComment commentId={itemComment.commentId} content={itemComment.content} userNo={currentUser.no} isEditable={false} /> }
                    {!isEditableComment &&
                    <div className="operations">
                        <ButtonGroup minimal={true}>
                            { isMyComment && <ModifyButton commentId={itemComment.commentId} itemId={itemComment.item.itemId} changeModifyState={onChangeModify} /> }
                            { isMyComment && <DeleteButton commentId={itemComment.commentId} itemId={itemComment.item.itemId} /> }
                        </ButtonGroup>
                    </div>}
                </Profile>
            </div>
        </li>
    );
};