import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import {dataIdFromObject, resolvers, typeDefs} from "./resolvers/resolvers";
import {API_BASE_URL} from "../components/common/Constant";
import {getToken} from "./cache/Token";

const httpLink = createHttpLink({
    uri: API_BASE_URL + "/graphql"
});

const authLink = setContext((_, { headers }) => {
    const token = getToken();

    return {
        headers: {
            ...headers,
            Authentication: token ? `Bearer ${token}` : "",
        }
    }
});

const cache = new InMemoryCache({ dataIdFromObject });
export const Client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    typeDefs,
    resolvers
});

const cacheData = {
        itemImages: []
};

cache.writeData({ data: cacheData });

Client.onResetStore((): any => {
    cache.writeData({data: cacheData });
});