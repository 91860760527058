import * as React from "react";
import {gql} from "apollo-boost";
;import {
    getItemCategoryKey,
    IItem,
    IItemImage,
} from "../common/Domains";
import {Button, H5} from "@blueprintjs/core";
import {RouteComponentProps} from "react-router-dom";
import {ICardData} from "../card/CardDomains";
import {useQuery} from "react-apollo-hooks";
import * as InfiniteScroll from 'react-infinite-scroller';
import VBox, {LoadVBox} from "../card/VBox";
import {CARD_WIDTH, LOAD_DATA_COUNT, LOAD_EMPTY_DATA_COUNT} from "../common/Constant";

import "./Items.css";
import {ErrorPage} from "../utils/LoadingAndError";
import {getUserInfo} from "../common/UserInfo";

export const TEMPORARY_SHOPS_QUERY = gql `
    query TemporaryItems (
        $keyword: String,
        $categoryInfo: CategoryInfo,
        $userNo: Int,
        $offset: Int,
        $limit: Int
    ) {
        temporaryItems(
            keyword: $keyword, categoryInfo: $categoryInfo, userNo: $userNo, offset: $offset, limit: $limit
        ) {
            itemId
            category {
                categoryId
                key
            }
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            brand {
                brandId
                korName
                engName
                desc
            }
            title
            description
            content
            quantity
            price
            discountPrice
            discount
            discountUnit
            status
            isDelete
            counter {
                counterId
                count
            }
            itemEnv {
                envId
                model
                origin
                manufacturer
                material
                size
                composition
                url
            }
            itemImages {
                itemImageId
                image {
                    imageId
                    realName
                }
                isDefault
                createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

interface IProps extends RouteComponentProps {
    keyword?: string
    category?: string
}

interface IStatus {
    keyword: string
    categoryKey: string
}

class TemporaryItems extends React.Component<IProps, IStatus> {
    constructor(props: IProps) {
        super(props);

        const { keyword, category } = this.props.match.params as IProps;

        this.state = {
            keyword: keyword || "",
            categoryKey: getItemCategoryKey(category)
        }
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStatus>, snapshot?: any): void {
        const prevData = prevProps.match.params as IProps;
        const currData = this.props.match.params as IProps;

        if(prevData.category !== currData.category) {
            this.setState({
                categoryKey: getItemCategoryKey(currData.category)
            });
        }
    }

    public render() {
        const currentUser = getUserInfo();

        return (
            <div className="items-container">
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="header-wrapper-2">
                            <H5 className="title">Products(Draft)</H5>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="items-wrapper pad-t-1">
                            <ItemsItems keyword={this.state.keyword} categoryId={this.state.categoryKey} userNo={currentUser.no} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TemporaryItems;

interface IItemItems {
    keyword: string
    categoryId: string
    userNo?: number
}

export const ItemsItems = (props: IItemItems) => {
    const { keyword, categoryId, userNo } = props;

    const { loading, error, data, fetchMore, networkStatus } = useQuery(TEMPORARY_SHOPS_QUERY, {
        variables: {
            keyword,
            categoryId,
            userNo,
            offset: 0,
            limit: LOAD_DATA_COUNT
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: false
    });

    if(loading || networkStatus === 4) {
        return <LoadingBox key="0" />;
    }

    if(error) {
        return <ErrorPage />;
    }

    const { temporaryItems } = data || { temporaryItems: null };

    if(!temporaryItems || temporaryItems.length === 0) {
        const emptyData = {
            id: "0",
            link: {
                path: ""
            },
            title: "No results"
        } as ICardData;

        return (
            <>
                <VBox key={emptyData.id} data={emptyData} large={true} width={CARD_WIDTH} isEmpty={true} />
                <div style={{clear: "left"}} />
            </>
        );
    }

    const offset = temporaryItems ? temporaryItems.length : 0;
    const hasMore = (offset !== 0 && offset % LOAD_DATA_COUNT === 0) ? true : false;

    const loadMoreHandler = () => {
        fetchMore({
            variables: {
                offset
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                if(!fetchMoreResult) {
                    return prev;
                }

                return Object.assign({}, prev, {
                    temporaryItems: [ ...prev.temporaryItems, ...fetchMoreResult.temporaryItems ]
                });
            }
        });
    };

    return (
        <>
            <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={loadMoreHandler}
                hasMore={hasMore}
                loader={<LoadingBox key="0" />}>
                {
                    (temporaryItems || []).map((item: IItem) => {
                        const images = item.itemImages.filter((itemImage: IItemImage) => {
                            return itemImage.isDefault;
                        })
                            .map((itemImage: IItemImage) => {
                                return itemImage.image;
                            });

                        const boxData = {
                            id: item.itemId + "",
                            link: {
                                path: "/temporaryItem/" + item.itemId,
                                localParams: {
                                    keyword,
                                    categoryId,
                                    userNo,
                                    offset: 0,
                                    limit: LOAD_DATA_COUNT
                                }
                            },
                            images,
                            date: item.createdDate,
                            title: item.title,
                            subTitle: item.brand.korName,
                            subSubTitle: item.brand.desc,
                            description: item.description,
                            author: item.author.displayName,
                        } as ICardData;

                        return <VBox key={boxData.id} data={boxData} large={true} width={CARD_WIDTH} />
                    })
                }
                <div style={{clear: "left"}} />
            </InfiniteScroll>
            <LoadMore loadMoreHandler={loadMoreHandler} />
        </>
    );
};

const LoadMore = ({ loadMoreHandler }: any) => {
    return (
        <div className="group-center pad-h-3">
            <Button minimal={true} onClick={loadMoreHandler} >Load more</Button>
        </div>
    );
};

export const LoadingBox = () => {
    return (
        <>
            {[...Array(LOAD_EMPTY_DATA_COUNT)].fill(0).map((_: any, index: number) => {
                const testData = {
                    id: index + "",
                    link: {
                        path: ""
                    },
                    title: "No results"
                } as ICardData;

                return <LoadVBox key={testData.id} data={testData} width={CARD_WIDTH} />;
            })}
            <div style={{clear: "left"}} />
        </>
    );
};