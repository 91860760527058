import {Alert, Button, FormGroup, H5, InputGroup, TextArea} from "@blueprintjs/core";
import * as React from "react";
import {gql} from "apollo-boost";
import {Mutation} from "react-apollo";
import {useForm} from "react-hook-form";
import {EMAIL_PATTERN, FACEBOOK_AUTH_URL, GOOGLE_AUTH_URL} from "../common/Constant";
import {redirectRoot} from "../utils/PageFolwUtils";

import "./Signup.css";

const SIGNUP_QUERY = gql `
    mutation Signup($email: String, $password: String, $displayName: String, $description: String) {
        signup(email: $email, password: $password, displayName: $displayName, description: $description) {
            success
            error
            message
            url
        }
    }
`;

interface ISignupProps {
    dummy?: number
}

interface ISignupState {
    error?: string
    isSuccess: boolean
    isOpen: boolean
}

class Signup extends React.Component<ISignupProps, ISignupState> {
    constructor(props: ISignupProps) {
        super(props);

        this.state = {
            error: "",
            isSuccess: false,
            isOpen: false
        };
    }

    public render() {
        return (
            <div className="grid-container pad-h-3">
                <div className="default-grid-container signup-wrapper">
                    <div className="pad-h-1">
                        <div className="header-wrapper-2">
                            <H5 className="title">Sign up</H5>
                        </div>
                        <div className="pad-h-1 pad-w-1-half">
                            <SocialLogin />
                        </div>
                        <div className="pad-h-1 group-center">
                            또는
                        </div>
                        <Mutation
                            mutation={SIGNUP_QUERY}
                            onCompleted={this.onCompletedSignup}
                        >
                            {(signup: any) => (
                                <EditSignup singupHandler={signup} error={this.state.error} />
                            )}
                        </Mutation>
                        <SignupAlert isSuccess={this.state.isSuccess} isOpen={this.state.isOpen} closeAlertHandler={this.closeAlertHandler} />
                    </div>
                </div>
            </div>
        );
    }

    private onCompletedSignup = (data: any) => {
        if(data && data.signup && data.signup.success) {
            this.setState({
                error: "",
                isSuccess: true,
                isOpen: true
            })
        }
        else {
            this.setState({
                error: "가입을 처리하지 못했습니다.",
                isSuccess: true,
                isOpen: true
            });
        }
    };

    private closeAlertHandler = () => {
        this.setState({
            isOpen: false
        })
    }
}

export default Signup;

interface IEditSignup {
    singupHandler: any
    error?: string
}

const EditSignup = (props: IEditSignup) => {
    const { singupHandler, error } = props;

    const { register, handleSubmit, errors, watch } = useForm();

    const submitHandler = (formData: any) => {
        if(singupHandler) {
            singupHandler({
                variables: {
                    email: formData.email,
                    password: formData.password,
                    displayName: formData.displayName,
                    description: formData.description
                }
            });
        }
    };

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <FormGroup
                className="user-input-wrapper"
                labelFor="email"
                label="Email"
            >
                <InputGroup name="email" className="input-email" type="text"
                            placeholder="이메일을 입력하세요"
                            inputRef={register({
                                required: true, minLength: 2, maxLength: 100,
                                pattern: EMAIL_PATTERN
                            })}
                />
                <div className="valid-error-wrapper">
                    {errors.email && errors.email.type === "required" && (
                        <p className="valid-error">이메일을 입력해주세요.</p>
                    )}
                    {errors.email && errors.email.type === "minLength" && (
                        <p className="valid-error">이메일을 2자 이상 입력해주세요.</p>
                    )}
                    {errors.email && errors.email.type === "maxLength" && (
                        <p className="valid-error">이메일을 100자 이하 입력해주세요.</p>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                        <p className="valid-error">이메일형식에 맞게 입력해주세요.</p>
                    )}
                </div>
            </FormGroup>
            <FormGroup
                className="user-input-wrapper"
                labelFor="emailConfirm"
                label="Confirm Email"
            >
                <InputGroup name="emailConfirm" className="input-emailCheck" type="text"
                            placeholder="이메일을 한번더 입력하세요"
                            inputRef={register({
                                required: true, minLength: 2, maxLength: 100,
                                pattern: EMAIL_PATTERN,
                                validate: (emailConfirm: string) => emailConfirm === watch("email")
                            })}
                />
                <div className="valid-error-wrapper">
                    {errors.emailConfirm && errors.emailConfirm.type === "required" && (
                        <p className="valid-error">이메일을 입력해주세요.</p>
                    )}
                    {errors.emailConfirm && errors.emailConfirm.type === "minLength" && (
                        <p className="valid-error">이메일을 2자 이상 입력해주세요.</p>
                    )}
                    {errors.emailConfirm && errors.emailConfirm.type === "maxLength" && (
                        <p className="valid-error">이메일을 100자 이하 입력해주세요.</p>
                    )}
                    {errors.emailConfirm && errors.emailConfirm.type === "pattern" && (
                        <p className="valid-error">이메일형식에 맞게 입력해주세요.</p>
                    )}
                    {errors.emailConfirm && errors.emailConfirm.type === "validate" && (
                        <p className="valid-error">위 이메일과 다릅니다. 다시 입력해주세요.</p>
                    )}
                </div>
            </FormGroup>
            <FormGroup
                className="user-input-wrapper"
                labelFor="Password"
                label="Password"
            >
                <InputGroup name="password" className="input-password" type="password"
                            placeholder="패스워드를 입력하세요"
                            inputRef={register({ required: true, minLength: 7, maxLength: 100 })}
                />
                <div className="valid-error-wrapper">
                    {errors.password && errors.password.type === "required" && (
                        <p className="valid-error">패스워드를 입력해주세요.</p>
                    )}
                    {errors.password && errors.password.type === "minLength" && (
                        <p className="valid-error">패스워드를 7자 이상 입력해주세요.</p>
                    )}
                    {errors.password && errors.password.type === "maxLength" && (
                        <p className="valid-error">패스워드를 100자 이하 입력해주세요.</p>
                    )}
                </div>
            </FormGroup>
            <FormGroup
                className="user-input-wrapper"
                labelFor="passwordConfirm"
                label="Confirm Password"
            >
                <InputGroup name="passwordConfirm" className="input-passwordCheck" type="password"
                            placeholder="패스워드를 한번더 입력하세요"
                            inputRef={register({
                                required: true, minLength: 7, maxLength: 100,
                                validate: (passwordConfirm: string) => passwordConfirm === watch('password')
                            })}
                />
                <div className="valid-error-wrapper">
                    {errors.passwordConfirm && errors.passwordConfirm.type === "required" && (
                        <p className="valid-error">패스워드를 입력해주세요.</p>
                    )}
                    {errors.passwordConfirm && errors.passwordConfirm.type === "minLength" && (
                        <p className="valid-error">패스워드를 7자 이상 입력해주세요.</p>
                    )}
                    {errors.passwordConfirm && errors.passwordConfirm.type === "maxLength" && (
                        <p className="valid-error">패스워드를 100자 이하 입력해주세요.</p>
                    )}
                    {errors.passwordConfirm && errors.passwordConfirm.type === "validate" && (
                        <p className="valid-error">위 패스워드와 다릅니다. 다시 입력해주세요.</p>
                    )}
                </div>
            </FormGroup>
            <FormGroup
                className="user-input-wrapper"
                labelFor="displayName"
                label="Display Name"
            >
                <InputGroup name="displayName" className="input-displayName" type="text"
                            placeholder="보여줄 이름을 입력하세요"
                            inputRef={register({ required: true, minLength: 2, maxLength: 20 })}
                />
                <div className="valid-error-wrapper">
                    {errors.displayName && errors.displayName.type === "required" && (
                        <p className="valid-error">닉네임을 입력해주세요.</p>
                    )}
                    {errors.displayName && errors.displayName.type === "minLength" && (
                        <p className="valid-error">닉네임을 2자 이상 입력해주세요.</p>
                    )}
                    {errors.displayName && errors.displayName.type === "maxLength" && (
                        <p className="valid-error">닉네임을 20자 이하 입력해주세요.</p>
                    )}
                </div>
            </FormGroup>
            <FormGroup
                className="user-input-wrapper"
                labelFor="description"
                label="Description"
            >
                <TextArea name="description" className="input-description"
                            placeholder="소개를 입력하세요"
                            inputRef={register({ required: true, minLength: 10, maxLength: 200 })}
                            fill={true}
                            growVertically={true}
                />
                <div className="valid-error-wrapper">
                    {errors.description && errors.description.type === "required" && (
                        <p className="valid-error">소개를 입력해주세요.</p>
                    )}
                    {errors.description && errors.description.type === "minLength" && (
                        <p className="valid-error">소개를 10자 이상 입력해주세요.</p>
                    )}
                    {errors.description && errors.description.type === "maxLength" && (
                        <p className="valid-error">소개를 200자 이하 입력해주세요.</p>
                    )}
                </div>
            </FormGroup>
            <div className="pad-b-1">* 가입 후 개인정보에서 수정할 수 있습니다.</div>
            <div className="group-center">
                <Button type="submit" className="button-s-1" minimal={true} large={true}>Sign up</Button>
            </div>
            <div className="valid-error-wrapper">
                { error && (
                    <p className="valid-error">이미 가입된 사용자이거나 가입을 처리하지 못했습니다.</p>
                )}
            </div>
        </form>
    );
};

const SocialLogin = () => {
    return (
        <div className="group-center">
            <a href={FACEBOOK_AUTH_URL}>
                <Button className="button-s-1" minimal={true} large={true} fill={true}>Facebook으로 가입하기</Button>
            </a>
            <br />
            <a href={GOOGLE_AUTH_URL}>
                <Button className="button-s-1" minimal={true} large={true} fill={true}>Google로 가입하기</Button>
            </a>
        </div>
    );
};

interface ISignupAlertProps {
    isSuccess: boolean
    isOpen: boolean
    closeAlertHandler: () => void
}

const SignupAlert = (props: ISignupAlertProps) => {
    const { isSuccess, isOpen, closeAlertHandler } = props;

    if(isSuccess) {
        return (
            <Alert
                confirmButtonText="Ok"
                isOpen={isOpen}
                onConfirm={() => {
                    redirectRoot();
                }}
            >
                <p>
                    회원가입 되었습니다.<br />
                    로그인 후 이용해주세요.
                </p>
            </Alert>
        );
    }
    else {
        return (
            <Alert
                confirmButtonText="Ok"
                isOpen={isOpen}
                onConfirm={() => {
                    closeAlertHandler();
                }}
            >
                <p>
                    회원가입 되지 않았습니다.<br />
                    시간이 지나 재시도 해보시고 안되면 관리자에게 문의해주세요.
                </p>
            </Alert>
        );
    }
};