import * as React from "react";
import {
    RecommendType,
    CardType,
    IBoard,
    IBoardImage,
    IBoardRecommend
} from "../common/Domains";
import {Query} from "react-apollo";
import {CardAlign, ICardData} from "../card/CardDomains";
import VCard from "../card/VCard";
import HCard from "../card/HCard";
import VBox from "../card/VBox";
import HBox from "../card/HBox";
import {RECOMMEND_BOARD_QUERY} from "./Recommend";
import {ErrorPage} from "../utils/LoadingAndError";

interface IProps {
    recommendType: RecommendType
    categoryId: number
    cardType: CardType
    titleLine?: number
    descriptionLine?: number
    dataLimit: number
    interactive: boolean
    width: number
}

export const RecommendCategory = (props: IProps) => {
    const { recommendType, categoryId, cardType, dataLimit, titleLine, descriptionLine, interactive, width } = props;

    return (
        <Query
            query={RECOMMEND_BOARD_QUERY}
            variables={{
                recommendType,
                categoryId,
                offset: 0,
                limit: dataLimit
            }}

            fetchPolicy="cache-and-network"
        >
            {({loading, error, data, fetchMore}: any) => {
                if(error) {
                    return <ErrorPage isOnlyMessage={true} />;
                }

                const { recommendBoards } = data|| { recommendBoards: [] };

                if(!recommendBoards || recommendBoards.length === 0) {
                    const emptyData = {
                        id: "0",
                        link: {
                            path: "/board/home"
                        },
                        title: "No results"
                    } as ICardData;

                    return (
                        <>
                            <GetCard key={emptyData.id} data={emptyData} cardType={cardType} interactive={interactive} width={width} isEmpty={true} />
                            <div style={{clear: "left"}} />
                        </>
                    );
                }

                return (
                    <>
                        {
                            (recommendBoards || []).map((boardRecommend: IBoardRecommend) => {
                                const board = boardRecommend.board as IBoard;

                                const images = board.boardImages.filter((boardImage: IBoardImage) => {
                                    return boardImage.isDefault;
                                })
                                .map((boardImage: IBoardImage) => {
                                    return boardImage.image;
                                });

                                const boxData = {
                                    id: board.boardId + "",
                                    link: {
                                        path: "/board/view/special/" + board.boardId,
                                        localParams: {}
                                    },
                                    images,
                                    date: board.createdDate,
                                    title: board.title,
                                    subTitle: "",
                                    subSubTitle: "",
                                    description: board.description,
                                    author: board.author.displayName,
                                } as ICardData;

                                return <GetCard key={boxData.id} data={boxData} cardType={cardType} titleLine={titleLine} descriptionLine={descriptionLine}  interactive={interactive} width={width} isEmpty={false} />
                            })
                        }
                        <div style={{clear: "left"}} />
                    </>
                );
            }}
        </Query>
    );
};

interface ICardProps {
    data: ICardData
    cardType: CardType
    titleLine?: number
    descriptionLine?: number
    interactive: boolean
    width?: number
    isEmpty: boolean
}

export const GetCard = ({data, cardType, titleLine, descriptionLine, interactive, width, isEmpty}: ICardProps) => {
    // CARD
    if(CardType.HORIZON_CARD === cardType) {
        return (
            <HCard data={data} large={true} titleLine={titleLine} descriptionLine={descriptionLine} interactive={interactive} align={CardAlign.RIGHT} isEmpty={isEmpty} />
        );
    }
    else if(CardType.VERTICAL_CARD === cardType) {
        return (
            <VCard data={data} large={true} titleLine={titleLine} descriptionLine={descriptionLine} width={width} interactive={interactive} isEmpty={isEmpty} />
        );
    }
    else if(CardType.SMALL_HORIZON_CARD === cardType) {
        return (
            <HCard data={data} titleLine={titleLine} descriptionLine={descriptionLine} interactive={interactive} align={CardAlign.RIGHT} isEmpty={isEmpty} />
        );
    }
    else if(CardType.SMALL_VERTICAL_CARD === cardType) {
        return (
            <VCard data={data} titleLine={titleLine} descriptionLine={descriptionLine} width={width} interactive={interactive} isEmpty={isEmpty} />
        );
    }

    // BOX
    if(CardType.HORIZON_BOX === cardType) {
        return (
            <HBox data={data} titleLine={titleLine} descriptionLine={descriptionLine} align={CardAlign.RIGHT} isEmpty={isEmpty} />
        );
    }
    else if(CardType.VERTICAL_BOX === cardType) {
        return (
            <VBox width={width} titleLine={titleLine} descriptionLine={descriptionLine} data={data} isEmpty={isEmpty} />
        );
    }
    else if(CardType.SMALL_HORIZON_BOX === cardType) {
        return (
            <HBox data={data} titleLine={titleLine} descriptionLine={descriptionLine} align={CardAlign.RIGHT} isEmpty={isEmpty} />
        );
    }
    else if(CardType.SMALL_VERTICAL_BOX === cardType) {
        return (
            <VBox width={width} data={data} titleLine={titleLine} descriptionLine={descriptionLine} isEmpty={isEmpty} />
        );
    }

    return (
        <VCard data={data} width={width} titleLine={titleLine} descriptionLine={descriptionLine} interactive={interactive} isEmpty={isEmpty} />
    );
};