import Quill, {StringMap} from "quill";
import {API_BASE_URL} from "../common/Constant";

// IMAGE ALIGN
const BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [ 'alt', 'height', 'width', 'class', 'data-original', 'data-width', 'data-height', 'style-data' ];

// FONT SIZE
const Size = Quill.import("attributors/style/size");
Size.whitelist = [
    "9px",
    "10px",
    "11px",
    "12px",
    "13px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "28px",
    "50px",
    "70px",
    "90px",
    "100px",
    "120px",
];

Quill.register(Size, true);

class ImageFormat extends BaseImageFormat {
    public static formats(domNode: any) {
        return ImageFormatAttributesList.reduce((formats, attribute) => {

            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }

            return formats;
        }, {});
    }

    public format(name: any, value: any) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat);

interface IAddImageToEditor {
    editorRef: any
    imagePath: string
}

export const AddImageToEditor = (props: IAddImageToEditor) => {
    const editor = props.editorRef.getEditor();
    const range = editor.getSelection();

    const cursorIndex = (range && range.index && range.index > 0) ? range.index : 0;
    editor.insertEmbed(cursorIndex, 'image', props.imagePath, 'user');
}

export const editorModules: StringMap = {
    imageUpload: {
        url: API_BASE_URL + "/upload",
        method: 'POST',
        name: 'image',
        withCredentials: false,
        headers: { },
        // csrf: { token: 'token', hash: '' },
        // customUploader: (file: any, insertFunc: any) => {
        //     console.log(file);
        //
        //     ApolloStore.Client.mutate( {
        //         mutation: UPLOAD_FILE,
        //         variables: { file }
        //     });
        //
        //     insertFunc("https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Monstera_deliciosa2.jpg/220px-Monstera_deliciosa2.jpg");
        // },
        checkBeforeSend: (file: any, next: any) => {
            next(file);
        },
        callbackOK: (serverResponse: any, insertFunc: any) => {
            insertFunc(serverResponse.url);
        },
        callbackKO: (serverError: any) => {
            console.log(serverError);
        }
    },
    imageResize: {
        parchment: Quill.import('parchment')
    },
    videoResize: {
        parchment: Quill.import('parchment')
    },
    imageDrop: true,
    toolbar: {
        container: [
            [{ 'header': [1, 2, 3, false] }],
            [{ 'font': [] }],
            [{ 'size': Size.whitelist }],
            // [{ 'header': 1 }, { 'header': 2 }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{ 'align': [] }],
            // ['bold', 'italic'],
            [{ 'color': [] }, { 'background': [] }],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            // ['link', 'image', 'video']
            ['link', 'video']
        ],
        handlers: {
        }
    },

};

export const editorFormats: string[] = [
    'header',
    'font',
    'size',
    'align',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'color', 'background',
    'list', 'bullet', 'indent',
    // 'link', 'image', 'video'
    'link', 'image', 'video'
];