import * as React from "react";
import {Card, Elevation, H5, H6, Icon, NonIdealState} from "@blueprintjs/core";
import {distanceInWordsToNow} from "date-fns";
import {Link} from "react-router-dom";
import {Box} from "grommet";
import Dotdotdot from "react-dotdotdot";
import {getPreviewImageIds, ICardData, ICardTags, Preview} from "./CardDomains";

import './SmallVCard.css';
import './VCard.css';
import {THUMBNAIL_SIZES} from "../common/Constant";

interface ICardProps {
    data: ICardData
    large?: boolean
    titleLine?: number
    descriptionLine?: number
    interactive?: boolean
    width?: number
    height?: number
    isEmpty?: boolean
}

interface ICardState {
    data: ICardData
    large: boolean
    titleLine: number
    descriptionLine: number
    interactive: boolean
}

class VCard extends React.Component<ICardProps, ICardState> {
    constructor(props: ICardProps) {
        super(props);

        this.state = {
            data: props.data || {},
            large: props.large || false,
            titleLine: props.titleLine || 1,
            descriptionLine: props.descriptionLine || 1,
            interactive: props.interactive || true
        }

        // this.likeHandler = this.likeHandler.bind(this);
    }

    public render() {
        const {
            data,
            large,
            titleLine,
            descriptionLine,
            interactive
        } = this.state;

        const {
            isEmpty,
            width
        } = this.props;

        const images = getPreviewImageIds(data.images);
        const distanceCreatedDate = distanceInWordsToNow(data.date);

        if(isEmpty) {
            return <EmptyVCard data={data} large={large} titleLine={titleLine} descriptionLine={descriptionLine} interactive={interactive} width={width} />;
        }

        return (
            <Card className={large ? 'v-card' : 'small-v-card'} interactive={interactive} elevation={Elevation.ZERO}>
                <div className="img">
                    <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                        <Box className="box">
                            { !large && <Preview images={images} width={THUMBNAIL_SIZES.BASIC.WIDTH} height={THUMBNAIL_SIZES.BASIC.HEIGHT} /> }
                            { large && <Preview images={images} width={THUMBNAIL_SIZES.MIDDLE.WIDTH} height={THUMBNAIL_SIZES.MIDDLE.HEIGHT} /> }
                        </Box>
                    </Link>
                </div>
                <div className="summary">
                    {(data.subTitle || data.subSubTitle) && (
                    <H6 className="name">
                        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                            <span className="sub-title">
                                <Dotdotdot clamp={titleLine}>{data.subTitle + ' | ' + data.subSubTitle}</Dotdotdot>
                            </span>
                        </Link>
                    </H6>
                    )}
                    <H5 className="title">
                        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                            <Dotdotdot clamp={titleLine}>{ data.title }</Dotdotdot>
                        </Link>
                    </H5>
                    <div className="description">
                        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                            <Dotdotdot clamp={descriptionLine}>{data.description}</Dotdotdot>
                        </Link>
                    </div>
                    {this.props.children}
                    <ICardTags tags={data.tags} />
                    <div className="like">
                        <Icon icon="heart" iconSize={Icon.SIZE_LARGE} />
                    </div>
                    <div className="author">by {data.author}</div>
                    <div className="date">{distanceCreatedDate}</div>
                </div>
            </Card>
        );
    }

    // private likeHandler = () => {
    //     console.log("lik!");
    // }
}

const EmptyVCard = ({data, large, interactive, width}: ICardProps) => {
    return (
        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
            <Card className={large ? 'v-card' : 'small-v-card'} interactive={interactive} elevation={Elevation.ZERO}>
                <NonIdealState
                    title={data.title}
                    description={data.description}
                />
            </Card>
        </Link>
    );
}

export default VCard;