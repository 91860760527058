import * as React from "react";
import { Route, Switch} from "react-router";

import AddItem from "../item/AddItem";
import Items from "../item/Items";
import Item from "../item/Item";
import Home from "../main/Home";

import ModifyItem from "../item/ModifyItem";
import Signup from "../auth/Signup";
import Login from "../auth/Login";
import OAuth2RedirectHandler from "../auth/OAuth2RedirectHandler";
import {WithAuth} from "../auth/WithAuth";
import IAm from "../user/IAm";
import Shops from "../shop/Shops";
import Shop from "../shop/Shop";
import AddShop from "../shop/AddShop";
import ModifyShop from "../shop/ModifyShop";
import ItemHome from "../item/ItemHome";
import ShopHome from "../shop/ShopHome";
import Follow from "../user/Follow";
import AboutUs from "../main/AboutUs";
import PrivacyPolicy from "../main/PrivacyPolicy";
import Boards from "../board/Boards";
import Board from "../board/Board";
import AddBoard from "../board/AddBoard";
import ModifyBoard from "../board/ModifyBoard";
import TemporaryItems from "../item/TemporaryItems";
import TemporaryItem from "../item/TemporaryItem";
import TemporaryModifyItem from "../item/TemporaryModifyItem";
import TemporaryShop from "../shop/TemporaryShop";
import TemporaryShops from "../shop/TemporaryShops";
import TemporaryModifyShop from "../shop/TemporaryModifyShop";
import TemporaryBoards from "../board/TemporaryBoards";
import TemporaryBoard from "../board/TemporaryBoard";
import TemporaryModifyBoard from "../board/TemporaryModifyBoard";
import OAuth2MobileRedirectHandler from "../auth/OAuth2MobileRedirectHandler";
import StoriesHome from "../stories/StoriesHome";
import SpecialHome from "../special/SpecialHome";

import "./Content.css";

class Content extends React.Component {
    public render() {
        return (
            <div className="content-wrapper">
                <Switch>
                    <Route exact={true} path="/" component={Home} />

                    <Route exact={true} path="/aboutus" component={AboutUs} />
                    <Route exact={true} path="/privacyPolicy" component={PrivacyPolicy} />

                    <Route exact={true} path="/special/home" component={SpecialHome} />
                    <Route exact={true} path="/stories/home" component={StoriesHome} />

                    <Route exact={true} path="/temporaryBoard/:category" component={TemporaryBoards} />
                    <Route exact={true} path="/temporaryBoard/view/:category/:boardId" component={TemporaryBoard} />
                    <Route exact={true} path="/temporaryBoard/update/:category/:boardId" component={WithAuth(TemporaryModifyBoard)} />

                    <Route exact={true} path="/board/:category" component={Boards} />
                    <Route exact={true} path="/board/view/:category/:boardId" component={Board} />
                    <Route exact={true} path="/board/add/:category" component={WithAuth(AddBoard)} />
                    <Route exact={true} path="/board/update/:category/:boardId" component={WithAuth(ModifyBoard)} />

                    <Route exact={true} path="/temporaryItems" component={TemporaryItems} />
                    <Route exact={true} path="/temporaryItem/:itemId" component={TemporaryItem} />
                    <Route exact={true} path="/temporaryItem/update/:itemId" component={WithAuth(TemporaryModifyItem)} />

                    <Route exact={true} path="/item/home" component={ItemHome} />
                    {/*<Route exact={true} path="/items" component={Items} />*/}
                    <Route exact={true} path="/items/:largeCategory?/:middleCategory?/:smallCategory?" component={Items} />
                    <Route exact={true} path="/item/:itemId" component={Item} />
                    <Route exact={true} path="/item/add" component={WithAuth(AddItem)} />
                    <Route exact={true} path="/item/add/:groupId" component={WithAuth(AddItem)} />
                    <Route exact={true} path="/item/update/:itemId" component={WithAuth(ModifyItem)} />
                    <Route exact={true} path="/newItem" component={WithAuth(AddItem)} />

                    <Route exact={true} path="/temporaryShops" component={TemporaryShops} />
                    <Route exact={true} path="/temporaryShop/:shopId" component={TemporaryShop} />
                    <Route exact={true} path="/temporaryShop/update/:shopId" component={WithAuth(TemporaryModifyShop)} />

                    <Route exact={true} path="/shop/home" component={ShopHome} />
                    <Route exact={true} path="/shops" component={Shops} />
                    <Route exact={true} path="/shops/:category" component={Shops} />
                    <Route exact={true} path="/shop/:shopId" component={Shop} />
                    <Route exact={true} path="/shop/add" component={WithAuth(AddShop)} />
                    <Route exact={true} path="/shop/add/:groupId" component={WithAuth(AddShop)} />
                    <Route exact={true} path="/shop/update/:shopId" component={WithAuth(ModifyShop)} />
                    <Route exact={true} path="/newShop" component={WithAuth(AddShop)} />

                    <Route exact={true} path="/login" component={Login} />
                    <Route exact={true} path="/signup" component={Signup} />
                    <Route exact={true} path="/iam" component={IAm} />
                    <Route exact={true} path="/follow/:userNo" component={WithAuth(Follow)} />
                    <Route exact={true} path="/oauth2/redirect" component={OAuth2RedirectHandler} />
                    <Route exact={true} path="/oauth2/mobileRedirect" component={OAuth2MobileRedirectHandler} />
                </Switch>
            </div>
        );
    }
}

export default Content;