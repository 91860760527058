 import * as React from "react";
import { Tab, TabId, Tabs } from "@blueprintjs/core";
import {Profile, ProfileType} from "./Profile";
import {Query} from "react-apollo";
import {GET_CURRENT_USER} from "./view/MyProfile";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";

import "./IAm.css";

interface IProps {
    dummy?: string
}

interface IState {
    selectedSubTabId: TabId
}

class IAm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            selectedSubTabId: "profile"
        }
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public render() {
        return (
            <Query
                query = {GET_CURRENT_USER}
                fetchPolicy="network-only"
            >
                {({loading, error, data}: any) => {
                    if(loading) {
                        return <LoadingPage />;
                    }

                    if(error) {
                        return <ErrorPage />;
                    }

                    if(!data || !data.currentUser) {
                        return <div>not login</div>;
                    }

                    const { currentUser } = data || { currentUser: null };

                    if(!currentUser) {
                        return <div>Invalid user</div>;
                    }

                    return (
                        <div>
                            <div className="grid-container pad-t-3">
                                <div className="default-grid-container">
                                    <Profile user={currentUser} profileType={ProfileType.DEFAULT} large={true} />
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container pad-t-2">
                                    <Tabs id="user-info-tabs" className={"bp3-focus-disabled"} large={true} animate={true} renderActiveTabPanelOnly={true} selectedTabId={this.state.selectedSubTabId} onChange={this.setSelectedSubTabId}>
                                        <Tab id="profile" title="Profile" panel={<Profile user={currentUser} profileType={ProfileType.EDIT} large={false} />} />
                                    </Tabs>
                                </div>
                            </div>
                        </div>

                    );
                }}
            </Query>
        );
    }

    private setSelectedSubTabId = (selectedSubTabId: TabId) => {
        this.setState({
            selectedSubTabId
        });
    }
}

export default IAm;