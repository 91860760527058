export const SITE_TITLE ="JIYUNSHOP, 지윤샵";

export const LOAD_DATA_COUNT = 12;
export const LOAD_EMPTY_DATA_COUNT = 4;
export const CARD_WIDTH = 23;

// FORMATS
export const HIT_COUNTER_FORMAT = "0a";
export const THOUSANDS_COMMA_FORMAT = "0,0";

// REGEXP
export const TIME_PATTERN = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/i;
export const DATETIME_PATTERN = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/i;
export const EMAIL_PATTERN = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const TELEPHONE_PATTERN =  /^[0-9]{3}[-]+[0-9]{4}[-]+[0-9]{4}$/i;

// UPLOAD
export const IMAGE_EXTENSIONS = [ "jpg", "jpeg", "png", "gif"];

export const ITEM_LOCATION: string[] = [
    "없음",
    "창가",
    "베란다",
    "선반",
    "바닥",
    "테이블"
];

export const WATER_PERIOD: string[] = [
    "없음",
    "1일-2일",
    "3-4일",
    "1주",
    "2주",
    "3주",
    "4주",
    "1개월"
];

export const WIND_METHOD: string[] = [
    "없음",
    "1일-2일",
    "3-4일",
    "1주",
    "2주",
    "3주",
    "4주",
    "1개월"
];

export const WIND_DISTANCE: string[] = [
    "없음",
    "1m",
    "2m",
    "3m",
    "10m~"
];

export const EDIT_DEFAULT_BOARD_CONTENT: string =
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />"
;

export const EDIT_DEFAULT_ITEM_CONTENT: string =
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />"
;

export const EDIT_DEFAULT_SHOP_CONTENT: string =
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />" +
    "<br />"
;

// BRAND
export const UNKNOWN_BRAND = 1;

// IMAGE
export const MAX_UPLOAD_IMAGE_SIZE = 20 * 1024 * 1024; // 20MB
export const THUMBNAIL_SIZES = {
    BASIC: {
        WIDTH: 500,
        HEIGHT: 500
    },
    MIDDLE: {
        WIDTH: 1000,
        HEIGHT: 1000
    },
    LARGE: {
        WIDTH: 2000,
        HEIGHT: 2000
    }
};

export const AUTO_SAVE_SHOP_SEC = 10 * 1000;

// DEV_HOSTS
// export const HOST_BASE_URL = 'http://localhost:3000';
// export const API_BASE_URL = 'http://localhost:8081';
// export const AUTH_BASE_URL = 'http://localhost:8081';
// export const IMAGE_BASE_PATH = "http://localhost:8081";

// PROD_HOSTS
export const HOST_BASE_URL = 'https://www.jiyunshop.co.kr';
export const API_BASE_URL = 'https://www.jiyunshop.co.kr/api';
export const AUTH_BASE_URL = 'https://www.jiyunshop.co.kr:8543/jiyunshop';
export const IMAGE_BASE_PATH = "https://www.jiyunshop.co.kr/share/image";

export const ACCESS_TOKEN = 'accessToken';

export const OAUTH2_REDIRECT_URI = HOST_BASE_URL + '/oauth2/redirect';

export const GOOGLE_AUTH_URL = AUTH_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = AUTH_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;

