import * as React from "react";
import "../IAm.css";
import {Query} from "react-apollo";
import {Profile, ProfileType} from "../Profile";
import {H5} from "@blueprintjs/core";
import {gql} from "apollo-boost";
import {ErrorPage, LoadingPage} from "../../utils/LoadingAndError";

export const GET_CURRENT_USER = gql `
    query currentUser {
        currentUser {
            no
            id
            name
            displayName
            description
            email
            emailVerified
            imageUrl
            provider
            providerId
            registeredDate
            userAuthorities {
                authorityName
            }
        }
    }
`;

interface IProps {
    isView: boolean
}

interface IState {
    dummy?: string
}

class MyProfile extends React.Component<IProps, IState> {
    public render() {
        const {isView} = this.props;

        return (
            <Query
                query = {GET_CURRENT_USER}
                fetchPolicy="network-only"
            >
                {({loading, error, data}: any) => {
                    if(loading) {
                        return <LoadingPage isOnlyMessage={true} />;
                    }

                    if(error) {
                        return <ErrorPage isOnlyMessage={true} />;
                    }

                    if(!data || !data.currentUser) {
                        return <div>not login</div>
                    }

                    const { currentUser } = data || { currentUser: null };

                    return (
                        <div>
                            <div className="grid-container">
                                <div className="default-grid-container">
                                    <div className="header-wrapper-2">
                                        <H5 className="title">{currentUser.displayName}</H5>
                                    </div>
                                </div>
                            </div>
                            <Profile user={currentUser} large={false} profileType={isView ? ProfileType.DEFAULT : ProfileType.EDIT} />
                        </div>
                    );
                }}
            </Query>
        );
    }
}

export default MyProfile;