import * as React from "react";
import {gql} from "apollo-boost";
import {
    IItem,
    IItemImage,
    // ITEM_ENV_METAS,
    RecommendType, IItemRecommend
} from "../../common/Domains";
import {Query} from "react-apollo";
import HBox from "../../card/HBox";
import {CardAlign, ICardData, ICardTag} from "../../card/CardDomains";
import {ErrorPage} from "../../utils/LoadingAndError";
import {PriceWrapper} from "./RecommendCategory";

export const RECOMMEND_ITEM_QUERY = gql `
    query RecommendItems (
        $recommendType: RecommendType,
        $categoryInfo: CategoryInfo,
        $offset: Int,
        $limit: Int
    ) {
        recommendItems(recommendType: $recommendType, categoryInfo: $categoryInfo, offset: $offset, limit: $limit) {
            recommendId
            item {
                itemId
                groupId
                category {
                    categoryId
                }
                author {
                    no
                    imageUrl
                    displayName
                    description
                }
                authorIp
                createdDate
                modifiedDate
                brand {
                    brandId
                    korName
                    engName
                    desc
                }
                itemItemId
                title
                description
                content
                quantity
                price
                discountPrice
                discount
                discountUnit
                status
                isDelete
                itemEnv {
                    envId
                    model
                    origin
                    manufacturer
                    material
                    size
                    composition
                    url
                }
                itemImages {
                    itemImageId
                    image {
                        imageId
                        realName
                    }
                    isDefault
                    createdDate
                }
                counter {
                    counterId
                    count
                }
            }
        }
    }
`;

class Recommend extends React.Component {

    public render() {

        return (
            <Query
                query={RECOMMEND_ITEM_QUERY}
                variables={{
                    recommendType: RecommendType.PICK,
                    categoryInfo: {},
                    offset: 0,
                    limit: 1
                }}

                fetchPolicy="cache-and-network"
            >
                {({loading, error, data, fetchMore}: any) => {
                    if(error) {
                        return <ErrorPage isOnlyMessage={true} />;
                    }

                    const { recommendItems } = data || { recommendItems: [] };

                    return (
                        <>
                            {
                                (recommendItems || []).map((itemRecommend: IItemRecommend) => {
                                    const item = itemRecommend.item as IItem;

                                    const images = item.itemImages.filter((itemImage: IItemImage) => {
                                        return itemImage.isDefault;
                                    })
                                    .map((itemImage: IItemImage) => {
                                        return itemImage.image;
                                    });

                                    const boxData = {
                                        id: item.itemId + "",
                                        link: {
                                            path: "/item/" + item.itemId,
                                            localParams: {}
                                        },
                                        images,
                                        date: item.createdDate,
                                        title: item.title,
                                        subTitle: item.brand.korName,
                                        subSubTitle: item.brand.desc,
                                        description: item.description,
                                        author: item.author.displayName,
                                        price: {
                                            price: item.price,
                                            discountPrice: item.discountPrice,
                                            discount: item.discount,
                                            discountUnit: item.discountUnit
                                        },
                                        tags: [] as ICardTag[] // getTags(ITEM_ENV_METAS, item.itemEnv)
                                    } as ICardData;


                                    return (
                                        <HBox key={boxData.id} data={boxData} large={true} titleLine={3} descriptionLine={5} align={CardAlign.LEFT}>
                                            <PriceWrapper price={data.price} />
                                        </HBox>
                                    );
                                })
                            }
                            <div style={{clear: "left"}} />
                        </>
                    );
                }}
            </Query>
        );
    };
}

export default Recommend;