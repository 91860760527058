import {useMutation} from "react-apollo-hooks";
import {IUserReport, USER_REPORT_TYPE, UserReportTargetType, UserReportType} from "../common/Domains";
import * as React from "react";
import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    InputGroup,
    Alert,
    PopoverInteractionKind,
    Popover,
    H5,
    Intent,
    RadioGroup, Radio, TextArea
} from "@blueprintjs/core";
import {gql} from "apollo-boost";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

import "./UserReport.css";

const ADD_REPORT_QUERY = gql `
    mutation AddBrand($userReport: InputUserReport) {
        addUserReport(userReport: $userReport) {
            reportId
            reportType
            targetType
            targetCategoryId
            title
            content
            result
            author {
                no
            }
            authorIp
            createdDate
            modifiedDate
        }
    }
`;

interface IUserReportProps {
    targetType: UserReportTargetType
    targetCategoryId: number
    targetId: number
}

export const UserReport = (props: IUserReportProps) => {
    const [ isDialogOpen, setIsDialogOpen ] = useState(false);
    const [ isAddSuccessAlert, setIsAddSuccessAlert] = useState(false);
    const [ reportType, setReportType] = useState(UserReportType.ADVERTISEMENT.toString());
    const [ content, setContent ] = useState("");
    const [ addUserReport ] = useMutation(ADD_REPORT_QUERY);

    const { register, errors, getValues, setValue, trigger } = useForm();

    const submitHandler = () => {
        trigger().then((isValid) => {
            if (isValid) {
                const formData = getValues();

                const {
                    title
                } = formData as IUserReport;

                addUserReport({
                    variables: {
                        userReport: {
                            reportType,
                            targetType: props.targetType,
                            targetCategoryId: props.targetCategoryId,
                            targetId: props.targetId,
                            title,
                            content
                        }
                    }
                });

                setIsAddSuccessAlert(true);
            }
        });
    };

    useEffect(() => {
        register({ name: "reportType"});
        register({ name: "content"});
    });

    const openHandler = () => {
        setIsDialogOpen(!isDialogOpen);
    };

    const closeHandler = () =>  {
        setIsDialogOpen(!isDialogOpen);
    };

    return (
        <>
            <span className="report" onClick={openHandler}>Report</span>
            <Dialog
                onClose={closeHandler}
                title="Report"
                isOpen={isDialogOpen}
            >
                <div className={Classes.DIALOG_BODY}>
                    <div className="user-report-container pad-t-1">
                        <form>
                            <RadioGroup name="reportType"
                                        className="user-input-wrapper"
                                        label="신고 유형을 선택해주세요."
                                        inline={true}
                                        onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                            setReportType(event.currentTarget.value);

                                            setValue("reportType", event.currentTarget.value);
                                        }}
                                        selectedValue={reportType}
                            >
                                {USER_REPORT_TYPE.map((tempReportType) => {
                                    return <Radio key={tempReportType.reportTypeId} label={tempReportType.name}
                                                  value={tempReportType.reportTypeId} inputRef={register} />
                                })}
                            </RadioGroup>
                            <FormGroup
                                className="user-input-wrapper"
                                labelFor="title"
                                label="제목을 입력해주세요."
                            >
                                <InputGroup name="title"
                                            className="input-title"
                                            placeholder="제목을 입력해주세요."
                                            inputRef={register({ required: true, minLength: 1, maxLength: 100 })}
                                            defaultValue=""
                                />
                                <div className="valid-error-wrapper">
                                    {errors.title && errors.title.type === "required" && (
                                        <p className="valid-error">제목을 입력해주세요.</p>
                                    )}
                                    {errors.title && errors.title.type === "minLength" && (
                                        <p className="valid-error">제목을 1자 이상 입력해주세요.</p>
                                    )}
                                    {errors.title && errors.title.type === "maxLength" && (
                                        <p className="valid-error">제목을 100자 이하 입력해주세요.</p>
                                    )}
                                </div>
                            </FormGroup>
                            <FormGroup
                                className="user-input-wrapper"
                                labelFor="content"
                                label="내용을 입력해주세요."
                            >
                                <TextArea name="content"
                                          className="input-content"
                                          fill={true}
                                          large={false}
                                          growVertically={true}
                                          value={content}
                                          onChange={(event: React.FormEvent<HTMLTextAreaElement>) => {
                                              setContent(event.currentTarget.value);

                                              setValue("content", event.currentTarget.value);
                                          }}
                                          inputRef={register({ required: true, minLength: 1, maxLength: 30 })}
                                />
                                <div className="valid-error-wrapper">
                                    {errors.content && errors.content.type === "required" && (
                                        <p className="valid-error">내용을 입력해주세요.</p>
                                    )}
                                    {errors.content && errors.content.type === "minLength" && (
                                        <p className="valid-error">내용을 1자 이상 입력해주세요.</p>
                                    )}
                                    {errors.content && errors.content.type === "maxLength" && (
                                        <p className="valid-error">내용을 30자 이하 입력해주세요.</p>
                                    )}
                                </div>
                            </FormGroup>
                            <Popover
                                interactionKind={PopoverInteractionKind.CLICK}
                                popoverClassName="bp3-popover-content-sizing"
                            >
                                <Button minimal={true}>
                                    Report
                                </Button>
                                <div>
                                    <H5>신고하기</H5>
                                    <p>신고하시겠습니까?</p>
                                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
                                        <Button className="bp3-popover-dismiss" style={{ marginRight: 10 }}>
                                            Cancel
                                        </Button>
                                        <Button intent={Intent.PRIMARY} className="bp3-popover-dismiss" onClick={submitHandler}>
                                            Report
                                        </Button>
                                    </div>
                                </div>
                            </Popover>
                        </form>
                        <Alert
                            confirmButtonText="Success"
                            isOpen={isAddSuccessAlert}
                            onClose={() => { setIsAddSuccessAlert(false)}}
                        >
                            <p>
                                등록 요청되었습니다. 확인 후 등록 완료됩니다.
                            </p>
                        </Alert>
                    </div>
                </div>
            </Dialog>
        </>
    );
};