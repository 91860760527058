import * as React from "react";

import "./Summary.css";

interface IProps {
    content: string
}

class Summary extends React.Component<IProps, {}> {
    public render() {
        const { content, children } = this.props;

        return (
            <div className="summary">
                <div className="ql-editor" dangerouslySetInnerHTML={{__html: content}} />
                { children }
            </div>
        );
    }
}

export default Summary;