import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import * as ApolloStore from './store/ApolloStore';

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import "./common/css/common.css";
import "./common/css/default.css";
import "./index.css";

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {ApolloProvider} from "react-apollo";
import {ApolloProvider as ApolloProviderHooks} from "react-apollo-hooks";

ReactDOM.render(
    <BrowserRouter>
    <ApolloProvider client={ApolloStore.Client}>
        <ApolloProviderHooks client={ApolloStore.Client}>
            <App />
        </ApolloProviderHooks>
    </ApolloProvider>
    </BrowserRouter>,
  document.getElementById('root') as HTMLElement
);
registerServiceWorker();