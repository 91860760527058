import * as React from "react";
import {gql} from "apollo-boost";
import {
    cloneCategoryInfo,
    getTags, ICategory,
    ICategoryInfo,
    IItem,
    IItemImage,
    SHOP_ENV_METAS,
} from "../common/Domains";
import {Button, H5} from "@blueprintjs/core";
import {Link, RouteComponentProps} from "react-router-dom";
import {ICardData} from "../card/CardDomains";
import {SearchWrapper} from "../search/SearchWrapper";
import {useQuery} from "react-apollo-hooks";
import * as InfiniteScroll from 'react-infinite-scroller';
import VBox, {LoadVBox} from "../card/VBox";
import {CARD_WIDTH, LOAD_DATA_COUNT, LOAD_EMPTY_DATA_COUNT, THOUSANDS_COMMA_FORMAT} from "../common/Constant";

import "./Items.css";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";
import numeral from "numeral";

export const CATEGORY_QUERY = gql `
    query Category (
        $categoryKey: String
    ) {
        category(
            categoryKey: $categoryKey
        ) {
            categoryId
            parent {
                categoryId
                key
                name
                desc
                subCategories {
                    categoryId
                    key
                    name
                    desc
                }
            }
            key
            name
            desc
            subCategories {
                categoryId
                key
                name
                desc
            }
        }
    }
`;

export const ITEMS_QUERY = gql `
    query Items (
        $keyword: String,
        $categoryInfo: CategoryInfo,
        $userNo: Int,
        $offset: Int,
        $limit: Int
    ) {
        items(
            keyword: $keyword, categoryInfo: $categoryInfo, userNo: $userNo, offset: $offset, limit: $limit
        ) {
            itemId
            category {
                categoryId
                key
            }
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            brand {
                brandId
                korName
                engName
                desc
            }
            title
            description
            content
            quantity
            price
            discountPrice
            discount
            discountUnit
            status
            isDelete
            counter {
                counterId
                count
            }
            itemEnv {
                envId
                model
                origin
                manufacturer
                material
                size
                composition
                url
            }
            itemImages {
                itemImageId
                image {
                    imageId
                    realName
                }
                isDefault
                createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

interface IProps extends RouteComponentProps {
    keyword?: string
    userNo?: number
    largeCategory?: string
    middleCategory?: string
    smallCategory?: string
}

interface IStatus {
    keyword: string
    userNo?: number
    categoryInfo: ICategoryInfo
}

class Items extends React.Component<IProps, IStatus> {
    constructor(props: IProps) {
        super(props);

        const { keyword, userNo, largeCategory, middleCategory, smallCategory } = this.props.match.params as IProps;

        const categoryInfo = { largeCategory, middleCategory, smallCategory} as ICategoryInfo;

        this.state = {
            keyword: keyword || "",
            userNo: userNo || -1,
            categoryInfo
        }
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStatus>, snapshot?: any): void {
        const prevData = prevProps.match.params as IProps;
        const currData = this.props.match.params as IProps;

        if(
            prevData.largeCategory !== currData.largeCategory
            || prevData.middleCategory !== currData.middleCategory
            || prevData.smallCategory !== currData.smallCategory) {


            this.setState({
                categoryInfo : cloneCategoryInfo(currData) as ICategoryInfo
            });
        }
    }

    public render() {
        return (
            <div className="items-container">
                <HeaderWrapper categoryInfo={this.state.categoryInfo} />
                <div className="grid-container pad-t-2">
                    <div className="default-grid-container search-wrapper center">
                        <SearchWrapper searchHandler={this.changeSearch} />
                    </div>
                </div>
                <div className="grid-container pad-t-2">
                    <div className="default-grid-container">
                        <div className="items-wrapper pad-t-1">
                            <ItemsItems keyword={this.state.keyword} categoryInfo={this.state.categoryInfo} userNo={this.state.userNo} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private changeSearch = (keyword: string) => {
        this.setState({
            keyword
        });
    }
}

export default Items;

export interface ICategoryProps {
    categoryInfo: ICategoryInfo
}

const HeaderWrapper = (props: ICategoryProps) => {
    const { categoryInfo } = props;

    const { error, data } = useQuery(CATEGORY_QUERY, {
        variables: {
            categoryKey: categoryInfo.largeCategory
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: false
    });

    if(error) {
        return <ErrorPage />;
    }

    const { category } = data || { category: null };

    if(!category || !category.categoryId) {
        return <LoadingPage isOnlyMessage={true} />
    }

    if((category.categoryId % 10000) === 0) {
        const categoryName = category ? category.name : "";
        const parentCategory = category;
        const subCategories = category && category.subCategories ? category.subCategories : [];

        return (
            <>
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="header-wrapper-1 pad-t-3">
                            <H5 className="title capitalize">{categoryName}</H5>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="sub-menu-wrapper">
                            <ul>
                                <li key={parentCategory.key}><Link to={"/items/" + parentCategory.key}>All</Link></li>
                                {
                                    subCategories.map((subCategory: ICategory) => {
                                        return <li key={subCategory.key}><Link to={"/items/" + parentCategory.key + "/" + subCategory.key}>{subCategory.name}</Link></li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    else {
        const categoryName = category && category.parent ? category.parent.name : "";
        const parentCategory = category.parent;
        const subCategories = category && category.parent && category.parent.subCategories ? category.parent.subCategories : [];

        return (
            <>
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="header-wrapper-1 pad-t-3">
                            <H5 className="title capitalize">{categoryName}</H5>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="sub-menu-wrapper">
                            <ul>
                                <li key={parentCategory.key}><Link to={"/items/" + parentCategory.key}>All</Link></li>
                                {
                                    subCategories.map((subCategory: ICategory) => {
                                        return <li key={subCategory.key}><Link to={"/items/" + parentCategory.key + "/" + subCategory.key}>{subCategory.name}</Link></li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

interface IItemItems {
    keyword: string
    categoryInfo: ICategoryInfo
    userNo?: number
}

export const ItemsItems = (props: IItemItems) => {
    const { keyword, categoryInfo, userNo } = props;

    const { loading, error, data, fetchMore, networkStatus } = useQuery(ITEMS_QUERY, {
        variables: {
            keyword,
            categoryInfo,
            userNo,
            offset: 0,
            limit: LOAD_DATA_COUNT
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: false
    });

    if(loading || networkStatus === 4) {
        return <LoadingPage isOnlyMessage={true} />;
    }

    if(error) {
        return <ErrorPage />;
    }

    const { items } = data || { items: null };

    if(!items || items.length === 0) {
        const emptyData = {
            id: "0",
            link: {
                path: ""
            },
            title: "No results"
        } as ICardData;

        return (
            <>
                <VBox key={emptyData.id} data={emptyData} large={true} width={CARD_WIDTH} isEmpty={true} />
                <div style={{clear: "left"}} />
            </>
        );
    }

    const offset = items ? items.length : 0;
    const hasMore = (offset !== 0 && offset % LOAD_DATA_COUNT === 0) ? true : false;

    const loadMoreHandler = () => {
        fetchMore({
            variables: {
                offset
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                if(!fetchMoreResult) {
                    return prev;
                }

                return Object.assign({}, prev, {
                    items: [ ...prev.items, ...fetchMoreResult.items ]
                });
            }
        });
    };

    return (
        <>
            <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={loadMoreHandler}
                hasMore={hasMore}
                loader={<LoadingBox key="0" />}>
                {
                    (items || []).map((item: IItem) => {
                        const images = item.itemImages.filter((itemImage: IItemImage) => {
                            return itemImage.isDefault;
                        })
                            .map((itemImage: IItemImage) => {
                                return itemImage.image;
                            });

                        const boxData = {
                            id: item.itemId + "",
                            link: {
                                path: "/item/" + item.itemId,
                                localParams: {
                                    keyword,
                                    categoryInfo,
                                    userNo,
                                    offset: 0,
                                    limit: LOAD_DATA_COUNT
                                }
                            },
                            images,
                            date: item.createdDate,
                            title: item.title,
                            subTitle: item.brand.korName,
                            subSubTitle: item.brand.desc,
                            description: item.description,
                            author: item.author.displayName,
                            tags: getTags(SHOP_ENV_METAS, item.itemEnv)
                        } as ICardData;

                        return (
                            <VBox key={boxData.id} data={boxData} large={true} width={CARD_WIDTH}>
                            <div className="price-info-wrapper pad-t-1">
                                <span className="price pad-h-1">₩{numeral(item.price).format(THOUSANDS_COMMA_FORMAT)}</span>&nbsp;<span className="discount-price">₩{numeral(item.discountPrice).format(THOUSANDS_COMMA_FORMAT)}</span> <span className="discount">({item.discount}</span><span className="discount-unit">{item.discountUnit})</span>
                            </div>
                        </VBox>
                        );
                    })
                }
                <div style={{clear: "left"}} />
            </InfiniteScroll>
            <LoadMore loadMoreHandler={loadMoreHandler} />
        </>
    );
};

const LoadMore = ({ loadMoreHandler }: any) => {
    return (
        <div className="group-center pad-h-3">
            <Button minimal={true} icon="plus" onClick={loadMoreHandler} >Load more</Button>
        </div>
    );
};

export const LoadingBox = () => {
    return (
        <>
            {[...Array(LOAD_EMPTY_DATA_COUNT)].fill(0).map((_: any, index: number) => {
                const testData = {
                    id: index + "",
                    link: {
                        path: ""
                    },
                    title: "No results"
                } as ICardData;

                return <LoadVBox key={testData.id} data={testData} width={CARD_WIDTH} />;
            })}
            <div style={{clear: "left"}} />
        </>
    );
};