import * as React from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {Button, H3, H5, Tab, TabId, Tabs} from "@blueprintjs/core";
import {distanceInWordsToNow} from "date-fns";
import {Helmet} from 'react-helmet';
import "./Item.css";
import Summary from "./view/sub/summary/Summary";
import Comments from "./view/sub/comment/Comments";
import Environment from "./Environment";
import {gql} from "apollo-boost";
import {Query} from "react-apollo";
import {Profile, ProfileType} from "../user/Profile";
import {getUserInfo, isBlockedUser} from "../common/UserInfo";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";
import {DeletePopover} from "./Item";
import {SITE_TITLE} from "../common/Constant";

export const TEMPORARY_ITEM_QUERY = gql `
    query Item($itemId: Int, $userNo: Int) {
        temporaryItem(itemId: $itemId, userNo: $userNo) {
            itemId
            groupId
            category {
                categoryId
            }
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            brand {
                brandId
                korName
                engName
                desc
            }
            title
            description
            content
            status
            isDelete
            itemEnv {
                envId
                isLightSun
                isLightBulb
                itemPos
                waterPeriod
                isWaterShower
                isWaterBottomWatering
                temperature
                humidity
                isWind
                isWindDirect
                windMethod
                windDistance
                itemHeight
                itemWidth
                potHeight
                potWidth
            }
            itemImages {
                itemImageId
               image {
                   imageId
                   realName
               }
               isDefault
               createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

interface IItemProps extends RouteComponentProps {
    itemId: number
}

interface IItemState {
    selectedSubTabId: TabId
}

class Item extends React.Component<IItemProps, IItemState> {
    constructor(props: IItemProps) {
        super(props);

        this.state = {
            selectedSubTabId: "comment"
        };

        this.goBackHandler = this.goBackHandler.bind(this);
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public render() {
        const currentUser = getUserInfo();
        const { itemId } = this.props.match.params as IItemProps;

        if(!itemId) {
            return <div>error...</div>;
        }

        const newItemId = Number(itemId);

        return (
            <Query
                query={TEMPORARY_ITEM_QUERY}
                variables={{ itemId: newItemId, userNo: currentUser.no }}
                notifyOnNetworkStatusChange={true}
                fetchPolicy="cache-and-network"
            >
                {({loading, error, data}: any) => {
                    if(loading) {
                        return <LoadingPage />;
                    }

                    if(error) {
                        return <ErrorPage />;
                    }

                    const { temporaryItem } = data || { temporaryItem: null };

                    if(!temporaryItem || temporaryItem.itemId <= 0) {
                        return <ErrorPage />;
                    }

                    const imagePath = temporaryItem.author.imageUrl;
                    const distanceCreatedDate = distanceInWordsToNow(temporaryItem.createdDate);
                    const distanceModifiedDate = distanceInWordsToNow(temporaryItem.modifiedDate);

                    return (
                        <div className="item-wrapper">
                            <Helmet>
                                <title>{temporaryItem.title + ", " + SITE_TITLE }</title>
                                <link rel="canonical" href={ window.location.href } />
                                <meta name="description" content={temporaryItem.title} />
                                <meta property="og:url" content={ window.location.href } />
                                <meta property="og:type" content="website" />
                                <meta property="og:title" content={temporaryItem.title + ", " + SITE_TITLE } />
                                <meta property="og:description" content={temporaryItem.description} />
                                <meta property="og:image" content={imagePath} />
                            </Helmet>
                            <div className="grid-container pad-t-3">
                                <div className="default-grid-container">
                                    <div className="header pad-h-1">
                                        <div className="name-wrapper">
                                            <H5 className="name">
                                                <span className="korName">{temporaryItem.brand.korName}</span> / <span
                                                className="desc">{temporaryItem.brand.desc}</span>
                                            </H5>
                                        </div>
                                        <H3 className="title">
                                            {temporaryItem.title}
                                        </H3>
                                        <div className="description">
                                            {temporaryItem.description}
                                        </div>
                                        <div className="author-item-container pad-h-1">
                                            <Profile user={temporaryItem.author}
                                                     profileType={ProfileType.SIMPLE}
                                                     large={false} isForceUserDetail={true} disableDescription={true}
                                            >
                                                    <span className="date" title="Created date">{distanceCreatedDate}</span>
                                                    {(distanceCreatedDate !== distanceModifiedDate) && (
                                                        <>
                                                        ,&nbsp;
                                                        <span className="date" title="Modified date">{distanceModifiedDate}</span>
                                                        </>
                                                    )}
                                            </Profile>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container">
                                    <div className="pad-h-1">
                                        <Environment env={temporaryItem.itemEnv}/>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container" >
                                    <div className="pad-h-1">
                                        <Summary content={temporaryItem.content}>
                                            <UserActions groupId={temporaryItem.groupId} itemId={temporaryItem.itemId} userNo={temporaryItem.author.no} />
                                        </Summary>

                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container border-top">
                                    <div className="pad-h-1">
                                        <Profile user={temporaryItem.author} profileType={ProfileType.DEFAULT} large={false} isForceUserDetail={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container">
                                    <div className="pad-h-1">
                                        <Tabs id="sub-info" large={true} animate={true} selectedTabId={this.state.selectedSubTabId}
                                              onChange={this.subInfoChanger}>
                                            <Tab id="comment" title={"Review"} panel={<Comments itemId={temporaryItem.itemId} />} />
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    }

    public subInfoChanger = (selectedSubTabId: TabId) => {
       this.setState({
           selectedSubTabId
       });
    };

    private goBackHandler = () => {
        this.props.history.back();
    };
}

export default withRouter(Item);

interface IUserActions {
    groupId: number
    itemId: number
    userNo: number
}

const UserActions = (props: IUserActions) => {
    const currentUser = getUserInfo();

    const {itemId, userNo} = props;

    const isEnableAction = currentUser.no === userNo;

    if(isBlockedUser()) {
        return (
            <div>
                <div className="group-center pad-h-3">
                    { isEnableAction &&
                    <>
                        <Button minimal={true} disabled={true}>List</Button>
                        <Button minimal={true} disabled={true}>Modify</Button>
                        <Button minimal={true} disabled={true}>Delete</Button>
                    </>
                    }
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="group-center pad-h-3">
            { isEnableAction &&
            <>
                <Link to={"/temporaryItems"}>
                    <Button minimal={true}>List</Button>
                </Link>
                <Link to={"/temporaryItem/update/" + itemId}>
                    <Button minimal={true}>Modify</Button>
                </Link>
                <DeletePopover itemId={itemId} userNo={userNo} />
            </>
            }
            </div>
        </div>
    );
};