import * as React from "react";
import {H5, H6, Icon, NonIdealState} from "@blueprintjs/core";
import {distanceInWordsToNow} from "date-fns";
import {Link} from "react-router-dom";
import Dotdotdot from "react-dotdotdot";
import {CardAlign, getPreviewImageIds, ICardData, ICardTags, Preview} from "./CardDomains";
import {Box} from "grommet";

import './CoverHBox.css';
import './SmallHBox.css';
import './HBox.css';
import {THUMBNAIL_SIZES} from "../common/Constant";

interface ICardProps {
    data: ICardData
    large?: boolean
    cover?: boolean
    titleLine?: number
    descriptionLine?: number
    width?: number
    height?: number
    align: CardAlign
    isEmpty?: boolean
}

interface ICardState {
    data: ICardData
    large: boolean
    cover: boolean
    titleLine: number
    descriptionLine: number
}

class HBox extends React.Component<ICardProps, ICardState> {
    constructor(props: ICardProps) {
        super(props);

        this.state = {
            data: props.data || {},
            large: props.large || false,
            cover: props.cover || false,
            titleLine: props.titleLine || 1,
            descriptionLine: props.descriptionLine || 1
        }
    }

    public render() {
        const {
            data,
            large,
            cover,
            titleLine,
            descriptionLine
        } = this.state;

        const {
            isEmpty,
            width,
            align
        } = this.props;

        if(isEmpty) {
            return <EmptyHBox data={data} large={large} titleLine={titleLine} descriptionLine={descriptionLine} align={align} width={width} />;
        }

        const images = getPreviewImageIds(data.images);
        const distanceCreatedDate = distanceInWordsToNow(data.date);

        return (
            <div className={cover ? "cover-box" : large ? 'h-box' : 'small-h-box'}>
                <div className={"img " + " img-" + align}>
                    <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                        <Box className="box">
                            {!large && <Preview images={images} width={THUMBNAIL_SIZES.BASIC.WIDTH} height={THUMBNAIL_SIZES.BASIC.HEIGHT} /> }
                            {large && <Preview images={images} width={THUMBNAIL_SIZES.MIDDLE.WIDTH} height={THUMBNAIL_SIZES.MIDDLE.HEIGHT} /> }
                        </Box>
                    </Link>
                </div>
                <div className={ "summary" + " summary-" + align }>
                    {(data.subTitle || data.subSubTitle) && (
                    <H6 className="name">
                        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                            <span className="sub-title">
                                <Dotdotdot clamp={titleLine}>{data.subTitle + ' | ' + data.subSubTitle}</Dotdotdot>
                            </span>
                        </Link>
                    </H6>
                    )}
                    {data.title &&
                    <H5 className="title">
                        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                            <Dotdotdot clamp={titleLine}>{data.title}</Dotdotdot>
                        </Link>
                    </H5>
                    }
                    {data.description &&
                    <div className="description">
                        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                            <Dotdotdot clamp={descriptionLine}>{data.description}</Dotdotdot>
                        </Link>
                    </div>
                    }
                    {this.props.children}
                    {data.tags &&
                    <ICardTags tags={data.tags}/>
                    }
                    <div className="like">
                        <Icon icon="heart" iconSize={Icon.SIZE_LARGE}/>
                    </div>
                    <div>
                        {data.date &&
                        <span className="date">{distanceCreatedDate},&nbsp;</span>
                        }
                        {data.author &&
                        <span className="author">by {data.author}</span>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const EmptyHBox = ({data, large, width}: ICardProps) => {
    return (
        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
            <div className={large ? 'h-box' : 'small-h-box'}>
                <NonIdealState
                    title={data.title}
                    description={data.description}
                />
            </div>
        </Link>
    );
}

export default HBox;