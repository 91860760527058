import * as React from "react";
import {H5} from "@blueprintjs/core";

import "./Environment.css"
import {IShopEnv} from "../common/Domains";
import {ErrorPage} from "../utils/LoadingAndError";
import Dotdotdot from "react-dotdotdot";

interface IProps {
    env: IShopEnv
}

class Environment extends React.Component<IProps, {}> {
    public render() {
        const { env } = this.props;

        if(env) {
            return (
                <div className="environment">
                    <div className="info-box">
                        <H5 className="title">영업시간(Opening times)</H5>
                        <p className="value">{env.isClosed ? "영업 안함" : "영업 중"}, {env.openingTime} - {env.closingTime}</p>
                    </div>
                    <div className="info-box">
                        <H5 className="title">위치(Location)</H5>
                        <p className="value">{env.location}</p>
                    </div>
                    <div className="info-box">
                        <H5 className="title">전화번호(Tel)</H5>
                        <p className="value">{env.telNumber}</p>
                    </div>
                    <div className="info-box">
                        <H5 className="title">웹사이트(Home Page)</H5>
                        <p className="value">
                            <a href={env.homePageUrl} target="_blank"><Dotdotdot clamp={3}>{env.homePageUrl}</Dotdotdot></a>
                        </p>
                    </div>
                    <div className="info-box">
                        <H5 className="title">키워드(Keywords)</H5>
                        <p className="value">{env.keywords}</p>
                    </div>
                </div>
            );
        }
        else {
            return (
                <ErrorPage isOnlyMessage={true} />
            )
        }
    }
}

export default Environment;