import * as React from "react";
import {ITotalBrand, IUser} from "../../common/Domains";
import {useQuery} from "react-apollo-hooks";
import {
    CARD_WIDTH,
    LOAD_DATA_COUNT,
    LOAD_EMPTY_DATA_COUNT,
    THOUSANDS_COMMA_FORMAT
} from "../../common/Constant";
import {ErrorPage} from "../../utils/LoadingAndError";
import {ICardData} from "../../card/CardDomains";
import * as InfiniteScroll from "react-infinite-scroller";
import {gql} from "apollo-boost";
import {Button} from "@blueprintjs/core";
import BrandCard, {LoadBrandCard} from "./grid/BrandCard";
import * as numeral from "numeral";

const TOTAL_BRANDS_QUERY = gql `
    query TotalBrands (
        $userNo: Int
        $offset: Int
        $limit: Int
    ) {
        totalBrand(userNo: $userNo),
        totalBrands (
            userNo: $userNo,
            offset: $offset,
            limit: $limit
        ) {
            totalBrandId
            brand {
                brandId
                korName
                engName
                desc
            }
            author {
                no
            }
            description
            count
            createdDate
            modifiedDate
        }
    }
`;

interface IProps {
    user: IUser
    category?: string
}

export const Summary = (props: IProps) => {
    return (
        <div className="items-container">
            <div className="grid-container">
                <div className="default-grid-container">
                    <div className="items-wrapper pad-t-1">
                        <TotalBrandItems userNo={props.user.no} />
                    </div>
                </div>
            </div>
        </div>
    );
};

interface ITotalBrandItemsProps {
    userNo?: number
}

export const TotalBrandItems = (props: ITotalBrandItemsProps) => {
    const { userNo } = props;

    const { loading, error, data, fetchMore, networkStatus } = useQuery(TOTAL_BRANDS_QUERY, {
        variables: {
            userNo,
            offset: 0,
            limit: LOAD_DATA_COUNT
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: false
    });

    if(loading || networkStatus === 4) {
        return <LoadingBox key="0" />;
    }

    if(error) {
        return <ErrorPage />;
    }

    const { totalBrands, totalBrand } = data || { totalBrands: null, totalBrand: null };

    if(!totalBrands || totalBrands.length === 0) {
        const emptyData = {
            id: "0",
            link: {
                path: ""
            },
            title: "No results"
        } as ICardData;

        return (
            <>
                <BrandCard key={emptyData.id} data={emptyData} large={false} width={CARD_WIDTH} interactive={true} isEmpty={true} />
                <div style={{clear: "left"}} />
            </>
        );
    }

    const offset = totalBrands ? totalBrands.length : 0;
    const hasMore = (offset !== 0 && offset % LOAD_DATA_COUNT === 0) ? true : false;

    const loadMoreHandler = () => {
        fetchMore({
            variables: {
                offset
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                if(!fetchMoreResult) {
                    return prev;
                }

                return Object.assign({}, prev, {
                    totalBrands: [ ...prev.totalBrands, ...fetchMoreResult.totalBrands ]
                });
            }
        });
    };

    return (
        <>
            <div className="input-description pad-w-1">
                {totalBrand}개의 식물종이 등록되어 있습니다.
            </div>
            <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={loadMoreHandler}
                hasMore={hasMore}
                loader={<LoadingBox key="0" />}>
                {
                    (totalBrands || []).map((tempTotalBrand: ITotalBrand) => {
                        const boxData = {
                            id: tempTotalBrand.totalBrandId + "",
                            link: {
                                path: ""
                            },
                            date: tempTotalBrand.createdDate,
                            title: tempTotalBrand.brand.engName,
                            subTitle: tempTotalBrand.brand.korName,
                            subSubTitle: tempTotalBrand.brand.desc,
                            description: tempTotalBrand.description,
                            value: numeral(tempTotalBrand.count).format(THOUSANDS_COMMA_FORMAT),
                            author: "",
                            extra: tempTotalBrand
                        } as ICardData;

                        return <BrandCard key={boxData.id} data={boxData} large={false} width={CARD_WIDTH} interactive={false} />
                    })
                }
                <div style={{clear: "left"}} />
            </InfiniteScroll>
            <LoadMore loadMoreHandler={loadMoreHandler} />
        </>
    );
};

const LoadMore = ({ loadMoreHandler }: any) => {
    return (
        <div className="group-center pad-h-3">
            <Button minimal={true} onClick={loadMoreHandler} >Load more</Button>
        </div>
    );
};


export const LoadingBox = () => {
    return (
        <>
            {[...Array(LOAD_EMPTY_DATA_COUNT)].fill(0).map((_: any, index: number) => {
                const testData = {
                    id: index + "",
                    link: {
                        path: ""
                    },
                    title: "No results"
                } as ICardData;

                return <LoadBrandCard  key={testData.id} data={testData} width={CARD_WIDTH} />;
            })}
            <div style={{clear: "left"}} />
        </>
    );
};