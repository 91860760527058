import * as React from "react";

import "node_modules/react-quill/dist/quill.snow.css";
import "../../common/css/quill-editor.css";
import "./AddBoard.css";
import {Mutation, Query} from "react-apollo";
import {RouteComponentProps} from "react-router";
import EditBoard from "./EditBoard";
import {H5, IToastProps, Position, Toast, Toaster} from "@blueprintjs/core";
import {getUserInfo, isBlockedUser} from "../common/UserInfo";
import {redirectCustom, redirectRoot} from "../utils/PageFolwUtils";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";
import {UPDATE_BOARD_QUERY} from "./ModifyBoard";
import {TEMPORARY_BOARD_QUERY} from "./TemporaryBoard";
import {BoardStatus} from "../common/Domains";

const isEnableAutoSave = false;

interface IProps extends RouteComponentProps {
    category: string
    boardId: number
}

interface IState {
    toasters: IToastProps[]
}

class TemporaryModifyBoard extends React.Component<IProps, IState> {
    private toaster: Toaster;

    private refToasterHandler = {
        toaster: (ref: Toaster) => this.toaster = ref
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            toasters: []
        }
    }

    public render() {
        if(isBlockedUser()) {
            redirectRoot();
        }

        const currentUser = getUserInfo();

        const { category, boardId } = this.props.match.params as IProps;

        if(!category) {
            return <ErrorPage message="Invalid request path" />;
        }

        if(!boardId) {
            return <ErrorPage />;
        }

        const newBoardId = Number(boardId);

        return (
            <div className="default-grid-container add-board-container">
                <div className="pad-h-1">
                    <div className="header-wrapper-2">
                        <H5 className="title">Modify {category}</H5>
                    </div>
                </div>
                <Query
                    query={TEMPORARY_BOARD_QUERY}
                    variables={{ boardId: newBoardId, userNo: currentUser.no }}
                    fetchPolicy="cache-and-network"
                >
                    {({loading, error, data}: any) => {
                        if(loading) {
                            return <LoadingPage />;
                        }

                        if(error) {
                            return <ErrorPage />;
                        }

                        const { temporaryBoard } = data || { temporaryBoard: null };

                        return (
                            <Mutation
                                mutation={UPDATE_BOARD_QUERY}
                                update={(cache: any, {data: {updateBoard}}: any) => {
                                    const tempBoard = cache.readQuery({
                                        query: TEMPORARY_BOARD_QUERY,
                                        variables: { boardId: updateBoard.boardId }
                                    });

                                    if(tempBoard) {
                                        cache.writeQuery({
                                            query: TEMPORARY_BOARD_QUERY,
                                            variables: {boardId: updateBoard.boardId},
                                            data: { temporaryBoard: updateBoard}
                                        });
                                    }
                                }}
                                onCompleted={this.onCompleteSave}
                            >
                                {(saveBoard: any) => (
                                <EditBoard
                                    isTemporary={true}
                                    category={category}
                                    board={temporaryBoard}
                                    boardId={newBoardId}
                                    saveHandler={saveBoard}
                                    isEnableAutoSave={isEnableAutoSave}
                                    history={this.props.history}
                                    location={this.props.location}
                                    match={this.props.match}
                                />
                                )}
                            </Mutation>
                        );
                    }}
                </Query>
                <Toaster position={Position.BOTTOM} ref={this.refToasterHandler.toaster}>
                    {
                        this.state.toasters.map((toast, idx) => {
                            return <Toast key={idx} message={toast.message} />;
                        })
                    }
                </Toaster>
            </div>
        );
    }

    private onCompleteSave = (data: any) => {
        this.toaster.show({message: "Success"});

        const { category, boardId } = this.props.match.params as IProps;

        if(data.updateBoard.status === BoardStatus.TEMPORARY) {
            redirectCustom("/temporaryBoard/view/" + category + "/" + boardId);
        }
        else {
            redirectCustom("/board/view/" + category + "/" + boardId);
        }

    }
}

export default TemporaryModifyBoard;