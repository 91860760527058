import * as React from "react";
import {ButtonBack, ButtonNext, CarouselProvider, Dot, DotGroup, Slide, Slider} from "pure-react-carousel";

import "pure-react-carousel/dist/react-carousel.es.css";
import "./../common/css/Carousel.css";

interface IProps {
    width: number,
    height: number,
    totalSlide: number,
    isPlaying?: boolean,
    interval?: number,
    children: React.ReactNode[]
}

export const PureCarousel = (props: IProps) => {
    return (
        <CarouselProvider
            className="carousel_container"
            naturalSlideWidth={ props.width }
            naturalSlideHeight={ props.height }
            totalSlides={ props.totalSlide }
            isPlaying={ props.isPlaying || false }
            interval={ props.interval || 5000 }
            isIntrinsicHeight={true}
        >
            <Slider>
                {
                    React.Children.map(props.children, (child, index) => {
                        return (
                            <Slide index={index}>
                                {child}
                            </Slide>
                        )
                    })
                }
            </Slider>
            <ButtonBack className="button_back">
                <svg width="30" height="70" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <line stroke="#72450e" stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_1" y2="35" x2="1" y1="1" x1="29" stroke-width="1.5" fill="#fff"/>
                        <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_2" y2="69" x2="29" y1="34.66667" x1="1" stroke-width="1.5" stroke="#72450e" fill="#fff"/>
                    </g>
                </svg>
            </ButtonBack>
            <ButtonNext className="button_next">
                <svg width="30" height="70" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <line stroke="#72450e" stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_1" y2="35" x2="29" y1="1" x1="1" stroke-width="1.5" fill="#fff"/>
                        <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_2" y2="69" x2="1" y1="34.66667" x1="29" stroke-width="1.5" stroke="#72450e" fill="#fff"/>
                    </g>
                </svg>
            </ButtonNext>
            <DotGroup renderDots={(dotsProps) => {
                return(
                    <>
                        {
                            React.Children.map(props.children, (child, index) => {
                                return (
                                    <Dot slide={index} >
                                        {dotsProps.currentSlide === index ?
                                            <svg viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg'><circle cx='10' cy='10' r='10' fill='gray'/></svg>
                                            : <svg viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg'><circle cx='10' cy='10' r='10' fill='#e3e3e3'/></svg>
                                        }
                                    </Dot>
                                )
                            })
                        }
                    </>
                );
            }} />
        </CarouselProvider>
    )
}