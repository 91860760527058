import * as React from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {Button, H3, Tab, TabId, Tabs} from "@blueprintjs/core";
import {distanceInWordsToNow} from "date-fns";
import {Helmet} from 'react-helmet';
import Summary from "./view/sub/summary/Summary";
import Comments from "./view/sub/comment/Comments";
import Environment from "./Environment";
import {gql} from "apollo-boost";
import {Query} from "react-apollo";
import {Profile, ProfileType} from "../user/Profile";
import {getUserInfo, isBlockedUser} from "../common/UserInfo";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";
import {DeletePopover} from "./Board";

import "./Board.css";
import {SITE_TITLE} from "../common/Constant";

export const TEMPORARY_BOARD_QUERY = gql `
    query TemporaryBoard($boardId: Int, $userNo: Int) {
        temporaryBoard(boardId: $boardId, userNo: $userNo) {
            boardId
            groupId
            category {
                categoryId
            }
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            title
            description
            content
            type
            status
            isDelete
            boardEnv {
                envId
                isDisplay
                displayStartDate
                displayEndDate
            }
            boardImages {
                boardImageId
               image {
                   imageId
                   realName
               }
               isDefault
               createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

export const DELETE_BOARD_QUERY = gql `
    mutation DeleteBoard($board: InputBoard) {
        deleteBoard(board: $board) {
            boardId
        }
    }
`;

interface IBoardProps extends RouteComponentProps {
    category: string
    boardId: number
}

interface IBoardState {
    selectedSubTabId: TabId
}

class TemporaryBoard extends React.Component<IBoardProps, IBoardState> {
    constructor(props: IBoardProps) {
        super(props);

        this.state = {
            selectedSubTabId: "comment"
        };

        this.goBackHandler = this.goBackHandler.bind(this);
    }

    public render() {
        const currentUser = getUserInfo();
        const { category, boardId } = this.props.match.params as IBoardProps;

        if(!boardId) {
            return <ErrorPage />;
        }

        const newBoardId = Number(boardId);

        return (
            <Query
                query={TEMPORARY_BOARD_QUERY}
                variables={{ boardId: newBoardId, userNo: currentUser.no }}
                notifyOnNetworkStatusChange={true}
                fetchPolicy="cache-and-network"
            >
                {({loading, error, data, networkStatus}: any) => {
                    if(loading) {
                        return <LoadingPage />;
                    }

                    if(error) {
                        return <ErrorPage />;
                    }

                    const { temporaryBoard } = data || { temporaryBoard: null };

                    if(!temporaryBoard || temporaryBoard.boardId <= 0) {
                        return <ErrorPage />;
                    }

                    const imagePath = temporaryBoard.author.imageUrl;
                    const distanceCreatedDate = distanceInWordsToNow(temporaryBoard.createdDate);
                    const distanceModifiedDate = distanceInWordsToNow(temporaryBoard.modifiedDate);

                    return (
                        <div className="board-wrapper">
                            <Helmet>
                                <title>{ temporaryBoard.title + ", " + SITE_TITLE }</title>
                                <link rel="canonical" href={ window.location.href } />
                                <meta name="description" content={temporaryBoard.title} />
                                <meta property="og:url" content={ window.location.href } />
                                <meta property="og:type" content="website" />
                                <meta property="og:title" content={ temporaryBoard.title + ", " + SITE_TITLE } />
                                <meta property="og:description" content={temporaryBoard.description} />
                                <meta property="og:image" content={imagePath} />
                            </Helmet>
                            <div className="grid-container pad-t-3">
                                <div className="default-grid-container">
                                    <div className="header pad-h-1">
                                        <H3 className="title">
                                            {temporaryBoard.title}
                                        </H3>
                                        <div className="description">
                                            {temporaryBoard.description}
                                        </div>
                                        <div className="author-item-container pad-h-1">
                                            <Profile user={temporaryBoard.author}
                                                     profileType={ProfileType.SIMPLE}
                                                     large={false} isForceUserDetail={true} disableDescription={true}
                                            >
                                                    <span className="date" title="Created date">{distanceCreatedDate}</span>
                                                    {(distanceCreatedDate !== distanceModifiedDate) && (
                                                        <>
                                                        ,&nbsp;
                                                        <span className="date" title="Modified date">{distanceModifiedDate}</span>
                                                        </>
                                                    )}
                                            </Profile>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container">
                                    <div className="pad-h-1">
                                        <Environment env={temporaryBoard.boardEnv}/>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container" >
                                    <div className="pad-h-1">
                                        <Summary content={temporaryBoard.content}>
                                            <UserActions category={category} boardId={temporaryBoard.boardId} userNo={temporaryBoard.author.no} />
                                        </Summary>

                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container border-top">
                                    <div className="pad-h-1">
                                        <Profile user={temporaryBoard.author} profileType={ProfileType.DEFAULT} large={false} isForceUserDetail={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container">
                                    <div className="pad-h-1">
                                        <Tabs id="sub-info" large={true} animate={true} selectedTabId={this.state.selectedSubTabId}
                                              onChange={this.subInfoChanger}>
                                            <Tab id="comment" title={"Review"} panel={<Comments boardId={temporaryBoard.boardId} />} />
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    }

    public subInfoChanger = (selectedSubTabId: TabId) => {
       this.setState({
           selectedSubTabId
       });
    };

    private goBackHandler = () => {
        this.props.history.back();
    };
}

export default withRouter(TemporaryBoard);

interface IUserActions {
    category: string
    boardId: number
    userNo: number
}

const UserActions = (props: IUserActions) => {
    const currentUser = getUserInfo();

    const {category, boardId, userNo} = props;

    const isEnableAction = currentUser.no === userNo;

    if(isBlockedUser()) {
        return (
            <div>
                <div className="group-center pad-h-3">
                    { isEnableAction &&
                    <>
                        <Button minimal={true} disabled={true}>List</Button>
                        <Button minimal={true} disabled={true}>Modify</Button>
                        <Button minimal={true} disabled={true}>Delete</Button>
                    </>
                    }
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="group-center pad-h-3">
            { isEnableAction &&
            <>
                <Link to={"/temporaryBoard/" + category}>
                    <Button minimal={true}>List</Button>
                </Link>
                <Link to={"/temporaryBoard/update/" + category + "/" + boardId}>
                    <Button minimal={true}>Modify</Button>
                </Link>
                <DeletePopover boardId={boardId} userNo={userNo} />
            </>
            }
            </div>
        </div>
    );
};