import {useMutation, useQuery} from "react-apollo-hooks";
import {IBrand, BrandType} from "../common/Domains";
import {LOAD_DATA_COUNT} from "../common/Constant";
import * as InfiniteScroll from "react-infinite-scroller";
import * as React from "react";
import {
    Button,
    Classes,
    ControlGroup,
    Dialog,
    FormGroup,
    InputGroup,
    Alert, Collapse, ButtonGroup, PopoverInteractionKind, Popover, H5, Intent
} from "@blueprintjs/core";
import {gql} from "apollo-boost";
import {useState} from "react";
import {useForm} from "react-hook-form";

import "./Brands.css";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";

const BRANDS_QUERY = gql `
    query Brands($keyword: String, $brandType: BrandType, $offset: Int, $limit: Int) {
        brands(keyword: $keyword, brandType: $brandType, offset: $offset, limit: $limit) {
            brandId
            brandType
            korName
            engName
            desc
            createdDate
            modifiedDate
        }
    }
`;

const ADD_BRAND_QUERY = gql `
    mutation AddBrand($brand: InputBrand) {
        addBrand(brand: $brand) {
            brandId
            brandType
            korName
            engName
            desc
            createdDate
            modifiedDate
        }
    }
`;

interface ISearchBrandProps {
    onSearch: (brand: IBrand) => void;
}

export const SearchBrand = (props: ISearchBrandProps) => {
    const { onSearch } = props;

    const [ isDialogOpen, setIsDialogOpen ] = useState(false);
    const [ currentKeyword, setCurrentKeyword] = useState("");
    const [ keyword, setKeyword] = useState("");

    const [ isAddBrandOpen, setIsAddBrand ] = useState(false);

    const openHandler = () => {
        setIsDialogOpen(!isDialogOpen);
    };

    const closeHandler = () =>  {
        setIsDialogOpen(!isDialogOpen);
    };

    const keywordKeyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === "Enter") {
            searchHandler();
        }
    };

    const searchHandler = () => {
        setKeyword(currentKeyword);
    };

    const selectBrandItemHandler = (brand: IBrand) => {
        onSearch(brand);

        closeHandler();
    };

    return (
        <>
            <Button onClick={openHandler}>브랜드 검색</Button>
            <Dialog
                onClose={closeHandler}
                title="브랜드 검색"
                isOpen={isDialogOpen}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="brand"
                        label="한글명, 영문명, 설명을 검색해주세요."
                    >
                        <ControlGroup fill={false}>
                            <InputGroup name="brand"
                                        className="input-brand"
                                        placeholder="브랜드명 입력해주세요."
                                        large={true}
                                        onKeyDown={keywordKeyPressHandler}
                                        onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                            setCurrentKeyword(event.currentTarget.value);
                                        }}
                                        defaultValue=""
                            />
                            <Button
                                onClick={searchHandler}
                            >
                                Search
                            </Button>
                        </ControlGroup>
                    </FormGroup>
                    <div className="brand-list-container">
                        <Brands brandName={keyword} selectItemHandler={selectBrandItemHandler} />
                    </div>
                    <ButtonGroup className="pad-t-1" vertical={true} alignText="left">
                        <Button minimal={true} onClick={() => {
                            if(selectBrandItemHandler) {
                                selectBrandItemHandler({brandId: 0, korName: "알 수 없는 브랜드", engName: "Unknown", desc: "Unknown"} as IBrand);
                            }
                        }}>
                            <p>등록된 브랜드가 없나요? "알수 없는 브랜드"로 등록하기</p>
                        </Button>
                        <Button minimal={true} onClick={() => {
                            setIsAddBrand(!isAddBrandOpen);
                        }}>
                            <p>등록된 브랜드가 없나요? 등록 요청하기</p>
                        </Button>
                    </ButtonGroup>
                    <AddBrand isOpen={isAddBrandOpen} />
                </div>
            </Dialog>
        </>
    );
};

interface ISearchBrandItemsProps {
    brandName: string
    selectItemHandler: (brand: IBrand) => void
}

export const Brands = (props: ISearchBrandItemsProps) => {
    const { brandName, selectItemHandler } = props;

    if(!brandName) {
        return <BrandBox />;
    }

    const { loading, error, data, fetchMore, networkStatus } = useQuery(BRANDS_QUERY, {
        variables: {
            keyword: brandName,
            brandType: BrandType.UNKNOWN,
            offset: 0,
            limit: 12
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: false
    });

    if(loading || networkStatus === 4) {
        return <LoadingPage />;
    }

    if(error) {
        return <ErrorPage />;
    }

    const { brands } = data || { brands: null };

    if(!brands || brands.length === 0) {
        return <BrandBox />;
    }

    const offset = brands ? brands.length : 0;
    const hasMore = (offset !== 0 && offset % LOAD_DATA_COUNT === 0) ? true : false;

    const loadMoreHandler = () => {
        fetchMore({
            variables: {
                offset
            },
            updateQuery: (prev: any, { fetchMoreResult}: any) => {
                if(!fetchMoreResult) {
                    return prev;
                }
                return Object.assign({}, prev, {
                    brands: [...prev.brands, ...fetchMoreResult.brands]
                });
            }
        });
    };

    return (
        <div className="brand-list-wrapper">
            <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={loadMoreHandler}
                hasMore={hasMore}
                loader={<div key="0">loading...</div>}
                useWindow={false}
            >
                {
                    (brands || []).map((brand: IBrand) => {
                        return <BrandBox key={brand.brandId + "d"} brand={brand} selectItemHandler={selectItemHandler} />;
                    })
                }
            </InfiniteScroll>
            <LoadMore loadMoreHandler={loadMoreHandler} />
        </div>
    );
};

interface IBrandBoxProps {
    brand?: IBrand
    selectItemHandler?: (brand: IBrand) => void
}

const BrandBox = (props: IBrandBoxProps) => {
    const { brand, selectItemHandler } = props;

    if(!brand) {
        return (
            <div className="brand-box-wrapper group-center">
                No Results
            </div>
        );
    }

    return (
        <div className="brand-box-wrapper" onClick={() => {
            if(selectItemHandler) {
                selectItemHandler(brand);
            }
        }}>
            {brand.korName}, {brand.engName}, <i>{brand.desc}</i>
        </div>
    );
};

const LoadMore = ({ loadMoreHandler }: any) => {
    return (
        <div className="group-center pad-h-3">
            <Button minimal={true} onClick={loadMoreHandler} >Load more</Button>
        </div>
    );
};

interface IAddBrand {
    isOpen: boolean
}

const AddBrand = (props: IAddBrand) => {
    const { isOpen } = props;

    const [ isAddSuccessAlert, setIsAddSuccessAlert] = useState(false);
    const [ addBrand ] = useMutation(ADD_BRAND_QUERY);

    const { register, errors, getValues, trigger } = useForm();

    const submitHandler = () => {
        trigger().then((isValid)=> {
            if(isValid) {
                const formData = getValues();

                const {
                    korName,
                    engName,
                    desc
                } = formData as IBrand;

                addBrand({
                    variables: {
                        brand: {
                            brandType: BrandType.UNKNOWN,
                            korName,
                            engName,
                            desc,
                            isConfirmed: false
                        }
                    }
                });

                setIsAddSuccessAlert(true);
            }
        });
    };

    return (
        <Collapse isOpen={isOpen}>
            <div className="brand-add-container pad-t-1">
                <p className="input-description">국명, 영명, 학명은 Google에 검색해보세요.</p>
                <form>
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="korName"
                        label="국명을 입력해주세요."
                    >
                        <InputGroup name="korName"
                                    className="input-korName"
                                    placeholder="국명을 입력해주세요."
                                    inputRef={register({ required: true, minLength: 1, maxLength: 100 })}
                                    defaultValue=""
                        />
                        <div className="valid-error-wrapper">
                            {errors.korName && errors.korName.type === "required" && (
                                <p className="valid-error">국명을 입력해주세요.</p>
                            )}
                            {errors.korName && errors.korName.type === "minLength" && (
                                <p className="valid-error">국명을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.korName && errors.korName.type === "maxLength" && (
                                <p className="valid-error">국명을 100자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="engName"
                        label="영명을 입력해주세요."
                    >
                        <InputGroup name="engName"
                                    className="input-engName"
                                    placeholder="영명을 입력해주세요."
                                    inputRef={register({ required: true, minLength: 1, maxLength: 100 })}
                                    defaultValue=""
                        />
                        <div className="valid-error-wrapper">
                            {errors.engName && errors.engName.type === "required" && (
                                <p className="valid-error">영명을 입력해주세요.</p>
                            )}
                            {errors.engName && errors.engName.type === "minLength" && (
                                <p className="valid-error">영명을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.engName && errors.engName.type === "maxLength" && (
                                <p className="valid-error">영명을 100자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="engName"
                        label="학명을 입력해주세요."
                    >
                        <InputGroup name="desc"
                                    className="input-desc"
                                    placeholder="학명을 입력해주세요."
                                    inputRef={register({ required: true, minLength: 1, maxLength: 100 })}
                                    defaultValue=""
                        />
                        <div className="valid-error-wrapper">
                            {errors.desc && errors.desc.type === "required" && (
                                <p className="valid-error">학명을 입력해주세요.</p>
                            )}
                            {errors.desc && errors.desc.type === "minLength" && (
                                <p className="valid-error">학명을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.desc && errors.desc.type === "maxLength" && (
                                <p className="valid-error">학명을 100자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                    <p className="input-description">요청한 식물은 확인 후 등록됩니다.<br />
                        "알 수 없는 식물"으로 선 작성 후 추후에 변경해주세요.
                    </p>
                    <Popover
                        interactionKind={PopoverInteractionKind.CLICK}
                        popoverClassName="bp3-popover-content-sizing"
                    >
                        <Button minimal={true}>
                            Request
                        </Button>
                        <div>
                            <H5>등록 요청</H5>
                            <p>등록 요청하시겠습니까?</p>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
                                <Button className="bp3-popover-dismiss" style={{ marginRight: 10 }}>
                                    Cancel
                                </Button>
                                <Button intent={Intent.PRIMARY} className="bp3-popover-dismiss" onClick={submitHandler}>
                                    Request
                                </Button>
                            </div>
                        </div>
                    </Popover>
                </form>
                <Alert
                    confirmButtonText="Success"
                    isOpen={isAddSuccessAlert}
                    onClose={() => { setIsAddSuccessAlert(false)}}
                >
                    <p>
                        등록 요청되었습니다. 확인 후 등록 완료됩니다.
                    </p>
                </Alert>
            </div>
        </Collapse>
    );
}