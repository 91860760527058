import * as React from "react";
import {InputGroup} from "@blueprintjs/core";
import {useState} from "react";

import "./SearchWrapper.css";

interface ISearchWrapper {
    searchHandler: (keyword: string) => void
}

export const SearchWrapper = (props: ISearchWrapper) => {
    const { searchHandler } = props;

    const [keyword, setKeyword] = useState("");

    const changeHandler = (event: React.FormEvent<HTMLInputElement>) => {
            setKeyword(event.currentTarget.value);
    };

    const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === "Enter" && searchHandler) {
            searchHandler(keyword);
        }
    };

    return (
        <span className="search-wrapper">
            <InputGroup
                leftIcon="search"
                placeholder="Search..."
                round={true}
                onKeyDown={keyPressHandler}
                onChange={changeHandler}
            />
        </span>
    );
};