import * as React from "react";
import {gql} from "apollo-boost";
import {
    getTags,
    IShop,
    IShopImage, IShopRecommend, RecommendType,
    SHOP_ENV_METAS,
    ShopCategory
} from "../../common/Domains";
import {Query} from "react-apollo";
import HBox from "../../card/HBox";
import {CardAlign, ICardData} from "../../card/CardDomains";
import {ErrorPage} from "../../utils/LoadingAndError";

export const RECOMMEND_SHOP_QUERY = gql `
    query RecommendShops (
        $recommendType: RecommendType,
        $categoryId: Int,
        $offset: Int,
        $limit: Int
    ) {
        recommendShops(recommendType: $recommendType, categoryId: $categoryId, offset: $offset, limit: $limit) {
            recommendId
            shop {
                shopId
                category {
                    categoryId
                }
                author {
                    no
                    imageUrl
                    displayName
                    description
                }
                createdDate
                modifiedDate
                name
                description
                content
                type
                status
                shopEnv {
                    envId
                    location
                    latitude
                    longitude
                    country
                    isClosed
                    openingTime
                    closingTime
                    homePageUrl
                    telNumber
                    keywords
                }
                shopImages {
                    shopImageId
                    image {
                        imageId
                        realName
                    }
                    isDefault
                    createdDate
                }
                counter {
                    counterId
                    count
                }
            }
        }
    }
`;

class Recommend extends React.Component {

    public render() {
        return (
            <Query
                query={RECOMMEND_SHOP_QUERY}
                variables={{
                    recommendType: RecommendType.PICK,
                    categoryId: ShopCategory.ALL,
                    offset: 0,
                    limit: 1
                }}

                fetchPolicy="cache-and-network"
            >
                {({loading, error, data, fetchMore}: any) => {
                    if(error) {
                        return <ErrorPage />;
                    }

                    const { recommendShops } = data || { recommendShops: [] };

                    return (
                        <>
                            {
                                (recommendShops || []).map((shopRecommend: IShopRecommend) => {
                                    const shop = shopRecommend.shop as IShop;

                                    const images = shop.shopImages.filter((shopImage: IShopImage) => {
                                        return shopImage.isDefault;
                                    })
                                    .map((shopImage: IShopImage) => {
                                        return shopImage.image;
                                    });

                                    const boxData = {
                                        id: shop.shopId + "",
                                        link: {
                                            path: "/shop/" + shop.shopId,
                                            localParams: {}
                                        },
                                        images,
                                        date: shop.createdDate,
                                        title: shop.name,
                                        description: shop.description,
                                        author: shop.author.displayName,
                                        tags: getTags(SHOP_ENV_METAS, data.shopEnv)
                                    } as ICardData;


                                    return <HBox key={boxData.id} data={boxData} large={true} titleLine={3} descriptionLine={5} align={CardAlign.RIGHT} />
                                })
                            }
                            <div style={{clear: "left"}} />
                        </>
                    );
                }}
            </Query>
        );
    };
}

export default Recommend;