import * as React from "react";

import "./Environment.css"
import {IBoardEnv} from "../common/Domains";
import {ErrorPage} from "../utils/LoadingAndError";

interface IProps {
    env: IBoardEnv
}

class Environment extends React.Component<IProps, {}> {
    public render() {
        const { env } = this.props;

        if(env) {
            return (
                <div className="environment">
                    &nbsp;
                </div>
            );
        }
        else {
            return (
                <ErrorPage />
            )
        }
    }
}

export default Environment;