import {ICardMeta} from "../card/CardDomains";

// RECOMMEND(SHOP, GARDEN, SHOP)
export interface IBoardRecommend {
    recommendId: number
    board: IBoard
    author: IUser
    authorIp: number
    createdDate: string
    modifiedDate: string
    startDate: string
    endDate: string
    recommendType: RecommendType
    isDelete: boolean
}

export interface IItemRecommend {
    recommendId: number
    item: IItem
    author: IUser
    authorIp: number
    createdDate: string
    modifiedDate: string
    startDate: string
    endDate: string
    recommendType: RecommendType
    isDelete: boolean
}

export interface IShopRecommend {
    recommendId: number
    shop: IShop
    author: IUser
    authorIp: number
    createdDate: string
    modifiedDate: string
    startDate: string
    endDate: string
    recommendType: RecommendType
    isDelete: boolean
}

// BRAND
export interface IBrand {
    brandId: number
    brandType: BrandType
    korName: string
    engName: string
    desc: string
    createdDate: string
    modifiedDate: string
    isConfirmed: boolean
    isDelete: boolean
}


// BOARD
export interface IBoard {
    boardId: number
    groupId: number
    category: ICategory
    author: IUser
    createdDate: string
    modifiedDate: string
    brand: IBrand
    title: string
    description: string
    content: string
    type: BoardType
    status: BoardStatus
    boardEnv: IBoardEnv
    boardImages: IBoardImage[]
    boardMetas: IBoardMeta[]
    counter: IBoardCounter
}

export interface IBoardEnv {
    envId: number
    isDisplay: boolean
    displayStartDate: string
    displayEndDate: string
}

export interface IBoardImage {
    boardImageId: number
    board: IBoard
    image: IImage
    isDefault: boolean
    createdDate: string
}

export interface IBoardMeta {
    metaId: number
    board: IBoard
    key: string
    value: string
}

export interface IBoardComment {
    commentId: number
    board: IBoard
    author: IUser
    authorIp: string
    createdDate: string
    content: string
    type: string
}

export interface IBoardLike {
    likeId: number
    board: IBoard
    userNo: number
    createdDate: string
}

export interface IBoardCounter {
    counterId: number
    board: IBoard
    count: number
    reportCount: number
    isDelete: boolean
}

export interface IBoardCounterMeta {
    counterMetaId: number
    board: IBoard
    counterIp: string
    counterLimit: number
    createdDate: string
}

// SHOP
export interface IItem {
    itemId: number
    groupId: number
    category: ICategory
    itemItemId: number
    author: IUser
    createdDate: string
    modifiedDate: string
    brand: IBrand
    title: string
    description: string
    content: string
    quantity: number
    price: number
    discountPrice: number
    discount: number
    discountUnit: string
    status: ItemStatus
    itemEnv: IItemEnv
    itemImages: IItemImage[]
    itemMetas: IItemMeta[]
    counter: IItemCounter
}

export interface IItemEnv {
    envId: number
    model: string
    origin: string
    manufacturer: string
    material: string
    size: string
    composition: string
    url: string
}

export interface IItemOption {
    optionId: number
    name: string
    price: number
    quantity: number
    order: number
    isEnable: boolean
    isDelete: boolean
}

export interface IItemImage {
    itemImageId: number
    item: IItem
    image: IImage
    isDefault: boolean
    createdDate: string
}

export interface IItemMeta {
    metaId: number
    item: IItem
    key: string
    value: string
}

export interface IItemComment {
    commentId: number
    item: IItem
    author: IUser
    authorIp: string
    createdDate: string
    content: string
    type: string
}

export interface IItemLike {
    likeId: number
    item: IItem
    userNo: number
    createdDate: string
}

export interface IItemCounter {
    counterId: number
    item: IItem
    count: number
    reportCount: number
    isDelete: boolean
}

export interface IItemCounterMeta {
    counterMetaId: number
    item: IItem
    counterIp: string
    counterLimit: number
    createdDate: string
}

// SHOP
export interface IShop {
    shopId: number
    groupId: number
    category: ICategory
    author: IUser
    createdDate: string
    modifiedDate: string
    name: string
    description: string
    content: string
    type: ShopType
    status: ShopStatus
    shopEnv: IShopEnv
    shopImages: IShopImage[]
    shopMetas: IShopMeta[]
    counter: IShopCounter
}

export interface IShopEnv {
    envId: number
    shop: IShop
    location: string
    latitude: string
    longitude: string
    country: string
    isClosed: boolean
    openingTime: string
    closingTime: string
    homePageUrl: string
    telNumber: string
    keywords: string
}

export interface IShopImage {
    shopImageId: number
    shop: IShop
    image: IImage
    isDefault: boolean
    createdDate: string
}

export interface IShopMeta {
    metaId: number
    shop: IShop
    key: string
    value: string
}

export interface IShopComment {
    commentId: number
    shop: IShop
    author: IUser
    authorIp: string
    createdDate: string
    content: string
    type: string
}

export interface IShopLike {
    likeId: number
    shop: IShop
    userNo: number
    createdDate: string
}

export interface IShopCounter {
    counterId: number
    shop: IShop
    count: number
    reportCount: number
    isDelete: boolean
}

export interface IShopCounterMeta {
    counterMetaId: number
    shop: IShop
    counterIp: string
    counterLimit: number
    createdDate: string
}

export interface ITotalBrand {
    totalBrandId: number
    createdDate: string
    brand: IBrand
    description: string
    count: number
    author: IUser
}

export interface ITotalBrandColor {
    brandColorId: number
    totalBrand: ITotalBrand
    colorType: TotalBrandColorType
    color: string
}

export interface IUserReport {
    reportId: number
    reportType: string
    targetType: string
    targetCategoryId: number
    targetId: number
    title: string
    content: string
    result: string
    author: IUser
    authorIp: string
    createdDate: string
    modifiedDate: string
    status: string
    isDelete: boolean
}

export interface IImage {
    imageId: number
    name: string
    realName: string
    desc: string
    year: number
    month: number
    userNo: number
    createdDate: string
    fullPath?: string
}

export interface ICategory {
    categoryId: number
    parent: ICategory
    order: number
    key: string
    name: string
    desc: string
    subCategories: [ICategory]
}

export interface IUser {
    no: number
    id: string
    password: string
    name: string
    displayName: string
    description: string
    email: string
    emailVerified: string
    imageUrl: string
    provider: string
    providerId: string
    registeredDate: string
    status: UserState
    userAuthorities: IUserAuthority[]
}

export interface IUserAuthority {
    authId: number
    user: IUser
    authorityName: UserAuthorityType
}

export enum UserState {
    REQUEST = "REQUEST",
    VALID = "VALID"
}

export enum UserAuthorityType {
    INVALID = "INVALID",
    BLOCKED = "BLOCKED",
    USER = "USER",
    MANAGER = "MANAGER",
    ADMIN = "ADMIN"
}

export enum AuthError {
    INVALID_USER= "INVALID_USER",
    NOT_EXISTS_USER = "NOT_EXISTS_USER"
}

export enum RecommendType {
    SPECIAL = "SPECIAL",
    FEATURED = "FEATURED",
    DISCOVER = "DISCOVER",
    PICK = "PICK",
    STAFF = "STAFF",
    READ_COUNT = "READ_COUNT",
    LIKE_COUNT = "LIKE_COUNT"
}

export enum BrandType {
    UNKNOWN = "UNKNOWN"
}

export enum BoardCategory {
    NEWS = 91000,
    SPECIAL = 92000,
    STORIES = 92100,
}

export const getBoardCategoryId = (category?: string) => {
    if(category === "news") {
        return BoardCategory.NEWS;
    }
    else if(category === "special") {
        return BoardCategory.SPECIAL;
    }
    else if(category === "stories") {
        return BoardCategory.STORIES;
    }

    return BoardCategory.NEWS;
}

// export enum ItemCategory {
//     ALL = -1,
//     HOME = 20,
//     SHOP = 21,
//     GARDEN = 22
// }

export interface ICategoryInfo {
    largeCategory: string,
    middleCategory?: string,
    smallCategory?: string
}

export const cloneCategoryInfo = (fromCategoryInfo: any) => {
    if(!fromCategoryInfo) {
        return {};
    }

    return {
        largeCategory: fromCategoryInfo.largeCategory ? fromCategoryInfo.largeCategory : null,
        middleCategory: fromCategoryInfo.middleCategory ? fromCategoryInfo.middleCategory : null,
        smallCategory: fromCategoryInfo.smallCategory ? fromCategoryInfo.smallCategory : null
    } as ICategoryInfo;
}

export const getItemCategoryKey = (largeCategory?: string, middleCategory?: string, smallCategory?: string) => {
    if(smallCategory) {
        return smallCategory;
    }

    if(middleCategory) {
        return middleCategory;
    }

    return largeCategory || "";
}

// export const ITEM_CATEGORIES = [
//     {
//         categoryId: ItemCategory.HOME,
//         name: "Home Item"
//     },
//     {
//         categoryId: ItemCategory.SHOP,
//         name: "Shop Item"
//     },
//     {
//         categoryId: ItemCategory.GARDEN,
//         name: "Garden Item"
//     }
// ];

export enum GardenCategory {
    ALL = -1,
    HOME = 30,
    KOREA = 31,
    OVERSEAS = 32
}

export const GARDEN_CATEGORIES = [
    {
        categoryId: GardenCategory.HOME,
        name: "Home Garden"
    },
    {
        categoryId: GardenCategory.KOREA,
        name: "Korea Garden"
    },
    {
        categoryId: GardenCategory.OVERSEAS,
        name: "Overseas Garden"
    }
];

export const getGardenCategoryId = (category?: string) => {
    if(category === "home") {
        return GardenCategory.HOME;
    }
    else if(category === "korea") {
        return GardenCategory.KOREA;
    }
    else if(category === "overseas") {
        return GardenCategory.OVERSEAS;
    }

    return GardenCategory.ALL;
}

export enum BoardCategory {
    ALL = -1
}

export enum ShopCategory {
    ALL = -1,
    ITEM = 40,
    FLOWER = 41,
    CAFE = 42,
    FARM = 43
}

export const SHOP_CATEGORIES = [
    {
        categoryId: ShopCategory.ITEM,
        name: "Item Shop"
    },
    {
        categoryId: ShopCategory.FLOWER,
        name: "Flower Shop"
    },
    {
        categoryId: ShopCategory.CAFE,
        name: "Coffee Shop"
    },
    {
        categoryId: ShopCategory.FARM,
        name: "Farm"
    }
];

export const getShopCategoryId = (category?: string) => {
    if(category === "item") {
        return ShopCategory.ITEM;
    }
    else if(category === "flower") {
        return ShopCategory.FLOWER;
    }
    else if(category === "farm") {
        return ShopCategory.FARM;
    }
    else if(category === "cafe") {
        return ShopCategory.CAFE;
    }

    return ShopCategory.ALL;
}

// BOARD
export enum BoardType {
    NONE = "NONE",
    NEWS = "NEWS",
    NOTICE = "NOTICE",
}

export const BOARD_BOARD_TYPES = [
    {
        boardTypeId: BoardType.NONE,
        name: "None"
    },
    {
        boardTypeId: BoardType.NEWS,
        name: "News"
    },
    {
        boardTypeId: BoardType.NOTICE,
        name: "Notice"
    }
];

export enum BoardStatus {
    TEMPORARY = "TEMPORARY",
    FINISHED = "FINISHED"
}

export const BOARD_ENV_METAS = [

] as ICardMeta[];

// ITEM
export enum ItemStatus {
    TEMPORARY = "TEMPORARY",
    FINISHED = "FINISHED"
}
// const ITEM_ENV_METAS = [
//     {   key: 'isLightSun',
//         name: "빛",
//         formatter: (data: any, key: string) => {
//             return data[key] ? "태양광" : "없음";
//         }
//     },
//     {
//         key: 'isLightBulb',
//         name: "전등",
//         formatter: (data: any, key: string) => {
//             return data[key] ? "조명등" : "없음";
//         }
//     },
//     {
//         key: 'itemPos',
//         name: "위치"
//     },
//     {
//         key: 'waterPeriod',
//         name: "물"
//     },
//     {
//         key: 'isWaterShower',
//         name: "샤워",
//         formatter: (data: any, key: string) => {
//             return data[key] ? "함" : "없음";
//         }
//
//     },
//     {
//         key: 'isWaterBottomWatering',
//         name: "저면관수",
//         formatter: (data: any, key: string) => {
//             return data[key] ? "함" : "없음";
//         }
//     },
//     {
//         key: 'temperature',
//         name: "온도",
//         formatter: (data: any, key: string) => {
//             return data[key] + "°C";
//         }
//     },
//     {
//         key: 'humidity',
//         name: "습도",
//         formatter: (data: any, key: string) => {
//             return data[key] + "%";
//         }
//     },
//     {
//         key: 'isWind',
//         name: "바람",
//         formatter: (data: any, key: string) => {
//             return data[key] ? "있음" : "없음";
//         }
//     },
//     // {
//     //     key: 'isWindDirect',
//     //     name: "직접 바람",
//     //     formatter: (data: any, key: string) => {
//     //         return data[key] ? "직접" : "간접";
//     //     }
//     // },
//     // {
//     //     key: 'windMethod',
//     //     name: "통풍 방법",
//     //     formatter: (data: any, key: string) => {
//     //         return data[key] === "wind" ? "자연풍" : "선풍기";
//     //     }
//     // },
//     // {
//     //     key: 'windDistance',
//     //     name: "통풍 거리"
//     // }
// ] as ICardMe
// exportta[];

// GARDEN
export enum GardenType {
    ALL = "ALL",
    UNKNOWN = "UNKNOWN"
}

export enum GardenStatus {
    TEMPORARY = "TEMPORARY",
    FINISHED = "FINISHED"
}

export const GARDEN_ENV_METAS = [
    {
        key: 'weather',
        name: "기후"
    },
    {
        key: 'openingTime',
        name: "운영 시작 시간"
    },
    {
        key: 'closingTime',
        name: "운영 종료 시간"
    }
] as ICardMeta[];

// SHOP
export enum ShopType {
    ALL = "ALL",
    UNKNOWN = "UNKNOWN"
}

export enum ShopStatus {
    TEMPORARY = "TEMPORARY",
    FINISHED = "FINISHED"
}

export const SHOP_ENV_METAS = [
    {   key: 'isClosed',
        name: "영업",
        formatter: (data: any, key: any) => {
            return data[key] ? "영업 중" : "영업 안함";
        }
    },
    {
        key: 'openingTime',
        name: "오픈시간"
    },
    {
        key: 'closingTime',
        name: "마감시간"
    }
] as ICardMeta[];

// SUMMARY
export enum TotalBrandColorType {
    COLOR01 = "COLOR01",
    COLOR02 = "COLOR02",
    COLOR03 = "COLOR03",
}

// USER REPORT
export enum UserReportType {
    ADVERTISEMENT = "ADVERTISEMENT",
    CUSS = "CUSS"
}

export enum UserReportTargetType {
    ITEM = "ITEM",
    GARDEN = "GARDEN",
    SHOP = "SHOP",
    BOARD = "BOARD"
}

export const USER_REPORT_TYPE = [
    {
        reportTypeId: UserReportType.ADVERTISEMENT,
        name: "광고"
    },
    {
        reportTypeId: UserReportType.CUSS,
        name: "비방, 욕설, 비속 등"
    }
];

export const getTags = (metas: ICardMeta[], data: any) => {
    return (metas || []).filter((meta: ICardMeta) => {
            return(data && data[meta.key] !== undefined);
        })
        .map((meta: ICardMeta) => {
            const formattedValue = meta.formatter ? meta.formatter(data, meta.key) : data[meta.key];

            return {
                key: meta.key,
                name: meta.name,
                value: formattedValue
            }
        });
}

export enum CardType {
    // CARD
    VERTICAL_CARD = "VERTICAL_CARD",
    HORIZON_CARD = "HORIZON_CARD",
    SMALL_VERTICAL_CARD = "SMALL_VERTICAL_CARD",
    SMALL_HORIZON_CARD = "SMALL_HORIZON_CARD",

    // BOX
    VERTICAL_BOX = "VERTICAL_BOX",
    HORIZON_BOX = "HORIZON_BOX",
    SMALL_VERTICAL_BOX = "SMALL_VERTICAL_BOX",
    SMALL_HORIZON_BOX = "SMALL_HORIZON_BOX"
}

