export const STORE_KEY_TOKEN = "token";

export const setToken = (token: string) => {
    localStorage.setItem(STORE_KEY_TOKEN, token);
};

export const getToken = () => {
    return localStorage.getItem(STORE_KEY_TOKEN);
};

export const resetToken = () => {
    localStorage.setItem(STORE_KEY_TOKEN, "");
};