import * as React from "react";

import "node_modules/react-quill/dist/quill.snow.css";
import "../../common/css/quill-editor.css";
import "./AddBoard.css";
import {Mutation, Query} from "react-apollo";
import {gql} from "apollo-boost";
import {RouteComponentProps} from "react-router";
import EditBoard from "./EditBoard";
import {H5, IToastProps, Position, Toast, Toaster} from "@blueprintjs/core";
import {BOARD_QUERY} from "./Board";
import {isBlockedUser} from "../common/UserInfo";
import {redirectCustom, redirectRoot} from "../utils/PageFolwUtils";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";
import {BoardStatus} from "../common/Domains";

const isEnableAutoSave = false;

export const UPDATE_BOARD_QUERY = gql `
    mutation UpdateBoard(
        $board: InputBoard
    ) {
        updateBoard(
            board: $board
        ) {
            boardId
            groupId
            category {
                categoryId
            }
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            title
            description
            content
            type
            status
            isDelete
            boardEnv {
                envId
                isDisplay
                displayStartDate
                displayEndDate
            }
            boardImages {
                boardImageId
                image {
                    imageId
                    realName
                }
                isDefault
                createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

interface IProps extends RouteComponentProps {
    category: string
    boardId: number
}

interface IState {
    toasters: IToastProps[]
}

class ModifyBoard extends React.Component<IProps, IState> {
    private toaster: Toaster;

    private refToasterHandler = {
        toaster: (ref: Toaster) => this.toaster = ref
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            toasters: []
        }
    }

    public render() {
        if(isBlockedUser()) {
            redirectRoot();
        }

        const { category, boardId } = this.props.match.params as IProps;

        if(!category) {
            return <ErrorPage message="Invalid request path" />;
        }

        if(!boardId) {
            return <ErrorPage />;
        }

        const newBoardId = Number(boardId);

        return (
            <div className="default-grid-container add-board-container">
                <div className="pad-h-1">
                    <div className="header-wrapper-2">
                        <H5 className="title">Modify {category}</H5>
                    </div>
                </div>
                <Query
                    query={BOARD_QUERY}
                    variables={{ boardId: newBoardId }}
                    fetchPolicy="cache-and-network"
                >
                    {({loading, error, data, networkStatus}: any) => {
                        if(loading) {
                            return <LoadingPage />;
                        }

                        if(error) {
                            return <ErrorPage />;
                        }

                        const { board } = data || { board: null };

                        return (
                            <Mutation
                                mutation={UPDATE_BOARD_QUERY}
                                update={(cache: any, {data: {updateBoard}}: any) => {
                                    const tempBoard = cache.readQuery({
                                        query: BOARD_QUERY,
                                        variables: { boardId: updateBoard.boardId }
                                    });

                                    if(tempBoard) {
                                        cache.writeQuery({
                                            query: BOARD_QUERY,
                                            variables: {boardId: updateBoard.boardId},
                                            data: { board: updateBoard}
                                        });
                                    }
                                }}
                                onCompleted={this.onCompleteSave}
                            >
                                {(saveBoard: any) => (
                                <EditBoard
                                    isTemporary={false}
                                    category={category}
                                    board={board}
                                    boardId={newBoardId}
                                    saveHandler={saveBoard}
                                    isEnableAutoSave={isEnableAutoSave}
                                    history={this.props.history}
                                    location={this.props.location}
                                    match={this.props.match}
                                />
                                )}
                            </Mutation>
                        );
                    }}
                </Query>
                <Toaster position={Position.BOTTOM} ref={this.refToasterHandler.toaster}>
                    {
                        this.state.toasters.map((toast, idx) => {
                            return <Toast key={idx} message={toast.message} />;
                        })
                    }
                </Toaster>
            </div>
        );
    }

    private onCompleteSave = (data: any) => {
        this.toaster.show({message: "Success"});

        const { category, boardId } = this.props.match.params as IProps;

        if(data.updateBoard.status === BoardStatus.TEMPORARY) {
            redirectCustom("/temporaryBoard/view/" + category + "/" + boardId);
        }
        else {
            redirectCustom("/board/view/" + category + "/" + boardId);
        }
    }
}

export default ModifyBoard;