import * as React from "react";
import {FACEBOOK_AUTH_URL, GOOGLE_AUTH_URL} from "../common/Constant";
import LoginForm from "../auth/LoginForm";
import {
    Button, H5,
    Menu,
    MenuDivider,
    MenuItem,
    Popover,
    PopoverInteractionKind,
    PopoverPosition
} from "@blueprintjs/core";
import {Link} from "react-router-dom";

import "./UnSignupUserMenu.css";
import {Query} from "react-apollo";
import {IUser} from "../common/Domains";
import {isAdminUser, isBlockedUser, resetUserInfo, setUserInfo} from "../common/UserInfo";
import {Profile, ProfileType} from "../user/Profile";
import {GET_CURRENT_USER} from "../user/view/MyProfile";
import {resetToken} from "../../store/cache/Token";
import {redirectLogin} from "../utils/PageFolwUtils";
import {LoadingPage} from "../utils/LoadingAndError";

export const UnSignupUserMenu = () => {
    return (
        <div className="user-menu-container">
            <div className="header-wrapper-2">
                <H5 className="title">Sign in</H5>
            </div>
            <div className="pad-h-1 pad-w-1-half">
                <SocialLogin />
            </div>
            <Menu>
                <MenuDivider/>
                <div className="pad-h-1 pad-w-1">
                    <LoginForm />
                </div>
                <MenuDivider/>
                <div className="group-center">
                    <Link to="/signup"><Button minimal={true}>계정이 없으신가요? 계정 만들기</Button></Link>
                </div>
            </Menu>
        </div>
    );
};

export const SignupUserMenu = () => {
    return (
        <Query
            query={GET_CURRENT_USER}
            fetchPolicy="network-only"
        >
            {({loading, error, data}: any) => {
                if(loading) {
                    return <LoadingPage isOnlyMessage={true} />;
                }

                if(!data || !data.currentUser) {
                    resetUserInfo();
                    resetToken();

                    return (
                        <Popover
                            interactionKind={PopoverInteractionKind.CLICK}
                            position={PopoverPosition.BOTTOM}
                            popoverClassName="bp3-popover-content-sizing"
                            // isOpen={isOpenUserInfo}
                        >
                            <Button minimal={true}>Sign in</Button>
                            <div>
                                <UnSignupUserMenu />
                            </div>
                        </Popover>
                    );
                }

                const user = data.currentUser as IUser;

                setUserInfo(user);

                const isBlocked = isBlockedUser();

                return (
                    <Popover
                        interactionKind={PopoverInteractionKind.CLICK}
                        position={PopoverPosition.BOTTOM}
                        popoverClassName="bp3-popover-content-sizing"
                        // isOpen={isOpenUserInfo}
                    >
                        <Button minimal={true}>{user.displayName}</Button>
                        {/*<Button minimal={true} onClick={() => {*/}
                        {/*    isOpenUserInfoHandler(!isOpenUserInfo);*/}
                        {/*}}>{user.displayName}</Button>*/}
                        <div className="user-menu-container">
                            <Menu>
                                <Profile user={user} profileType={ProfileType.DEFAULT} large={false} descriptionLength={10} />
                                { isAdminUser() && (
                                    <>
                                        <MenuDivider title="Draft" />
                                        <MenuItem icon="chevron-right" text="News" href={"/temporaryBoard/news"} disabled={isBlocked} />
                                        <MenuItem icon="chevron-right" text="Product" href={"/temporaryItems"} disabled={isBlocked} />
                                        <MenuItem icon="chevron-right" text="Shop" href={"/temporaryShops"} disabled={isBlocked} />
                                        <MenuDivider title="New" />
                                        <MenuItem icon="chevron-right" text="News" href={"/board/add/news"} disabled={isBlocked} />
                                        <MenuItem icon="chevron-right" text="Product" href={"/newItem"} disabled={isBlocked} />
                                        <MenuItem icon="chevron-right" text="Shop" href={"/newShop"} disabled={false} />
                                    </>
                                )}
                                <MenuDivider />
                                <MenuItem href={"/"} onClick={handlerLogout} text="Sign out" />
                            </Menu>
                        </div>
                    </Popover>
                );
            }}
        </Query>
    );
};

const SocialLogin = () => {
    return (
        <div className="group-center">
            <a href={FACEBOOK_AUTH_URL}>
                <Button className="button-s-1" minimal={true} large={false} fill={true}>Facebook으로 로그인하기</Button>
            </a>
            <br />
            <a href={GOOGLE_AUTH_URL}>
                <Button className="button-s-1" minimal={true} large={false} fill={true}>Google로 로그인하기</Button>
            </a>
        </div>
    );
};

const handlerLogout = () => {
    resetUserInfo();
    resetToken();

    redirectLogin();
};

