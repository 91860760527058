import * as React from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import {Button, H4} from "@blueprintjs/core";
import "./ItemHome.css";
import 'pure-react-carousel/dist/react-carousel.es.css';

import {CardType, ICategoryInfo, IItem, RecommendType} from "../common/Domains";
import {SITE_TITLE} from "../common/Constant";
import {Helmet} from "react-helmet";
import {NewRecommand} from "./view/NewRecommand";

interface IItemProps extends RouteComponentProps {
    id: number,
    item: IItem
}

class ItemHome extends React.Component<IItemProps> {
    constructor(props: IItemProps) {
        super(props);

    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public render() {
        return (
            <div className="item-home-container">
                <Helmet>
                    <title>{"New In, " + SITE_TITLE }</title>
                    <link rel="canonical" href={ window.location.href } />
                    <meta name="description" content={"New in"} />
                    <meta property="og:url" content={ window.location.href } />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={"New In, " + SITE_TITLE } />
                    <meta property="og:description" content={"New In"} />
                </Helmet>
                <HeaderWrapper />
                <div className="grid-container">
                    <div className="default-grid-container pad-t-3">
                        <NewRecommand recommendType={RecommendType.STAFF} categoryInfo={{} as ICategoryInfo} cardType={CardType.SMALL_VERTICAL_BOX} width={23} dataLimit={20} descriptionLine={4} interactive={false} />
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container pad-h-3">
                        <div className="topic-container">
                            <div className="group-center">
                                <Link to="/items/tableware"><Button className="button-s-2" minimal={true} large={true}> 더 많은 제품 둘러보기</Button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ItemHome;

const HeaderWrapper = () => {
    return (
        <>
            <div className="grid-container">
                <div className="default-grid-container">
                    <div className="header-wrapper-1 group-center pad-t-3">
                        <H4 className="title capitalize">New In</H4>
                        <div className="group-center">우리가 보여드리는 새로운 제품을 만나보세요.</div>
                    </div>
                </div>
            </div>
        </>
    );
}