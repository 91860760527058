import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Button, ControlGroup, FormGroup, H5, Icon, InputGroup, Radio, RadioGroup, Switch} from "@blueprintjs/core";
import ReactQuill, {Quill} from "react-quill";

import ImageResize from "quill-image-resize-module-react";
import {ImageUpload} from "quill-image-upload";
import {ImageDrop} from 'quill-image-drop-module';
import VideoResize from 'quill-video-resize-module-react';
import {editorFormats, editorModules} from "../editor/EditorOptions";

import "node_modules/react-quill/dist/quill.snow.css";
import "../../common/css/quill-editor.css";
import "./AddShop.css";
import {Query} from "react-apollo";
import {IShop, SHOP_CATEGORIES, ShopCategory, ShopStatus, ShopType} from "../common/Domains";
import {EDIT_DEFAULT_SHOP_CONTENT} from "../common/Constant";
import {Link, RouteComponentProps} from "react-router-dom";
import {getUserInfo} from "../common/UserInfo";
import {Client} from "../../store/ApolloStore";
import ImageLibrary, {SHOP_IMAGES_IN_LIBRARY_QUERY} from "./image/ImageLibrary";
import {address} from "ip";
import {useForm} from "react-hook-form";
import CountrySelection, {COUNTRY_ITEMS, ICountry} from "../utils/CountrySelection";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";

Quill.register("modules/imageUpload", ImageUpload);
Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/videoResize', VideoResize);

interface IUserFormData {
    categoryId: number
    name: string
    title: string
    korName: string
    engName: string
    description: string
    content: string
    country: string
    location: string
    latitude: string
    longitude: string
    weather: string
    openingTime: string
    closingTime: string
    telNumber:string
    homePageUrl: string
    keywords: string
}

interface IProps extends RouteComponentProps {
    isTemporary: boolean
    shopId?: number
    shop: IShop
    saveHandler: any
    isEnableAutoSave: boolean
}

export const EditShop = (props: IProps) => {
    const quill: any = useRef();

    const {isTemporary, shopId, shop} = props;

    const currentUser = getUserInfo();
    const newShopId = shopId || -1;

    const [category, setCategory] = useState(shop.category.categoryId || ShopCategory.ITEM);
    const [country, setCountry] = useState(shop.shopEnv.country || COUNTRY_ITEMS[115].title);
    const [content, setContent] = useState(shop.content || EDIT_DEFAULT_SHOP_CONTENT);
    const [status, setStatus] = useState(ShopStatus.FINISHED);
    const [isPublished, setPublished] = useState(false);

    const {register, handleSubmit, errors, setValue} = useForm<IUserFormData>({
        defaultValues: {
            country,
            content
        }
    });

    const goBackHandler = () => {
        props.history.back();
    };

    const submitHandler = (formData: any) => {
        if (!isPublished) {
            setPublished(true);

            saveHandlerAction(newShopId, currentUser.no, formData, status, props.saveHandler);
        }
    };

    useEffect(() => {
        register({name: "country"});
        register({name: "content"});
    });

    return (
        <>
            <form onSubmit={handleSubmit(submitHandler)}>
                <div>
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="name"
                        label="상점명을 입력해주세요."
                    >
                        <InputGroup name="name"
                                    className="input-name"
                                    placeholder="상점명을 입력해주세요."
                                    large={false}
                                    inputRef={register({required: true, minLength: 1, maxLength: 200})}
                                    defaultValue={shop.name}
                        />
                        <div className="valid-error-wrapper">
                            {errors.name && errors.name.type === "required" && (
                                <p className="valid-error">상점명을 입력해주세요.</p>
                            )}
                            {errors.name && errors.name.type === "minLength" && (
                                <p className="valid-error">상점명을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.name && errors.name.type === "maxLength" && (
                                <p className="valid-error">상점명을 200자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="description"
                        label="간단한 설명을 입력해주세요."
                    >
                        <InputGroup name="description"
                                    className="input-description"
                                    placeholder="간단한 설명을 입력해주세요"
                                    inputRef={register({required: true, minLength: 1, maxLength: 200})}
                                    defaultValue={shop.description}
                        />
                        <div className="valid-error-wrapper">
                            {errors.description && errors.description.type === "required" && (
                                <p className="valid-error">설명을 입력해주세요.</p>
                            )}
                            {errors.description && errors.description.type === "minLength" && (
                                <p className="valid-error">설명을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.description && errors.description.type === "maxLength" && (
                                <p className="valid-error">설명을 200자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <RadioGroup
                        className="user-input-wrapper"
                        label="상점이 있는 곳의 카테고리를 선택해주세요."
                        name="categoryId"
                        inline={false}
                        onChange={(event: React.FormEvent<HTMLInputElement>) => {
                            setCategory(Number(event.currentTarget.value));

                            setValue("categoryId", event.currentTarget.value);
                        }}
                        selectedValue={category}
                    >
                        {SHOP_CATEGORIES.map((categoryItem) => {
                            return <Radio key={categoryItem.categoryId} label={categoryItem.name}
                                          value={categoryItem.categoryId} inputRef={register}/>
                        })}
                    </RadioGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="environment"
                        label="상점의 환경을 알려주세요."
                    >
                        <div className="add-environment">
                            <div className="info-box">
                                <H5 className="title">국가(Country)</H5>
                                <div className="value">
                                    <FormGroup
                                        labelFor="country-input"
                                    >
                                        <CountrySelection
                                            items={COUNTRY_ITEMS}
                                            country={{id: country, title: country}}
                                            hasInitialContent={true}
                                            valueChangeHandler={(countryItem: ICountry) => {
                                                setCountry(countryItem.title);

                                                setValue("country", countryItem.title);
                                            }}
                                            // inputRef={register({ required: true, minLength: 1, maxLength: 100 })}
                                            // defaultValue={garden.gardenEnv.country}
                                        />
                                        <div className="valid-error-wrapper">
                                            {errors.country && errors.country.type === "required" && (
                                                <p className="valid-error">국가을 입력해주세요.</p>
                                            )}
                                            {errors.country && errors.country.type === "minLength" && (
                                                <p className="valid-error">국가을 1자 이상 입력해주세요.</p>
                                            )}
                                            {errors.country && errors.country.type === "maxLength" && (
                                                <p className="valid-error">국가을 100자 이하 입력해주세요.</p>
                                            )}
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="info-box">
                                <H5 className="title">위치(Location)</H5>
                                <div className="value">
                                    <FormGroup
                                        labelFor="location-input"
                                    >
                                        <InputGroup name="location"
                                                    placeholder="위치을 알려주세요."
                                                    inputRef={register({required: true, minLength: 1, maxLength: 50})}
                                                    defaultValue={shop.shopEnv.location}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="info-box">
                                <H5 className="title">위경도(Latitude/Longitude)</H5>
                                <div className="value">
                                    <ControlGroup
                                        fill={false}
                                    >
                                        <InputGroup name="latitude"
                                                    placeholder="위도을 알려주세요."
                                                    inputRef={register({
                                                        required: true,
                                                        minLength: 6,
                                                        maxLength: 20,
                                                        pattern: /^(\-?\d+(\.\d+)?)$/i
                                                    })}
                                                    defaultValue={shop.shopEnv.latitude}
                                        />
                                        <InputGroup name="longitude"
                                                    placeholder="경도을 알려주세요."
                                                    inputRef={register({
                                                        required: true,
                                                        minLength: 6,
                                                        maxLength: 20,
                                                        pattern: /^(\-?\d+(\.\d+)?)$/i
                                                    })}
                                                    defaultValue={shop.shopEnv.longitude}
                                        />
                                    </ControlGroup>
                                    <p className="input-description">
                                        <a href="https://www.google.co.kr/maps" target="_blank">위경도
                                            찾기(https://www.google.co.kr/maps)<Icon icon="link"/></a><br/>
                                        ex)51.4787471 / -0.2977617
                                    </p>
                                    <div className="valid-error-wrapper">
                                        {errors.latitude && errors.latitude.type === "required" && (
                                            <p className="valid-error">위도를 입력해주세요.</p>
                                        )}
                                        {errors.latitude && errors.latitude.type === "minLength" && (
                                            <p className="valid-error">위도를 6자 이상 입력해주세요.</p>
                                        )}
                                        {errors.latitude && errors.latitude.type === "maxLength" && (
                                            <p className="valid-error">위도를 20자 이하 입력해주세요.</p>
                                        )}
                                        {errors.latitude && errors.latitude.type === "pattern" && (
                                            <p className="valid-error">위도를 형식에 맞게 입력해주세요.</p>
                                        )}
                                        {errors.longitude && errors.longitude.type === "required" && (
                                            <p className="valid-error">경도를 입력해주세요.</p>
                                        )}
                                        {errors.longitude && errors.longitude.type === "minLength" && (
                                            <p className="valid-error">경도를 6자 이상 입력해주세요.</p>
                                        )}
                                        {errors.longitude && errors.longitude.type === "maxLength" && (
                                            <p className="valid-error">경도를 20자 이하 입력해주세요.</p>
                                        )}
                                        {errors.longitude && errors.longitude.type === "pattern" && (
                                            <p className="valid-error">경도를 형식에 맞게 입력해주세요.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="info-box">
                                <H5 className="title">영업 유무</H5>
                                <div className="value">
                                    <FormGroup
                                        labelFor="is-closed-input"
                                    >
                                        <Switch label="현재도 영업 중 인가요?"
                                                inputRef={register}
                                                defaultChecked={shop.shopEnv.isClosed}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="info-box">
                                <H5 className="title">운영시간(Opening time)</H5>
                                <div className="value">
                                    <FormGroup
                                        labelFor="opening-time"
                                    >
                                        <ControlGroup
                                            fill={false}
                                        >
                                            <InputGroup name="openingTime"
                                                        placeholder="시작 시간을 알려주세요."
                                                        inputRef={register({
                                                            required: true,
                                                            minLength: 3,
                                                            maxLength: 50
                                                        })}
                                                        defaultValue={shop.shopEnv.openingTime}
                                            />
                                            <InputGroup name="closingTime"
                                                        placeholder="종료 시간을 알려주세요."
                                                        inputRef={register({
                                                            required: true,
                                                            minLength: 3,
                                                            maxLength: 50
                                                        })}
                                                        defaultValue={shop.shopEnv.closingTime}
                                            />
                                        </ControlGroup>
                                        <div className="valid-error-wrapper">
                                            {errors.openingTime && errors.openingTime.type === "required" && (
                                                <p className="valid-error">시작 시간을 입력해주세요.</p>
                                            )}
                                            {errors.openingTime && errors.openingTime.type === "minLength" && (
                                                <p className="valid-error">시작 시간을 3자 이상 입력해주세요.</p>
                                            )}
                                            {errors.openingTime && errors.openingTime.type === "maxLength" && (
                                                <p className="valid-error">시작 시간을 50자 이하 입력해주세요.</p>
                                            )}
                                            {errors.closingTime && errors.closingTime.type === "required" && (
                                                <p className="valid-error">종료 시간을 입력해주세요.</p>
                                            )}
                                            {errors.closingTime && errors.closingTime.type === "minLength" && (
                                                <p className="valid-error">종료 시간을 3자 이상 입력해주세요.</p>
                                            )}
                                            {errors.closingTime && errors.closingTime.type === "maxLength" && (
                                                <p className="valid-error">종료 시간을 50자 이하 입력해주세요.</p>
                                            )}
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="info-box">
                                <H5 className="title">웹사이트(Home page)</H5>
                                <div className="value">
                                    <FormGroup
                                        labelFor="homePageUrl"
                                    >
                                        <InputGroup name="homePageUrl"
                                                    placeholder="웹사이트를 알려주세요."
                                                    inputRef={register({required: true, minLength: 3, maxLength: 300})}
                                                    defaultValue={shop.shopEnv.homePageUrl}
                                        />
                                        <div className="valid-error-wrapper">
                                            {errors.homePageUrl && errors.homePageUrl.type === "required" && (
                                                <p className="valid-error">웹사이트를 입력해주세요.</p>
                                            )}
                                            {errors.homePageUrl && errors.homePageUrl.type === "minLength" && (
                                                <p className="valid-error">웹사이트를 3자 이상 입력해주세요.</p>
                                            )}
                                            {errors.homePageUrl && errors.homePageUrl.type === "maxLength" && (
                                                <p className="valid-error">웹사이트를 300자 이하 입력해주세요.</p>
                                            )}
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="info-box">
                                <H5 className="title">전화번호</H5>
                                <div className="value">
                                    <FormGroup
                                        labelFor="tel-number"
                                    >
                                        <InputGroup name="telNumber"
                                                    placeholder="전화번호를 입력해주세요."
                                                    inputRef={register({required: true, minLength: 10, maxLength: 13})}
                                                    defaultValue={shop.shopEnv.telNumber}
                                        />
                                        <div className="valid-error-wrapper">
                                            {errors.telNumber && errors.telNumber.type === "required" && (
                                                <p className="valid-error">전화번호를 입력해주세요.</p>
                                            )}
                                            {errors.telNumber && errors.telNumber.type === "minLength" && (
                                                <p className="valid-error">전화번호를 10자 이상 입력해주세요.</p>
                                            )}
                                            {errors.telNumber && errors.telNumber.type === "maxLength" && (
                                                <p className="valid-error">전화번호를 13자 이하 입력해주세요.</p>
                                            )}
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="info-box">
                                <H5 className="title">키워드(Keywords)</H5>
                                <div className="value">
                                    <FormGroup
                                        labelFor="keywords"
                                    >
                                        <InputGroup name="keywords"
                                                    placeholder="키워드를 알려주세요."
                                                    inputRef={register({required: true, minLength: 1, maxLength: 300})}
                                                    defaultValue={shop.shopEnv.keywords}
                                        />
                                        <div className="valid-error-wrapper">
                                            {errors.keywords && errors.keywords.type === "required" && (
                                                <p className="valid-error">키워드를 입력해주세요.</p>
                                            )}
                                            {errors.keywords && errors.keywords.type === "minLength" && (
                                                <p className="valid-error">키워드를 1자 이상 입력해주세요.</p>
                                            )}
                                            {errors.keywords && errors.keywords.type === "maxLength" && (
                                                <p className="valid-error">키워드를 300자 이하 입력해주세요.</p>
                                            )}
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="content"
                        label="상점의 이야기를 들려주세요."
                    >
                        <ReactQuill
                            ref={(el) => {
                                quill.current = el
                            }}
                            theme="snow"
                            modules={editorModules}
                            formats={editorFormats}
                            value={content}
                            onChange={(value: string) => {
                                setContent(value);

                                setValue("content", value);
                            }}
                        />
                    </FormGroup>
                </div>

                <div className="group-center pad-w-1 pad-h-3">
                    <Button minimal={true} onClick={goBackHandler}>Cancel</Button>
                    <Link to={isTemporary ? "/temporaryShops" : "/shops"}>
                        <Button minimal={true}>List</Button>
                    </Link>
                    <Button type="submit" minimal={true} onClick={() => { setStatus(ShopStatus.TEMPORARY) }}>Save</Button>
                    <Button type="submit" className="button-s-1" minimal={true} onClick={() => { setStatus(ShopStatus.FINISHED) }}>Publish</Button>
                </div>
            </form>
            <div className="pad-w-1 pad-h-1">
                <Query
                    query={SHOP_IMAGES_IN_LIBRARY_QUERY}
                    variables={{
                        shopId: newShopId
                    }}
                    fetchPolicy="cache-and-network"
                >
                    {({loading, error, data}: any) => {

                        if (loading) {
                            return <LoadingPage/>;
                        }

                        if (error) {
                            return <ErrorPage/>;
                        }

                        const {shopImages} = data || { shopImages: null };

                        return (
                            <ImageLibrary editorRef={quill.current} userNo={currentUser.no} shopId={newShopId}
                                          shopImages={shopImages || []}/>
                        );
                    }}
                </Query>
            </div>
        </>
    );
}

export default EditShop;

const saveHandlerAction = (shopId: number, userNo: number, formData: any, status: ShopStatus, saveHandler: any) => {
    const shopImageCache = Client.readQuery({
        query: SHOP_IMAGES_IN_LIBRARY_QUERY,
        variables: {
            shopId
        }
    });

    const shopImages = (shopImageCache.shopImages || []).map((shopImage: any) => {
        const tempImage = {...shopImage.image};
        const tempShop = {...shopImage.shop};
        const tempShopImage = {...shopImage};

        tempShopImage.image = tempImage;
        tempShopImage.shop = tempShop;

        tempShopImage.shopImageId = 0;
        tempShopImage.createdDate = null;
        tempShopImage.image.createdDate = null;

        delete tempShopImage.image.__typename;
        delete tempShopImage.shop.__typename;
        delete tempShopImage.shop.author.__typename;
        delete tempShopImage.__typename;

        return tempShopImage;
    });

    const {
        groupId,
        name,
        description,
        categoryId,
        content,
        // type,
        // status,
        location,
        latitude,
        longitude,
        country,
        isClosed,
        openingTime,
        closingTime,
        homePageUrl,
        telNumber,
        keywords
    } = formData;

    if(name && content) {
        const authorIp = address();

        saveHandler({
            variables: {
                shop: {
                    shopId,
                    groupId,
                    category: {
                        categoryId
                    },
                    author: {
                        no: userNo
                    },
                    authorIp,
                    name,
                    description,
                    content,
                    type: ShopType.UNKNOWN,
                    status,
                    shopEnv: {
                        location,
                        latitude,
                        longitude,
                        country,
                        isClosed,
                        openingTime,
                        closingTime,
                        homePageUrl,
                        telNumber,
                        keywords
                    },
                    shopImages
                }
            }
        });
    }

    // this.updateAutoSaveTimeStatus();
};