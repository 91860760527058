import * as React from "react";
import {NonIdealState} from "@blueprintjs/core";
import {CardAlign, getPreviewImageIds, ICardData, Preview} from "./CardDomains";
import {Box} from "grommet";

import './CoverHBox.css';
import './SmallHBox.css';
import './ItemHBox.css';
import {THUMBNAIL_SIZES} from "../common/Constant";

interface ICardProps {
    data: ICardData
    large?: boolean
    cover?: boolean
    width?: number
    height?: number
    align: CardAlign
    isEmpty?: boolean
}

interface ICardState {
    data: ICardData
    large: boolean
    cover: boolean
}

class ItemHBox extends React.Component<ICardProps, ICardState> {
    constructor(props: ICardProps) {
        super(props);

        this.state = {
            data: props.data || {},
            large: props.large || false,
            cover: props.cover || false
        }
    }

    public render() {
        const {
            data,
            large,
            cover
        } = this.state;

        const {
            isEmpty,
            width,
            align
        } = this.props;

        if(isEmpty) {
            return <EmptyItemHBox data={data} large={large} align={align} width={width} />;
        }

        const images = getPreviewImageIds(data.images);

        return (
            <div className={cover ? "cover-box" : large ? 'item-h-box' : 'small-h-box'}>
                <div className={"img " + " img-" + align}>
                    <Box className="box">
                        {!large && <Preview images={images} width={THUMBNAIL_SIZES.BASIC.WIDTH} height={THUMBNAIL_SIZES.BASIC.HEIGHT} /> }
                        {large && <Preview images={images} width={THUMBNAIL_SIZES.MIDDLE.WIDTH} height={THUMBNAIL_SIZES.MIDDLE.HEIGHT} /> }
                    </Box>
                </div>
                <div className={ "summary" + " summary-" + align }>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const EmptyItemHBox = ({data, large, width}: ICardProps) => {
    return (
        <div className={large ? 'item-h-box' : 'small-h-box'}>
            <NonIdealState
                title={data.title}
                description={data.description}
            />
        </div>
    );
}

export default ItemHBox;