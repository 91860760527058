import * as React from "react";
import {IBoardComment} from "../../../../common/Domains";
import {Button, ButtonGroup} from "@blueprintjs/core";
import {gql} from "apollo-boost";
import {Query} from "react-apollo";
import {getUserInfo} from "../../../../common/UserInfo";
import {useState} from "react";
import {distanceInWordsToNow} from "date-fns";
import EditableComment, {AddComment, DeleteButton, ModifyButton} from "./EditableComment";

import "../../../../common/css/Comments.css";
import {Profile, ProfileType} from "../../../../user/Profile";
import {ErrorPage} from "../../../../utils/LoadingAndError";

export const BOARD_COMMENTS_QUERY = gql `
    query BoardComments(
        $boardId: Int,
        $offset: Int,
        $limit: Int
    ) {
        boardComments(
            boardId: $boardId,
            offset: $offset,
            limit: $limit
        ) {
            commentId
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            content
            commentType
            board {
                boardId
            }
        }
    }
`;

interface ICommentsProps {
    boardId: number
}

class Comments extends React.Component<ICommentsProps, {}> {
    public render() {
        const { boardId } = this.props;

        return (
            <div className="comments-wrapper">
                <Query
                    query={BOARD_COMMENTS_QUERY}
                    variables={{
                        boardId,
                        offset: 0,
                        limit: 10
                    }}
                    fetchPolicy="cache-and-network"
                >
                    {({ loading, error, data, fetchMore } : any) => {
                        if(error) {
                            return <ErrorPage />;
                        }

                        const { boardComments } = data || { boardComments: null };
                        const offset = boardComments ? boardComments.length : 0;

                        return (
                            <>
                                <Comment boardComments={boardComments} boardId={boardId} />
                                { offset > 0 &&
                                    <LoadMore fetchMore={fetchMore} offset={offset} />
                                }
                            </>
                        );
                    }}
                </Query>
            </div>
        );
    }
}

const LoadMore = ({ fetchMore, offset }: any) => {
    const loadMoreHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        fetchMore({
            variables: {
                offset
            },
            updateQuery: (prev: any, {fetchMoreResult}: any) => {
                if(!fetchMoreResult) {
                    return prev;
                }

                return Object.assign({}, {
                    boardComments: [...prev.boardComments, ...fetchMoreResult.boardComments]
                });
            }
        });
    };

    return (
        <div className="group-center pad-h-3">
            <Button minimal={true} onClick={loadMoreHandler} >Load more</Button>
        </div>
    );
};

export default Comments;

interface ICommentProps {
    boardComments: IBoardComment[]
    boardId: number
}

const Comment = (props: ICommentProps) => {
    const { boardComments, boardId } = props;

    return (
        <div className="comments-container">
            <div className="add-comment-wrapper">
                <AddComment boardId={boardId} />
            </div>
            <div className="comment-item-list">
                <ul>
                    { (boardComments || []).map((boardComment: IBoardComment) => {
                        return (
                            <CommentWrapper key={boardComment.commentId} boardComment={boardComment} />
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

interface ICommentWrapper {
    boardComment: IBoardComment
}

const CommentWrapper = (props: ICommentWrapper) => {
    const { boardComment } = props;

    const currentUser = getUserInfo();

    const distanceCreatedDate = distanceInWordsToNow(boardComment.createdDate);
    const isMyComment = currentUser.no === boardComment.author.no;

    const [isEditableComment, setIsEditableComment] = useState(false);

    const onChangeModify = (isEnable: boolean) => {
        setIsEditableComment(isEnable);
    };

    return (
        <li>
            <div className="comment-item-container">
                <Profile user={boardComment.author}
                         profileType={ProfileType.SIMPLE}
                         large={false} isForceUserDetail={true} disableDescription={true}
                >
                    <div className="date">{distanceCreatedDate}</div>
                    { isMyComment && <EditableComment commentId={boardComment.commentId} content={boardComment.content} userNo={currentUser.no} isEditable={isEditableComment} onChangeEditable={setIsEditableComment} /> }
                    { !isMyComment && <EditableComment commentId={boardComment.commentId} content={boardComment.content} userNo={currentUser.no} isEditable={false} /> }
                    <div className="operations">
                        {!isEditableComment && <ButtonGroup minimal={true}>
                            { isMyComment && <ModifyButton commentId={boardComment.commentId} boardId={boardComment.board.boardId} changeModifyState={onChangeModify} /> }
                            { isMyComment && <DeleteButton commentId={boardComment.commentId} boardId={boardComment.board.boardId} /> }
                        </ButtonGroup>}
                    </div>
                </Profile>
            </div>
        </li>
    );
};