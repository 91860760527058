import * as React from "react";
import {H5} from "@blueprintjs/core";

import "./ShoppingGuide.css";

export const ShoppingGuide = () => {
    return (
        <div className="shopping-guide pad-t-3">
            <div className="main-title pad-b-1">Shopping Guide</div>
            <div className="guide-box">
                <H5 className="title">결제안내</H5>
                <p className="value">
                    결제는 네이버 스마트스토어를 통해 결제해주셔야합니다.<br />
                    현 사이트에서는 구매하실 수 없으며, 제품 상세정보만 제공하고 있습니다.
                </p>
            </div>
            <div className="guide-box">
                <H5 className="title">배송안내</H5>
                <p className="value">
                    배송은 전국 택배를 기본으로하며, 배송비는 제품에 따라 다르나 3,000원을 기본으로 하고 있습니다.<br />
                    도서 산간의 경우 추가 택배비를 지불하셔야 합니다.
                    배송기간은 1~4일이며, 지연될 경우 따로 안내드리고 있습니다.
                </p>
            </div>
            <div className="guide-box">
                <H5 className="title">교환/반품</H5>
                <p className="value">
                    상품을 수령 후 7일 이내 교환 및 반품 가능하며, 상품이 훼손하거나 상품가치가 상실된 경우에는 교환 및 반품이 불가능합니다.
                    교환 및 반품 시 배송된 구성품 그대로 재포장하여, 2일 이내에 회수(택배사 전달)되어야 됩니다.
                    자세한 내용은 상세페이지나 문자, e-mail을 통해 상담 문의 주시기 바랍니다.
                </p>
            </div>
        </div>
    );
}

