import * as React from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {Button, H3, H5, Intent, Popover, PopoverInteractionKind, Tab, TabId, Tabs} from "@blueprintjs/core";
import {distanceInWordsToNow} from "date-fns";
import {Helmet} from 'react-helmet';
import * as numeral from "numeral";
import "./Board.css";
import Summary from "./view/sub/summary/Summary";
import Comments from "./view/sub/comment/Comments";
import Environment from "./Environment";
import {gql} from "apollo-boost";
import {Mutation, Query} from "react-apollo";
import {Profile, ProfileType} from "../user/Profile";
import {ToggleBoardLikeWrapper} from "./Like";
import {getUserInfo, isBlockedUser} from "../common/UserInfo";
import {HIT_COUNTER_FORMAT, HOST_BASE_URL, SITE_TITLE} from "../common/Constant";
import {SharePopover} from "../share/SharePopover";
import {UserReport} from "../report/UserReport";
import {UserReportTargetType} from "../common/Domains";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";

export const BOARD_QUERY = gql `
    query Board($boardId: Int) {
        board(boardId: $boardId) {
            boardId
            groupId
            category {
                categoryId
            }
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            title
            description
            content
            type
            status
            isDelete
            boardEnv {
                envId
                isDisplay
                displayStartDate
                displayEndDate
            }
            boardImages {
               boardImageId
               image {
                   imageId
                   realName
               }
               isDefault
               createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

export const DELETE_BOARD_QUERY = gql `
    mutation DeleteBoard($board: InputBoard) {
        deleteBoard(board: $board) {
            boardId
        }
    }
`;

interface IBoardProps extends RouteComponentProps {
    category: string
    boardId: number
}

interface IBoardState {
    selectedSubTabId: TabId
}

class Board extends React.Component<IBoardProps, IBoardState> {
    constructor(props: IBoardProps) {
        super(props);

        this.state = {
            selectedSubTabId: "comment"
        };

        this.goBackHandler = this.goBackHandler.bind(this);
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public render() {
        const { category, boardId } = this.props.match.params as IBoardProps;

        if(!boardId) {
            return <ErrorPage />;
        }

        const newBoardId = Number(boardId);

        return (
            <Query
                query={BOARD_QUERY}
                variables={{ boardId: newBoardId }}
                notifyOnNetworkStatusChange={true}
                fetchPolicy="cache-and-network"
            >
                {({loading, error, data, networkStatus}: any) => {
                    if(loading) {
                        return <LoadingPage />;
                    }

                    if(error) {
                        return <ErrorPage />;
                    }

                    const { board } = data || { board : null };

                    if(!board || board.boardId <= 0) {
                        return <ErrorPage />;
                    }

                    const imagePath = board.author.imageUrl;
                    const distanceCreatedDate = distanceInWordsToNow(board.createdDate);
                    const distanceModifiedDate = distanceInWordsToNow(board.modifiedDate);

                    return (
                        <div className="board-wrapper">
                            <Helmet>
                                <title>{ board.title + ", " + SITE_TITLE }</title>
                                <link rel="canonical" href={ window.location.href } />
                                <meta name="description" content={board.title} />
                                <meta property="og:url" content={ window.location.href } />
                                <meta property="og:type" content="website" />
                                <meta property="og:title" content={ board.title + ", " + SITE_TITLE } />
                                <meta property="og:description" content={board.description} />
                                <meta property="og:image" content={imagePath} />
                            </Helmet>
                            <div className="grid-container pad-t-3">
                                <div className="default-grid-container">
                                    <div className="header pad-h-1">
                                        <H3 className="title">
                                            {board.title}
                                        </H3>
                                        <div className="description">
                                            {board.description}
                                        </div>
                                        <div className="author-item-container pad-h-1">
                                            <Profile user={board.author}
                                                     profileType={ProfileType.SIMPLE}
                                                     large={false} isForceUserDetail={true} disableDescription={true}
                                            >
                                                    <span className="date" title="Created date">{distanceCreatedDate}</span>
                                                    {(distanceCreatedDate !== distanceModifiedDate) && (
                                                        <span className="date" title="Modified date">,&nbsp;{distanceModifiedDate}</span>
                                                    )}
                                                    <span className="divider"> / </span>
                                                    <span className="counter" title="View Count">{numeral(board.counter.count).format(HIT_COUNTER_FORMAT)}</span>
                                                    <span className="divider"> / </span>
                                                    <span className="like" title="Like">
                                                        <ToggleBoardLikeWrapper board={board} />
                                                    </span>
                                                    <span className="divider"> / </span>
                                                    <SharePopover url={HOST_BASE_URL + "/board/view/" + category + "/" +  boardId} title={board.title} />
                                                    <span className="divider"> / </span>
                                                    <UserReport targetType={UserReportTargetType.BOARD} targetCategoryId={board.category.categoryId} targetId={boardId} />
                                            </Profile>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container">
                                    <div className="pad-h-1 pad-w-1-half">
                                        <Environment env={board.boardEnv}/>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container" >
                                    <div className="pad-h-1 pad-w-1-half">
                                        <Summary content={board.content}>
                                            <UserActions category={category} boardId={board.boardId} userNo={board.author.no} />
                                        </Summary>

                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container border-top">
                                    <div className="pad-h-1">
                                        <Profile user={board.author} profileType={ProfileType.DEFAULT} large={false} isForceUserDetail={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container">
                                    <div className="sub-tab-wrapper pad-h-1 pad-w-1-half">
                                        <Tabs id="sub-info" large={true} animate={true} selectedTabId={this.state.selectedSubTabId}
                                              onChange={this.subInfoChanger}>
                                            <Tab id="comment" title={"리뷰"} panel={<Comments boardId={board.boardId} />} />
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    }

    public subInfoChanger = (selectedSubTabId: TabId) => {
       this.setState({
           selectedSubTabId
       });
    };

    private goBackHandler = () => {
        this.props.history.back();
    };
}

export default withRouter(Board);

interface IUserActions {
    category: string
    boardId: number
    userNo: number
}

const UserActions = (props: IUserActions) => {
    const currentUser = getUserInfo();

    const {category, boardId, userNo} = props;

    const isEnableAction = currentUser.no === userNo;

    if(isBlockedUser()) {
        return (
            <div>
                <div className="group-center pad-h-3">
                    { isEnableAction &&
                    <>
                        <Button minimal={true} disabled={true}>List</Button>
                        <Button minimal={true} disabled={true}>Modify</Button>
                        <Button minimal={true} disabled={true}>Delete</Button>
                    </>
                    }
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="group-center pad-h-3">
            { isEnableAction &&
            <>
                <Link to={"/board/" + category}>
                    <Button minimal={true}>List</Button>
                </Link>
                <Link to={"/board/update/" + category + "/" + boardId}>
                    <Button minimal={true}>Modify</Button>
                </Link>
                <DeletePopover boardId={boardId} userNo={userNo} />
            </>
            }
            </div>
        </div>
    );
};

interface IDeletePopover {
    boardId: number
    userNo: number
}

export const DeletePopover = (props: IDeletePopover) => {
    const {boardId, userNo } = props;

    const isDisabled = isBlockedUser();

    return (
        <Mutation
            mutation={DELETE_BOARD_QUERY}
            onCompleted={(data: any) => {
                // window.location.href = HOST_BASE_URL + "/boards";
                window.history.back();
            }}
        >
        {(deleteBoard: any) => (
            <Popover
                interactionKind={PopoverInteractionKind.CLICK}
                popoverClassName="bp3-popover-content-sizing"
            >
                <Button minimal={true} disabled={isDisabled}>Delete</Button>
                <div>
                    <H5>삭제</H5>
                    <p>삭제하시겠습니까?</p>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
                        <Button className="bp3-popover-dismiss" style={{ marginRight: 10 }}>
                            Cancel
                        </Button>
                        <Button intent={Intent.PRIMARY} className="bp3-popover-dismiss" disabled={isDisabled} onClick={() => {
                            deleteBoard({
                                variables: {
                                    board: {
                                        boardId,
                                        author: {
                                            no: userNo
                                        }
                                    }
                                }
                            });
                        }}>
                            Delete
                        </Button>
                    </div>
                </div>
            </Popover>
        )}
        </Mutation>
    );
};