import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Button, ControlGroup, FormGroup, InputGroup, Radio, RadioGroup, Switch} from "@blueprintjs/core";
import ReactQuill, {Quill} from "react-quill";

import ImageResize from "quill-image-resize-module-react";
import {ImageUpload} from "quill-image-upload";
import {ImageDrop} from 'quill-image-drop-module';
import VideoResize from 'quill-video-resize-module-react';
import {editorFormats, editorModules} from "../editor/EditorOptions";

import "node_modules/react-quill/dist/quill.snow.css";
import "../../common/css/quill-editor.css";
import "./AddBoard.css";
import {Query} from "react-apollo";
import {
    IBoard,
    BOARD_BOARD_TYPES,
    BoardStatus,
    BoardType,
} from "../common/Domains";
import {DATETIME_PATTERN, EDIT_DEFAULT_BOARD_CONTENT} from "../common/Constant";
import {Link, RouteComponentProps} from "react-router-dom";
import ImageLibrary, {BOARD_IMAGES_IN_LIBRARY_QUERY} from "./image/ImageLibrary";

import {getUserInfo} from "../common/UserInfo";

import {useForm} from "react-hook-form";
import {Client} from "../../store/ApolloStore";
import {address} from "ip";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";

Quill.register("modules/imageUpload", ImageUpload);
Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/videoResize', VideoResize);

interface IProps extends RouteComponentProps {
    isTemporary: boolean
    category: string
    boardId?: number
    board: IBoard
    saveHandler: any
    isEnableAutoSave: boolean
}

interface IUserFormData {
    title: string
    description: string
    content: string
    type: string
    displayStartDate: string
    displayEndDate: string
}

export const EditBoard = (props: IProps) => {
    const quill: any = useRef();

    const {isTemporary, category, boardId, board} = props;

    const currentUser = getUserInfo();
    const newBoardId = boardId || -1;

    const [boardType, setBoardType] = useState(board.type || BoardType.NONE);
    const [content, setContent] = useState(board.content || EDIT_DEFAULT_BOARD_CONTENT);
    const [status, setStatus] = useState(BoardStatus.FINISHED);
    const [isPublished, setPublished] = useState(false);

    const {register, handleSubmit, errors, setValue} = useForm<IUserFormData>({
        defaultValues: {
            type: BoardType.NONE,
            content
        }
    });

    const goBackHandler = () => {
        props.history.back();
    };

    const submitHandler = (formData: any) => {
        if (!isPublished) {
            setPublished(true);

            saveHandlerAction(newBoardId, board.category.categoryId, currentUser.no, formData, status, props.saveHandler);
        }
    };

    useEffect(() => {
        register({name: "content"});
    });

    return (
        <>
            <form onSubmit={handleSubmit(submitHandler)}>
                <div>
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="title"
                        label="제목을 입력해주세요."
                    >
                        <InputGroup name="title"
                                    className="input-title"
                                    placeholder="제목을 입력해주세요"
                                    inputRef={register({required: true, minLength: 1, maxLength: 200})}
                                    defaultValue={board.title}
                        />
                        <div className="valid-error-wrapper">
                            {errors.title && errors.title.type === "required" && (
                                <p className="valid-error">제목을 입력해주세요.</p>
                            )}
                            {errors.title && errors.title.type === "minLength" && (
                                <p className="valid-error">제목을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.title && errors.title.type === "maxLength" && (
                                <p className="valid-error">제목을 200자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="description"
                        label="간단한 설명을 입력해주세요."
                    >
                        <InputGroup name="description"
                                    className="input-description"
                                    placeholder="간단한 설명을 입력해주세요"
                                    inputRef={register({required: true, minLength: 10, maxLength: 200})}
                                    defaultValue={board.description}
                        />
                        <div className="valid-error-wrapper">
                            {errors.description && errors.description.type === "required" && (
                                <p className="valid-error">설명을 입력해주세요.</p>
                            )}
                            {errors.description && errors.description.type === "minLength" && (
                                <p className="valid-error">설명을 10자 이상 입력해주세요.</p>
                            )}
                            {errors.description && errors.description.type === "maxLength" && (
                                <p className="valid-error">설명을 200자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                {props.category === "news" &&
                <>
                    <div className="pad-t-2">
                        <RadioGroup
                            className="user-input-wrapper"
                            label="게시글 종류를 선택해주세요."
                            name="type"
                            inline={true}
                            onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                setBoardType(BoardType[event.currentTarget.value]);

                                setValue("type", event.currentTarget.value);
                            }}
                            selectedValue={boardType}
                        >
                            {BOARD_BOARD_TYPES.map((boardTypeItem) => {
                                return <Radio key={boardTypeItem.boardTypeId} label={boardTypeItem.name}
                                              value={boardTypeItem.boardTypeId} inputRef={register}/>
                            })}
                        </RadioGroup>
                    </div>
                    <div className="pad-t-2">
                        <FormGroup
                            className="user-input-wrapper"
                            labelFor="description"
                            label="메인에 노출 여부를 선택하세요."
                        >
                            <Switch name="isDisplay"
                                    label="메인에 노출함"
                                    inputRef={register}
                                    defaultChecked={board.boardEnv.isDisplay}
                            />
                        </FormGroup>
                    </div>
                    <div className="pad-t-2">
                        <FormGroup
                            className="user-input-wrapper"
                            labelFor="inputDisplayStartDate"
                            label="메인에 노출할 기간을 입력하세요."
                        >
                            <p className="input-display-start-date">시작 기간을 입력하세요.</p>
                            <ControlGroup fill={false}>
                                <InputGroup name="displayStartDate"
                                            type="text"
                                            placeholder="0000-00-00 00:00:00"
                                            inputRef={register({
                                                required: true,
                                                minLength: 1,
                                                maxLength: 19,
                                                pattern: DATETIME_PATTERN
                                            })}
                                            defaultValue={board.boardEnv.displayStartDate}
                                            readOnly={false}
                                />
                                <InputGroup name="displayEndDate"
                                            type="text"
                                            placeholder="0000-00-00 00:00:00"
                                            inputRef={register({
                                                required: true,
                                                minLength: 1,
                                                maxLength: 19,
                                                pattern: DATETIME_PATTERN
                                            })}
                                            defaultValue={board.boardEnv.displayEndDate}
                                            readOnly={false}
                                />
                            </ControlGroup>
                            <div className="valid-error-wrapper">
                                {errors.displayStartDate && errors.displayStartDate.type === "required" && (
                                    <p className="valid-error">시작 시간을 입력해주세요.</p>
                                )}
                                {errors.displayStartDate && errors.displayStartDate.type === "minLength" && (
                                    <p className="valid-error">시작 시간을 1자 이상 입력해주세요.</p>
                                )}
                                {errors.displayStartDate && errors.displayStartDate.type === "maxLength" && (
                                    <p className="valid-error">시작 시간을 19자 이상 입력해주세요.</p>
                                )}
                                {errors.displayStartDate && errors.displayStartDate.type === "pattern" && (
                                    <p className="valid-error">시작 시간을 형식에 맞게 입력해주세요.</p>
                                )}
                                {errors.displayEndDate && errors.displayEndDate.type === "required" && (
                                    <p className="valid-error">종료 시간을 입력해주세요.</p>
                                )}
                                {errors.displayEndDate && errors.displayEndDate.type === "minLength" && (
                                    <p className="valid-error">종료 시간을 1자 이상 입력해주세요.</p>
                                )}
                                {errors.displayEndDate && errors.displayEndDate.type === "maxLength" && (
                                    <p className="valid-error">종료 시간을 19자 이상 입력해주세요.</p>
                                )}
                                {errors.displayEndDate && errors.displayEndDate.type === "pattern" && (
                                    <p className="valid-error">종료 시간을 형식에 맞게 입력해주세요.</p>
                                )}
                            </div>
                        </FormGroup>
                    </div>
                </>
                }
                {props.category !== "news" &&
                    <>
                        <InputGroup name="type"
                                    type="hidden"
                                    className="input-type"
                                    inputRef={register({})}
                                    defaultValue={BoardType.NONE}
                        />
                    </>
                }
                <div className="editor-wrapper pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="content"
                        label="이야기를 들려주세요."
                    >
                        <ReactQuill
                            ref={(el) => {
                                quill.current = el;
                            }}
                            theme={"snow"}
                            modules={editorModules}
                            formats={editorFormats}
                            value={content}
                            onChange={(value: string) => {
                                setContent(value);

                                setValue("content", value);
                            }}
                        />
                    </FormGroup>
                </div>

                <div className="group-center pad-h-3">
                    <Button minimal={true} onClick={goBackHandler}>Cancel</Button>
                    <Link to={isTemporary ? "/temporaryBoard/" + category : "/board/" + category}>
                        <Button minimal={true}>List</Button>
                    </Link>
                    <Button type="submit" minimal={true} onClick={() => {
                        setStatus(BoardStatus.TEMPORARY)
                    }}>Save</Button>
                    <Button type="submit" className="button-s-1" minimal={true} onClick={() => {
                        setStatus(BoardStatus.FINISHED)
                    }}>Publish</Button>
                </div>
            </form>
            <div className="pad-t-2">
                <Query
                    query={BOARD_IMAGES_IN_LIBRARY_QUERY}
                    variables={{
                        boardId: newBoardId
                    }}
                    fetchPolicy="cache-and-network"
                >
                    {({loading, error, data}: any) => {
                        if (loading) {
                            return <LoadingPage/>;
                        }

                        if (error) {
                            return <ErrorPage/>;
                        }

                        const {boardImages} = data || {boardImages: null};

                        return (
                            <ImageLibrary editorRef={quill.current} userNo={currentUser.no} boardId={newBoardId}
                                          boardImages={boardImages || []}/>
                        );
                    }}
                </Query>
            </div>
        </>
    );
}

export default EditBoard;

const saveHandlerAction = (boardId: number, categoryId: number, userNo: number, formData: any, status: BoardStatus, saveHandler: any) => {
    const boardImageCache = Client.readQuery({
        query: BOARD_IMAGES_IN_LIBRARY_QUERY,
        variables: {
            boardId
        }
    });

    const boardImages = (boardImageCache.boardImages || []).map((boardImage: any) => {
        const tempImage = {...boardImage.image};
        const tempBoard = {...boardImage.board};
        const tempBoardImage = {...boardImage};

        tempBoardImage.image = tempImage;
        tempBoardImage.board = tempBoard;

        tempBoardImage.boardImageId = 0;
        tempBoardImage.createdDate = null;
        tempBoardImage.image.createdDate = null;

        delete tempBoardImage.image.__typename;
        delete tempBoardImage.board.__typename;
        delete tempBoardImage.board.author.__typename;
        delete tempBoardImage.__typename;

        return tempBoardImage;
    });

    const {
        groupId,
        title,
        description,
        content,
        type,
        // status,
        isDisplay,
        displayStartDate,
        displayEndDate
    } = formData;

    if(title && content) {
        const authorIp = address();

        saveHandler({
            variables: {
                board: {
                    boardId,
                    groupId,
                    category: {
                        categoryId
                    },
                    author: {
                        no: userNo
                    },
                    authorIp,
                    title,
                    description,
                    content,
                    type,
                    status,
                    boardEnv: {
                        isDisplay,
                        displayStartDate,
                        displayEndDate
                    },
                    boardImages
                }
            }
        });
    }
};