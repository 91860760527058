import * as React from "react";
import {IShopComment} from "../../../../common/Domains";
import {Button, ButtonGroup} from "@blueprintjs/core";
import {gql} from "apollo-boost";
import {Query} from "react-apollo";
import {getUserInfo} from "../../../../common/UserInfo";
import {useState} from "react";
import {distanceInWordsToNow} from "date-fns";
import EditableComment, {AddComment, DeleteButton, ModifyButton} from "./EditableComment";

import "../../../../common/css/Comments.css";
import {Profile, ProfileType} from "../../../../user/Profile";
import {ErrorPage} from "../../../../utils/LoadingAndError";

export const SHOP_COMMENTS_QUERY = gql `
    query ShopComments(
        $shopId: Int,
        $offset: Int,
        $limit: Int
    ) {
        shopComments(
            shopId: $shopId,
            offset: $offset,
            limit: $limit
        ) {
            commentId
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            content
            commentType
            shop {
                shopId
            }
        }
    }
`;

interface ICommentsProps {
    shopId: number
}

class Comments extends React.Component<ICommentsProps, {}> {
    public render() {
        const { shopId } = this.props;

        return (
            <div className="comments-wrapper">
                <Query
                    query={SHOP_COMMENTS_QUERY}
                    variables={{
                        shopId,
                        offset: 0,
                        limit: 10
                    }}
                    fetchPolicy="cache-and-network"
                >
                    {({ loading, error, data, fetchMore } : any) => {
                        if(error) {
                            return <ErrorPage />;
                        }

                        const { shopComments } = data || { shopComments: null };
                        const offset = shopComments ? shopComments.length : 0;

                        return (
                            <>
                                <Comment shopComments={shopComments} shopId={shopId} />
                                { offset > 0 &&
                                    <LoadMore fetchMore={fetchMore} offset={offset} />
                                }
                            </>
                        );
                    }}
                </Query>
            </div>
        );
    }
}

const LoadMore = ({ fetchMore, offset }: any) => {
    const loadMoreHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        fetchMore({
            variables: {
                offset
            },
            updateQuery: (prev: any, {fetchMoreResult}: any) => {
                if(!fetchMoreResult) {
                    return prev;
                }

                return Object.assign({}, {
                    shopComments: [...prev.shopComments, ...fetchMoreResult.shopComments]
                });
            }
        });
    };

    return (
        <div className="group-center pad-h-3">
            <Button minimal={true} onClick={loadMoreHandler} >Load more</Button>
        </div>
    );
};

export default Comments;

interface ICommentProps {
    shopComments: IShopComment[]
    shopId: number
}

const Comment = (props: ICommentProps) => {
    const { shopComments, shopId } = props;

    return (
        <div className="comments-container">
            <div className="add-comment-wrapper">
                <AddComment shopId={shopId} />
            </div>
            <div className="comment-item-list">
                <ul>
                    { (shopComments || []).map((shopComment: IShopComment) => {
                        return (
                            <CommentWrapper key={shopComment.commentId} shopComment={shopComment} />
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

interface ICommentWrapper {
    shopComment: IShopComment
}

const CommentWrapper = (props: ICommentWrapper) => {
    const { shopComment } = props;

    const currentUser = getUserInfo();

    const distanceCreatedDate = distanceInWordsToNow(shopComment.createdDate);
    const isMyComment = currentUser.no === shopComment.author.no;

    const [isEditableComment, setIsEditableComment] = useState(false);

    const onChangeModify = (isEnable: boolean) => {
        setIsEditableComment(isEnable);
    };

    return (
        <li>
            <div className="comment-item-container">
                <Profile user={shopComment.author}
                         profileType={ProfileType.SIMPLE}
                         large={false} isForceUserDetail={true} disableDescription={true}
                >
                    <div className="date">{distanceCreatedDate}</div>
                    { isMyComment && <EditableComment commentId={shopComment.commentId} content={shopComment.content} userNo={currentUser.no} isEditable={isEditableComment} onChangeEditable={setIsEditableComment} /> }
                    { !isMyComment && <EditableComment commentId={shopComment.commentId} content={shopComment.content} userNo={currentUser.no} isEditable={false} /> }
                    <div className="operations">
                        {!isEditableComment && <ButtonGroup minimal={true}>
                            { isMyComment && <ModifyButton commentId={shopComment.commentId} shopId={shopComment.shop.shopId} changeModifyState={onChangeModify} /> }
                            { isMyComment && <DeleteButton commentId={shopComment.commentId} shopId={shopComment.shop.shopId} /> }
                        </ButtonGroup>}
                    </div>
                </Profile>
            </div>
        </li>
    );
};