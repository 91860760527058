import * as React from "react";

import Header from "./components/layout/Header";
import Content from "./components/layout/Content";
import Footer from "./components/layout/Footer";

class App extends React.Component {
  public render() {
    return (
        <div>
            <Header />
            <Content />
            <Footer />
        </div>
    );
  }
}

export default App;