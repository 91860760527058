 import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Button, FormGroup, InputGroup} from "@blueprintjs/core";
import ReactQuill, {Quill} from "react-quill";

import ImageResize from "quill-image-resize-module-react";
import {ImageUpload} from "quill-image-upload";
import {ImageDrop} from 'quill-image-drop-module';
import VideoResize from 'quill-video-resize-module-react';
import {editorFormats, editorModules} from "../editor/EditorOptions";

import "node_modules/react-quill/dist/quill.snow.css";
import "../../common/css/quill-editor.css";
import "./AddItem.css";
import {Query} from "react-apollo";
import {
    IBrand, ICategory,
    IItem,
    // ITEM_CATEGORIES,
    // ItemCategory,
    ItemStatus
} from "../common/Domains";
import {
    EDIT_DEFAULT_ITEM_CONTENT,
    UNKNOWN_BRAND,
} from "../common/Constant";
import {Link, RouteComponentProps} from "react-router-dom";
import ImageLibrary, {ITEM_IMAGES_IN_LIBRARY_QUERY} from "./image/ImageLibrary";

import {getUserInfo} from "../common/UserInfo";

import {useForm} from "react-hook-form";
import {Client} from "../../store/ApolloStore";
import {address} from "ip";
import {SearchBrand} from "../brand/Brands";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";
 import {SearchCategory} from "../category/Categories";

Quill.register("modules/imageUpload", ImageUpload);
Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/videoResize', VideoResize);

interface IUserFormData {
    title: string
    description: string
    brandId: number
    korName: string
    engName: string
    categoryId: number
    categoryName: string
    desc: string
    content: string
    quantity: number
    price: number
    discountPrice: number
    discount: number
    discountUnit: string
    imageFile: string
    model: string
    origin: string
    manufacturer: string
    material: string
    size: string
    composition: string
    url: string
}

interface IProps extends RouteComponentProps {
    isTemporary: boolean
    itemId?: number
    item: IItem
    saveHandler: any
    isEnableAutoSave: boolean
}

export const EditItem = (props: IProps) => {
    const quill: any = useRef();

    const { isTemporary, itemId, item } = props;

    const currentUser = getUserInfo();
    const newItemId = itemId || -1;

    const [ content, setContent ] = useState(item.content || EDIT_DEFAULT_ITEM_CONTENT);
    const [ status, setStatus ] = useState(ItemStatus.FINISHED);
    const [ isPublished, setPublished ] = useState(false);

    const { register, handleSubmit, errors, setValue } = useForm<IUserFormData>({
        defaultValues: {
            brandId: item.brand.brandId,
            categoryId: item.category.categoryId,
            content
        }
    });

    const goBackHandler = () => {
        props.history.back();
    };

    const submitHandler = (formData: any) => {
        if(!isPublished) {
            setPublished(true);

            saveHandlerAction(newItemId, currentUser.no, formData, status, props.saveHandler);
        }
    };

    useEffect(() => {
        register({ name: "brandId"});
        register({ name: "categoryId"});
        register({ name: "content"});
    });

    return (
        <>
            <form onSubmit={handleSubmit(submitHandler)}>
                <div>
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="title"
                        label="제목을 입력해주세요."
                    >
                        <InputGroup name="title"
                                    className="input-title"
                                    placeholder="제목을 입력해주세요"
                                    inputRef={register({ required: true, minLength: 1, maxLength: 200 })}
                                    defaultValue={item.title}
                        />
                        <div className="valid-error-wrapper">
                            {errors.title && errors.title.type === "required" && (
                                <p className="valid-error">제목을 입력해주세요.</p>
                            )}
                            {errors.title && errors.title.type === "minLength" && (
                                <p className="valid-error">제목을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.title && errors.title.type === "maxLength" && (
                                <p className="valid-error">제목을 200자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="description"
                        label="간단한 설명을 입력해주세요."
                    >
                        <InputGroup name="description"
                                    className="input-description"
                                    placeholder="간단한 설명을 입력해주세요"
                                    inputRef={register({ required: true, minLength: 10, maxLength: 200 })}
                                    defaultValue={item.description}
                        />
                        <div className="valid-error-wrapper">
                            {errors.description && errors.description.type === "required" && (
                                <p className="valid-error">설명을 입력해주세요.</p>
                            )}
                            {errors.description && errors.description.type === "minLength" && (
                                <p className="valid-error">설명을 10자 이상 입력해주세요.</p>
                            )}
                            {errors.description && errors.description.type === "maxLength" && (
                                <p className="valid-error">설명을 200자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="url"
                        label="사이트 링크를 입력해주세요."
                    >
                        <InputGroup name="url"
                                    className="input-url"
                                    placeholder="사이트 링크를 입력해주세요"
                                    inputRef={register({ required: true, minLength: 10, maxLength: 200 })}
                                    defaultValue={item.itemEnv.url}
                        />
                        <div className="valid-error-wrapper">
                            {errors.url && errors.url.type === "required" && (
                                <p className="valid-error">사이트 링크를 입력해주세요.</p>
                            )}
                            {errors.url && errors.url.type === "minLength" && (
                                <p className="valid-error">사이트 링크를 10자 이상 입력해주세요.</p>
                            )}
                            {errors.url && errors.url.type === "maxLength" && (
                                <p className="valid-error">사이트 링크를 200자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="name"
                        label="브랜드명을 선택해주세요."
                    >
                        <InputGroup name="korName"
                                    placeholder="한글명을 알려주세요."
                                    readOnly={true}
                                    inputRef={register({ required: true, minLength: 1, maxLength: 100})}
                                    defaultValue={item.brand.korName}
                        />
                        <InputGroup name="engName"
                                    placeholder="영문명을 알려주세요."
                                    readOnly={true}
                                    inputRef={register({ required: true, minLength: 1, maxLength: 100})}
                                    defaultValue={item.brand.engName}
                        />
                        <InputGroup name="desc"
                                    placeholder="설명을 알려주세요."
                                    readOnly={true}
                                    inputRef={register({ required: true, minLength: 1, maxLength: 100})}
                                    defaultValue={item.brand.desc}
                        />
                        <SearchBrand onSearch={(brand: IBrand) => {
                            setValue("brandId", brand.brandId);
                            setValue("korName", brand.korName);
                            setValue("engName", brand.engName);
                            setValue("desc", brand.desc);
                        }}/>
                        <div className="valid-error-wrapper">
                            {errors.korName && errors.korName.type === "required" && (
                                <p className="valid-error">한국명을 입력해주세요.</p>
                            )}
                            {errors.korName && errors.korName.type === "minLength" && (
                                <p className="valid-error">한국명을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.korName && errors.korName.type === "maxLength" && (
                                <p className="valid-error">한국명을 100자 이하 입력해주세요.</p>
                            )}
                            {errors.engName && errors.engName.type === "required" && (
                                <p className="valid-error">을 입력해주세요.</p>
                            )}
                            {errors.engName && errors.engName.type === "minLength" && (
                                <p className="valid-error">영문명을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.engName && errors.engName.type === "maxLength" && (
                                <p className="valid-error">영문명을 100자 이하 입력해주세요.</p>
                            )}
                            {errors.desc && errors.desc.type === "required" && (
                                <p className="valid-error">설명을 입력해주세요.</p>
                            )}
                            {errors.desc && errors.desc.type === "minLength" && (
                                <p className="valid-error">설명을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.desc && errors.desc.type === "maxLength" && (
                                <p className="valid-error">설명을 100자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="name"
                        label="카테고리를 선택해주세요."
                    >
                        <InputGroup name="categoryName"
                                    placeholder="카테고리를 선택하세요"
                                    readOnly={true}
                                    inputRef={register({ required: true, minLength: 1, maxLength: 100})}
                                    defaultValue={item.category.name}
                        />
                        <SearchCategory onSearch={(category: ICategory) => {
                            setValue("categoryId", category.categoryId);
                            setValue("categoryName", category.name);
                        }}/>
                        <div className="valid-error-wrapper">
                            {errors.categoryName && errors.categoryName.type === "required" && (
                                <p className="valid-error">한국명을 입력해주세요.</p>
                            )}
                            {errors.categoryName && errors.categoryName.type === "minLength" && (
                                <p className="valid-error">한국명을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.categoryName && errors.categoryName.type === "maxLength" && (
                                <p className="valid-error">한국명을 100자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="quantity"
                        label="수량"
                    >
                        <InputGroup name="quantity"
                                    className="input-quantity"
                                    placeholder="수량을 입력해주세요"
                                    inputRef={register({ required: true, minLength: 1, maxLength: 20 })}
                                    defaultValue={item.quantity + ""}
                        />
                        <div className="valid-error-wrapper">
                            {errors.quantity && errors.quantity.type === "required" && (
                                <p className="valid-error">수량을 입력해주세요.</p>
                            )}
                            {errors.quantity && errors.quantity.type === "minLength" && (
                                <p className="valid-error">수량을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.quantity && errors.quantity.type === "maxLength" && (
                                <p className="valid-error">모델명을 20자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="price"
                        label="소비자가"
                    >
                        <InputGroup name="price"
                                    className="input-price"
                                    placeholder="소비자가를 입력해주세요"
                                    inputRef={register({ required: true, minLength: 1, maxLength: 20 })}
                                    defaultValue={item.price + ""}
                        />
                        <div className="valid-error-wrapper">
                            {errors.price && errors.price.type === "required" && (
                                <p className="valid-error">소비자가를 입력해주세요.</p>
                            )}
                            {errors.price && errors.price.type === "minLength" && (
                                <p className="valid-error">소비자가를 1자 이상 입력해주세요.</p>
                            )}
                            {errors.price && errors.price.type === "maxLength" && (
                                <p className="valid-error">소비자가를 20자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="discount"
                        label="할인율"
                    >
                        <InputGroup name="discount"
                                    className="input-discount"
                                    placeholder="할인율 입력해주세요"
                                    inputRef={register({ required: true, minLength: 1, maxLength: 20 })}
                                    defaultValue={item.discount + ""}
                        />
                        <div className="valid-error-wrapper">
                            {errors.discount && errors.discount.type === "required" && (
                                <p className="valid-error">할인율을 입력해주세요.</p>
                            )}
                            {errors.discount && errors.discount.type === "minLength" && (
                                <p className="valid-error">할인율을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.discount && errors.discount.type === "maxLength" && (
                                <p className="valid-error">할인율을 20자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="discountUnit"
                        label="할인율 단위"
                    >
                        <InputGroup name="discountUnit"
                                    className="input-discountUnit"
                                    placeholder="할인율단위를 입력해주세요"
                                    inputRef={register({ required: true, minLength: 1, maxLength: 20 })}
                                    defaultValue={item.discountUnit}
                        />
                        <div className="valid-error-wrapper">
                            {errors.discountUnit && errors.discountUnit.type === "required" && (
                                <p className="valid-error">할인율 단위를 입력해주세요.</p>
                            )}
                            {errors.discountUnit && errors.discountUnit.type === "minLength" && (
                                <p className="valid-error">할인율 단위를 1자 이상 입력해주세요.</p>
                            )}
                            {errors.discountUnit && errors.discountUnit.type === "maxLength" && (
                                <p className="valid-error">할인율 단위를 20자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="discountPrice"
                        label="판매가"
                    >
                        <InputGroup name="discountPrice"
                                    className="input-discountPrice"
                                    placeholder="판매가를 입력해주세요"
                                    inputRef={register({ required: true, minLength: 1, maxLength: 20 })}
                                    defaultValue={item.discountPrice + ""}
                        />
                        <div className="valid-error-wrapper">
                            {errors.discountPrice && errors.discountPrice.type === "required" && (
                                <p className="valid-error">판매가를 입력해주세요.</p>
                            )}
                            {errors.discountPrice && errors.discountPrice.type === "minLength" && (
                                <p className="valid-error">판매가를 1자 이상 입력해주세요.</p>
                            )}
                            {errors.discountPrice && errors.discountPrice.type === "maxLength" && (
                                <p className="valid-error">판매가를 20자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>

                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="model"
                        label="모델명"
                    >
                        <InputGroup name="model"
                                    className="input-model"
                                    placeholder="모델명을 입력해주세요"
                                    inputRef={register({ required: true, minLength: 1, maxLength: 100 })}
                                    defaultValue={item.itemEnv.model}
                        />
                        <div className="valid-error-wrapper">
                            {errors.model && errors.model.type === "required" && (
                                <p className="valid-error">모델명을 입력해주세요.</p>
                            )}
                            {errors.model && errors.model.type === "minLength" && (
                                <p className="valid-error">모델명을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.model && errors.model.type === "maxLength" && (
                                <p className="valid-error">모델명을 20자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="origin"
                        label="원산지"
                    >
                        <InputGroup name="origin"
                                    className="input-origin"
                                    placeholder="원산지을 입력해주세요"
                                    inputRef={register({ required: true, minLength: 1, maxLength: 100 })}
                                    defaultValue={item.itemEnv.origin}
                        />
                        <div className="valid-error-wrapper">
                            {errors.origin && errors.origin.type === "required" && (
                                <p className="valid-error">원산지를 입력해주세요.</p>
                            )}
                            {errors.origin && errors.origin.type === "minLength" && (
                                <p className="valid-error">원산지를 1자 이상 입력해주세요.</p>
                            )}
                            {errors.origin && errors.origin.type === "maxLength" && (
                                <p className="valid-error">원산지를 100자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="manufacturer"
                        label="제조사"
                    >
                        <InputGroup name="manufacturer"
                                    className="input-manufacturer"
                                    placeholder="제조사 입력해주세요"
                                    inputRef={register({ required: true, minLength: 1, maxLength: 100 })}
                                    defaultValue={item.itemEnv.manufacturer}
                        />
                        <div className="valid-error-wrapper">
                            {errors.manufacturer && errors.manufacturer.type === "required" && (
                                <p className="valid-error">모델명을 입력해주세요.</p>
                            )}
                            {errors.manufacturer && errors.manufacturer.type === "minLength" && (
                                <p className="valid-error">모델명을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.manufacturer && errors.manufacturer.type === "maxLength" && (
                                <p className="valid-error">모델명을 100자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>

                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="material"
                        label="소재"
                    >
                        <InputGroup name="material"
                                    className="input-material"
                                    placeholder="소재를 입력해주세요"
                                    inputRef={register({ required: true, minLength: 1, maxLength: 100 })}
                                    defaultValue={item.itemEnv.material}
                        />
                        <div className="valid-error-wrapper">
                            {errors.material && errors.material.type === "required" && (
                                <p className="valid-error">소재를 입력해주세요.</p>
                            )}
                            {errors.material && errors.material.type === "minLength" && (
                                <p className="valid-error">소재를 1자 이상 입력해주세요.</p>
                            )}
                            {errors.material && errors.material.type === "maxLength" && (
                                <p className="valid-error">소재를 100자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>

                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="size"
                        label="사이즈"
                    >
                        <InputGroup name="size"
                                    className="input-size"
                                    placeholder="사이즈를 입력해주세요"
                                    inputRef={register({ required: true, minLength: 1, maxLength: 100 })}
                                    defaultValue={item.itemEnv.size}
                        />
                        <div className="valid-error-wrapper">
                            {errors.size && errors.size.type === "required" && (
                                <p className="valid-error">사이즈를 입력해주세요.</p>
                            )}
                            {errors.size && errors.size.type === "minLength" && (
                                <p className="valid-error">사이즈를 1자 이상 입력해주세요.</p>
                            )}
                            {errors.size && errors.size.type === "maxLength" && (
                                <p className="valid-error">사이즈를 100자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>

                <div className="pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="composition"
                        label="구성"
                    >
                        <InputGroup name="composition"
                                    className="input-composition"
                                    placeholder="구성 입력해주세요"
                                    inputRef={register({ required: true, minLength: 1, maxLength: 100 })}
                                    defaultValue={item.itemEnv.composition}
                        />
                        <div className="valid-error-wrapper">
                            {errors.composition && errors.composition.type === "required" && (
                                <p className="valid-error">구성을 입력해주세요.</p>
                            )}
                            {errors.composition && errors.composition.type === "minLength" && (
                                <p className="valid-error">구성을 1자 이상 입력해주세요.</p>
                            )}
                            {errors.composition && errors.composition.type === "maxLength" && (
                                <p className="valid-error">구성을 100자 이하 입력해주세요.</p>
                            )}
                        </div>
                    </FormGroup>
                </div>
                <div className="editor-wrapper pad-t-2">
                    <FormGroup
                        className="user-input-wrapper"
                        labelFor="content"
                        label="상품 정보"
                    >
                        <p className="input-description">상품 정보를 입력해주세요.</p>
                        <ReactQuill
                            ref={(el) => {
                                quill.current = el;
                            }}
                            theme={"snow"}
                            modules={editorModules}
                            formats={editorFormats}
                            value={content}
                            onChange={(value: string) => {
                                setContent(value);

                                setValue("content", value);
                            }}
                        />
                    </FormGroup>
                </div>

                <div className="group-center pad-h-3">
                    <Button minimal={true} onClick={goBackHandler}>Cancel</Button>
                    <Link to={isTemporary ? "/temporaryItems" : "/items"}>
                        <Button minimal={true}>List</Button>
                    </Link>
                    <Button type="submit" minimal={true} onClick={() => { setStatus(ItemStatus.TEMPORARY )}}>Save</Button>
                    <Button type="submit" className="button-s-1"  minimal={true} onClick={() => { setStatus(ItemStatus.FINISHED )}}>Publish</Button>
                </div>
            </form>
            <div className="pad-t-2">
                <Query
                    query={ITEM_IMAGES_IN_LIBRARY_QUERY}
                    variables={{
                        itemId: newItemId
                    }}
                    fetchPolicy="cache-and-network"
                >
                    {({loading, error, data}: any) => {
                        if (loading) {
                            return <LoadingPage />;
                        }

                        if (error) {
                            return <ErrorPage />
                        }

                        const { itemImages } = data || { itemImages: null };

                        return (
                            <ImageLibrary editorRef={quill.current} userNo={currentUser.no} itemId={newItemId} itemImages={itemImages || []} />
                        );
                    }}
                </Query>
            </div>
        </>
    );
}

export default EditItem;

const saveHandlerAction = (itemId: number, userNo: number, formData: any, status: ItemStatus, saveHandler: any) => {
    const itemImageCache = Client.readQuery({
        query: ITEM_IMAGES_IN_LIBRARY_QUERY,
        variables: {
            itemId
        }
    });

    const itemImages = (itemImageCache.itemImages || []).map((itemImage: any) => {
        const tempImage = {...itemImage.image};
        const tempItem = {...itemImage.item};
        const tempItemImage = {...itemImage};

        tempItemImage.image = tempImage;
        tempItemImage.item = tempItem;

        tempItemImage.itemImageId = 0;
        tempItemImage.createdDate = null;
        tempItemImage.image.createdDate = null;

        delete tempItemImage.image.__typename;
        delete tempItemImage.item.__typename;
        delete tempItemImage.item.author.__typename;
        delete tempItemImage.__typename;

        return tempItemImage;
    });

    const {
        groupId,
        title,
        brandId,
        description,
        categoryId,
        content,
        quantity,
        price,
        discountPrice,
        discount,
        discountUnit,
        type,
        model,
        origin,
        manufacturer,
        material,
        size,
        composition,
        // status,
    } = formData;

    if(title && content) {
        const authorIp = address();

        saveHandler({
            variables: {
                item: {
                    itemId,
                    groupId,
                    category: {
                        categoryId
                    },
                    author: {
                        no: userNo
                    },
                    authorIp,
                    brand: {
                        brandId: brandId ? brandId : UNKNOWN_BRAND
                    },
                    title,
                    description,
                    content,
                    quantity,
                    price,
                    discountPrice,
                    discount,
                    discountUnit,
                    type,
                    status,
                    itemEnv: {
                        model,
                        origin,
                        manufacturer,
                        material,
                        size,
                        composition,
                    },
                    itemImages
                }
            }
        });
    }
};
