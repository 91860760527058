import * as React from "react";
import {Link} from "react-router-dom";
import {SignupUserMenu} from "./UnSignupUserMenu";
// import {NewItems} from "./NewsPopover";

import "./MainMenu.css";

interface IProps {
    dummy?: number
}

interface IState {
    isShowMenu: boolean
}

class MainMenu extends React.Component<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            isShowMenu: false
        }
    }

    public render() {
        return (
            <div className="main-menu-container">
                <div className="grid-container border-bottom">
                    <div className="default-grid-container">
                        <div className="title">
                            <Link to="/"><span className="main-title">jiyunshop</span></Link>
                        </div>

                        <div className="menu-wrapper">
                            <BuggerMenuWrapper isShowMenu={this.state.isShowMenu} clickBuggerMenu={this.clickBuggerMenu} clickMenuItem={this.clickMenuItem} />
                            <div className={this.state.isShowMenu ? "menu-item-wrapper menu-show" : "menu-item-wrapper  menu-hidden"}>
                                <ul>
                                    <li className="main"><Link to="/special/home" onClick={this.clickMenuItem}>Special</Link></li>
                                    <li className="main"><Link to="/stories/home" onClick={this.clickMenuItem}>Stories</Link></li>
                                    <li className="main"><Link to="/item/home" onClick={this.clickMenuItem}>New In</Link></li>
                                    <li className="main"><Link to="/items/tableware" onClick={this.clickMenuItem}>Tableware</Link></li>
                                    <li className="main"><Link to="/items/kitchen" onClick={this.clickMenuItem}>Kitchen</Link></li>
                                    <li className="main"><Link to="/items/bathroom" onClick={this.clickMenuItem}>Bathroom</Link></li>
                                    <li className="main"><Link to="/items/home" onClick={this.clickMenuItem}>Home</Link></li>
                                    <li className="main"><Link to="/items/object" onClick={this.clickMenuItem}>Object</Link></li>
                                    <li className="main"><Link to="/items/outdoor" onClick={this.clickMenuItem}>Outdoor</Link></li>
                                </ul>
                            </div>
                            <div className="popover-main-menu-wrapper">
                                <SignupUserMenu />
                            </div>
                        </div>
                        <div style={{clear: "both"}} />
                    </div>
                </div>
                {/*<div className="grid-container">*/}
                {/*    <div className="default-grid-container pad-h-1">*/}
                {/*        <NewItems />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    }

    private clickBuggerMenu = () => {
        this.setState({
            isShowMenu: true
        })
    };

    private clickMenuItem = () => {
        this.setState({
            isShowMenu: false
        })
    };
}

export default MainMenu;

interface IBuggerMenuWrapperProps {
    isShowMenu: boolean
    clickBuggerMenu: () => void
    clickMenuItem: () => void
}

const BuggerMenuWrapper = (props: IBuggerMenuWrapperProps) => {
    return(
        props.isShowMenu ? (
            <div className="close-menu" onClick={props.clickMenuItem}>
                <svg width="30" height="30" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                    <g id="Layer_1">
                        <title>Close</title>
                        <line stroke-linecap="null" stroke-linejoin="null" id="svg_11" y2="29.43738" x2="29.46863" y1="0.56269" x1="0.59394" opacity="undefined" fill-opacity="null" stroke-opacity="null" stroke-dasharray="null" stroke="#000" fill="none"/>
                        <line transform="rotate(90 15.0313 15)" stroke-linecap="null" stroke-linejoin="null" id="svg_13" y2="29.43738" x2="29.46863" y1="0.56269" x1="0.59394" opacity="undefined" fill-opacity="null" stroke-opacity="null" stroke-dasharray="null" stroke="#000" fill="none"/>
                    </g>
                </svg>
            </div>
        ) : (
            <div className="bugger-menu" onClick={props.clickBuggerMenu}>
                <div/>
                <div/>
                <div/>
            </div>
        )
    );
}




