import {IImage} from "../common/Domains";
import {getImagePath} from "../image/ImageLibrary";
import * as React from "react";
import {Tag} from "@blueprintjs/core";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export enum CardAlign {
    LEFT = "left",
    RIGHT = "right",
    CENTER = "center",
    TOP_LEFT = "top-left",
    TOP_CENTER = "top-center",
    TOP_RIGHT = "top-right",
    MIDDLE_LEFT = "middle-left",
    MIDDLE_CENTER = "middle-center",
    MIDDLE_RIGHT = "middle-right",
    BOTTOM_LEFT = "bottom-left",
    BOTTOM_CENTER = "bottom-center",
    BOTTOM_RIGHT = "bottom-right",
}

export interface ILinkInfo {
    path: string
    localParams?: any
}

export interface ICardData {
    id: string
    link: ILinkInfo
    images: IImage[]
    date: string
    title: string
    subTitle?: string
    subSubTitle?: string
    description: string
    value: string
    author: string
    price?: IPrice
    tags: ICardTag[]
    extra?: any
    likeHandler: (id: string, status: boolean) => {}
}

export interface IPrice {
    price: number
    discountPrice: number
    discount: number
    discountUnit: string
}
export interface ICardMeta {
    key: string
    name: string
    formatter: (value: any, key: any) => {}
}

export interface ICardTag {
    key: string
    name: string
    value: string
}

export interface IPreviewProps {
    images: IImage[]
    width: number
    height: number
}

export const Preview = ({images, width, height}: IPreviewProps) => {
    if(!images || images.length === 0) {
        return (
            <LazyLoadImage
                className="img"
                style={{
                    msFlex: "1 1",
                    flex: "1 1",
                    overflow: "hidden",
                    objectFit: "cover"}}
                effect="blur"
                src={getImagePath()}
                width={"100%"}
                height={"100%"}
            />
        );
    }

    const imageUrl = getImagePath(images[0], width, height);

    return (
        <LazyLoadImage
            className="img"
            style={{
                msFlex: "1 1",
                flex: "1 1",
                overflow: "hidden",
                objectFit: "cover"}}
            effect="blur"
            src={imageUrl}
            width={"100%"}
            height={"100%"}
        />
    );
};

export const getPreviewImageIds = (images: IImage[]) : IImage[] => {
    if(!images || images.length === 0) {
        return [];
    }

    return images;
};


export interface ITagsProps {
    tags: ICardTag[]
}

export const ICardTags = ({tags}: ITagsProps) => {
    if(!tags || tags.length === 0) {
        return <></>;
    }

    return (
        <div className="tags">
            {
                tags.map((tag: ICardTag) => {
                    return (
                        <Tag key={tag.key} minimal={true} round={true}>
                            {tag.name}/{tag.value}
                        </Tag>
                    );
                })
            }
        </div>
    );
};