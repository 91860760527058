import * as React from "react";
import {Button, ButtonGroup, FileInput, H5, Intent, Popover, Switch, TextArea} from "@blueprintjs/core";
import {IItemImage} from "../../common/Domains";
import {API_BASE_URL, IMAGE_EXTENSIONS, MAX_UPLOAD_IMAGE_SIZE, THUMBNAIL_SIZES} from "../../common/Constant";
import {Image} from "grommet";
import {distanceInWordsToNow} from "date-fns";
import {useMutation}from "react-apollo-hooks";
import gql from "graphql-tag";
import {Client} from "../../../store/ApolloStore";
import {getImagePath, uploadFile} from "../../image/ImageLibrary";
import {Mutation} from "react-apollo";
import {PopoverInteractionKind} from "@blueprintjs/core/lib/esm/components/popover/popover";
import {getUserInfo} from "../../common/UserInfo";

import "../../common/css/ImageLibrary.css";
import {getToken} from "../../../store/cache/Token";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {AddImageToEditor} from "../../editor/EditorOptions";

export const ITEM_IMAGES_IN_LIBRARY_QUERY = gql `    
    query ItemImages(
        $itemId: Int
    ) {
        itemImages(
            itemId: $itemId
        ) {
            itemImageId
            item {
                itemId
                author {
                    no
                }
            }
            image {
                imageId
                name
                realName
                desc
                year
                month
                day
                userNo
                createdDate
            }
            isDefault
            createdDate
        }
    }
`;

const DELETE_IMAGE_LIBRARY_QUERY = gql `
    mutation DeleteItemImage(
        $itemImage: InputItemImage
    ) {
        deleteItemImage(
            itemImage: $itemImage
        ) {
            itemImageId
            image {
                imageId
            }
        }
    }
`;

const DELETE_IMAGE_LIBRARY_QUERY_IN_CACHE = gql `
    mutation DeleteItemImageInCache(
        $itemImage: InputItemImage
    ) {
        deleteItemImageInCache(
            itemImage: $itemImage
        ) @client
    }
`;

const TOGGLE_DEFAULT_IMAGE_QUERY = gql `
    mutation ToggleDefaultItemImage($itemImageId: Int) {
        toggleDefaultItemImage(itemImageId: $itemImageId) @client
    }
`;

interface IUserFormData {
    description: string
    imageFile: string
}

interface IProps {
    editorRef: any
    itemId: number
    userNo: number
    itemImages: IItemImage[]
}

export const ImageLibrary = (props: IProps) => {
    const { register, handleSubmit, errors, setValue } = useForm<IUserFormData>({
        defaultValues: {
            imageFile: undefined
        }
    });

    const [ showImageArea, setShowImageArea ] = useState(false);

    useEffect(() => {
        register({ name: "imageFile" }, { required: true, validate: (fileInfo) => {
                const fileName = fileInfo.name;
                const fileExt = fileName.slice(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase();
                const isValidFileExt = IMAGE_EXTENSIONS.includes(fileExt);

                const isValidFileSize = MAX_UPLOAD_IMAGE_SIZE > fileInfo.size;

                return (isValidFileExt && isValidFileSize);
            }});
    });

    const submitHandler = (formData: any) => {
        const token = getToken();

        const uploadOptions = {
            url: API_BASE_URL  + "/upload",
            method: 'POST',
            data: {
                image: formData.imageFile,
                description: formData.description,
                userNo: props.userNo
            },
            headers: {
                Authentication: token ? `Bearer ${token}` : ""
            },
            csrf: false,
            withCredentials: true,
            callbackOK: (serverResponse: any) => {
                const data = Client.readQuery({
                    query: ITEM_IMAGES_IN_LIBRARY_QUERY,
                    variables: {
                        itemId: props.itemId
                    }
                });

                const itemImages = data.itemImages || [];

                const itemImage = {
                    itemImageId: itemImages.length - 9999 + 1,
                    item: {
                        itemId: props.itemId,
                        author: {
                            no: props.userNo,
                            __typename: "User"
                        },
                        __typename: "Item"
                    },
                    image: {
                        imageId: serverResponse.imageId,
                        name: serverResponse.name,
                        realName: serverResponse.realName,
                        desc: serverResponse.desc,
                        year: serverResponse.year,
                        month: serverResponse.month,
                        day: serverResponse.day,
                        userNo: serverResponse.userNo,
                        createdDate: serverResponse.createdDate,
                        __typename: "Image"
                    },
                    isDefault: false,
                    createdDate: serverResponse.createdDate,
                    __typename: "ItemImage"
                };

                Client.writeQuery({
                    query: ITEM_IMAGES_IN_LIBRARY_QUERY,
                    variables: {
                        itemId: props.itemId
                    },
                    data: {
                        itemImages: [itemImage, ...itemImages]
                    }
                });

                // INSERT IMAGE TO EDITOR
                const imagePath = getImagePath(itemImage.image, THUMBNAIL_SIZES.MIDDLE.WIDTH, THUMBNAIL_SIZES.MIDDLE.HEIGHT);

                AddImageToEditor({editorRef: props.editorRef, imagePath });
            },
            callbackKO: (serverError: any) => {
                console.log(serverError);
            }
        }

        if(formData.imageFile) {
            uploadFile(uploadOptions);
        }
    };

    const changeFile = (event: React.FormEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files ? event.currentTarget.files[0]: null;
        if(file) {
            setValue("imageFile", file);
        }
    };

    const setShowToggle = () => {
        setShowImageArea(!showImageArea);
    }

    return (
        <div className={"image-library-container " + (showImageArea ? "" : "hide")}>
            <div className="close" onClick={setShowToggle}>Show/Hide</div>
            <div className="header-wrapper-3">
                <div className="title">Images</div>
            </div>
            <div className="add-image-wrapper">
                <form onSubmit={handleSubmit(submitHandler)}>
                    <FileInput text="Choose image (jpg, jpeg, png, gif)..." id="image"
                               inputProps={{type: "file", name: "imageFile"}} fill={true}
                               onInputChange={changeFile}
                    />
                    <div className="valid-error-wrapper">
                        {errors.imageFile && errors.imageFile.type === "required" && (
                            <p className="valid-error">파일을 선택해주세요.</p>
                        )}
                        {errors.imageFile && errors.imageFile.type === "validate" && (
                            <p className="valid-error">jpg, jpeg, png, gif, 20MB이하의 이미지를 등록해주세요.</p>
                        )}
                    </div>
                    <TextArea name="description" className="editor margin-h-1"
                              fill={true} large={false} growVertically={true}
                              placeholder="이미지 설명을 입력하세요."
                              inputRef={register({required: true, minLength: 3, maxLength: 100})}
                    />
                    <div className="valid-error-wrapper">
                        {errors.description && errors.description.type === "required" && (
                            <p className="valid-error">이미지 설명을 입력해주세요.</p>
                        )}
                        {errors.description && errors.description.type === "minLength" && (
                            <p className="valid-error">이미지 설명을 3자 이상 입력해주세요.</p>
                        )}
                        {errors.description && errors.description.type === "maxLength" && (
                            <p className="valid-error">이미지 설명을 100자 이하 입력해주세요.</p>
                        )}
                    </div>
                    <div className="group-right">
                        <Button type="submit" className="margin-h-1" minimal={true}>Add Image</Button>
                    </div>
                </form>
            </div>
            <div className="image-item-list">
                <ul>
                {
                    (props.itemImages || []).map((itemImage: IItemImage) => {
                        return (
                            <li key={itemImage.itemImageId} className={ "image_" + itemImage.itemImageId}>
                                <ImageItemWrapper editorRef={props.editorRef} itemImage={itemImage} />
                            </li>
                        );
                    })
                }
                </ul>
            </div>
        </div>
    );
};

export default ImageLibrary;

interface IImageItemWrapper {
    editorRef: any
    itemImage: IItemImage
}

const ImageItemWrapper = (props: IImageItemWrapper) => {
    const {editorRef, itemImage} = props;

    const imagePath = getImagePath(itemImage.image, THUMBNAIL_SIZES.MIDDLE.WIDTH, THUMBNAIL_SIZES.MIDDLE.HEIGHT);
    const distanceCreatedDate = distanceInWordsToNow(new Date(itemImage.image.createdDate), { includeSeconds: true });
    const [toggleDefaultItemImage]: any = useMutation(TOGGLE_DEFAULT_IMAGE_QUERY, { variables:  { itemImageId: itemImage.itemImageId }});

    const addEditor = () => {
        AddImageToEditor({editorRef, imagePath});
    };

    return (
        <div className="image-item-container pad-h-1">
            <Image className="image author-cover" fit="cover" src={imagePath} width={70} height={70} />
            <div className="image-item-wrapper">
                <div className="title">{itemImage.image.realName} / <span className="date">{distanceCreatedDate}</span></div>
                <div className="desc">{itemImage.image.desc}</div>
                <div className="operations">
                    <ButtonGroup minimal={true}>
                        <Switch className="default-image" defaultChecked={itemImage.isDefault} label="Set default" onClick={toggleDefaultItemImage} />
                        <Button className="add" onClick={addEditor}>Insert</Button>
                        <DeleteItemImageButton itemImageId={itemImage.itemImageId} itemId={itemImage.item.itemId} authorNo={itemImage.item.author.no} />
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};

interface IDeleteItemImage {
    itemImageId: number
    itemId: number
    authorNo: number
}

const DeleteItemImageButton = (props: IDeleteItemImage) => {
    const { itemImageId, itemId, authorNo } = props;

    const currentUser = getUserInfo();

    const DELETE_QUERY = itemImageId > 0 ? DELETE_IMAGE_LIBRARY_QUERY : DELETE_IMAGE_LIBRARY_QUERY_IN_CACHE;

    return (
        <Popover
            interactionKind={PopoverInteractionKind.CLICK}
            popoverClassName="bp3-popover-content-sizing"
        >
            <Button className="del">Delete</Button>
            <div>
                <H5>삭제</H5>
                <p>삭제하시겠습니까?</p>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
                    <Button className="bp3-popover-dismiss" style={{ marginRight: 10 }}>
                        Cancel
                    </Button>
                    <Mutation
                        mutation={ DELETE_QUERY }
                        update={(cache: any, { data }: any) => {
                            console.log(data);
                            const cacheData = cache.readQuery({
                                query: ITEM_IMAGES_IN_LIBRARY_QUERY,
                                variables: {
                                    itemId
                                }
                            });

                            const itemImages = cacheData.itemImages || [];

                            cache.writeQuery({
                                query: ITEM_IMAGES_IN_LIBRARY_QUERY,
                                variables: {
                                    itemId
                                },
                                data: {
                                    itemImages: itemImages.filter(((itemImage: IItemImage) => {
                                        return itemImage.itemImageId !== itemImageId;
                                    }))
                                }
                            })
                        }}
                    >
                        {(deleteImage: any) => (
                            <Button intent={Intent.PRIMARY} className="bp3-popover-dismiss"
                                    onClick={() => {
                                        if(!currentUser || !currentUser.no) {
                                            return ;
                                        }

                                        deleteImage({
                                            variables: {
                                                itemImage: {
                                                    itemImageId,
                                                    item: {
                                                        itemId,
                                                        author: {
                                                            no: authorNo
                                                        }
                                                    }
                                                }
                                            }
                                        })
                            }}>
                            Delete
                            </Button>
                        )}
                    </Mutation>
                </div>
            </div>
        </Popover>
    );
};