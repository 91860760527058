import * as React from "react";
import {
    ICategoryInfo, IUser
} from "../../common/Domains";
import {ItemsItems} from "../../item/Items";
import {SearchWrapper} from "../../search/SearchWrapper";

interface IProps {
    user: IUser
    largeCategory?: string
    middleCategory?: string
    smallCategory?: string
}

interface IStatus {
    keyword: string
    categoryInfo: ICategoryInfo
    userNo: number
}

class Items extends React.Component<IProps, IStatus> {
    constructor(props: IProps) {
        super(props);

        const { largeCategory, middleCategory, smallCategory, user } = props;

        const categoryInfo = { largeCategory, middleCategory, smallCategory} as ICategoryInfo;

        this.state = {
            keyword: "",
            categoryInfo,
            userNo: user.no
        }

        this.changeCategory = this.changeCategory.bind(this);
    }

    public render() {
        return (
            <div className="items-container">
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="sub-menu-wrapper">
                            <ul>
                                <li><span onClick={this.changeCategory} data-category="all" style={{cursor: "pointer"}}>All Items</span></li>
                                <li><span onClick={this.changeCategory} data-category="home" style={{cursor: "pointer"}}>Home Items</span></li>
                                <li><span onClick={this.changeCategory} data-category="shop" style={{cursor: "pointer"}}>Shop Items</span></li>
                                <li><span onClick={this.changeCategory} data-category="garden" style={{cursor: "pointer"}}>Garden Items</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="grid-container pad-t-1">
                    <div className="default-grid-container search-wrapper center">
                        <SearchWrapper searchHandler={this.changeSearch} />
                    </div>
                </div>
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="items-wrapper pad-t-1">
                            <ItemsItems keyword={this.state.keyword} categoryInfo={this.state.categoryInfo} userNo={this.state.userNo} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private changeCategory = (event: React.MouseEvent<HTMLAnchorElement>) => {
        const categoryValue = event.currentTarget.dataset.category;
        if(categoryValue) {
            const categoryInfo = {
                largeCategory: "",
                middleCategory: categoryValue,
                smallCategory: ""
            } as ICategoryInfo;

            this.setState({
                categoryInfo
            });
        }
    }

    private changeSearch = (keyword: string) => {
        this.setState({
            keyword
        });
    }
}

export default Items;