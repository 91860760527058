import * as React from "react";
import {gql} from "apollo-boost";
import {
    BOARD_ENV_METAS,
    BoardCategory,
    BoardType,
    getBoardCategoryId,
    getTags,
    IBoard,
    IBoardImage
} from "../common/Domains";
import {Button, H5} from "@blueprintjs/core";
import {RouteComponentProps} from "react-router-dom";
import {CardAlign, ICardData} from "../card/CardDomains";
import VCard from "../card/VCard";
import {SearchWrapper} from "../search/SearchWrapper";
import {useQuery} from "react-apollo-hooks";
import * as InfiniteScroll from 'react-infinite-scroller';
import {LoadVBox} from "../card/VBox";
import {CARD_WIDTH, LOAD_DATA_COUNT, LOAD_EMPTY_DATA_COUNT, SITE_TITLE} from "../common/Constant";

import "./Boards.css";
import HBox from "../card/HBox";
import {ErrorPage} from "../utils/LoadingAndError";
import {Helmet} from "react-helmet";

;

export const BOARDS_QUERY = gql `
    query Boards (
        $keyword: String,
        $categoryId: Int,
        $boardType: BoardType,
        $userNo: Int,
        $offset: Int,
        $limit: Int
    ) {
        boards(
            keyword: $keyword, categoryId: $categoryId, boardType: $boardType, userNo: $userNo, offset: $offset, limit: $limit
        ) {
            boardId
            category {
                categoryId
            }
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            modifiedDate
            title
            description
            content
            type
            status
            isDelete
            boardEnv {
                envId
            }
            boardImages {
                boardImageId
                image {
                    imageId
                    realName
                }
                isDefault
                createdDate
            }
            counter {
                counterId
                count
            }
        }
    }
`;

interface IProps extends RouteComponentProps {
    category: string
    keyword?: string
    userNo?: number
    boardType?: BoardType
}

interface IStatus {
    keyword: string
    userNo?: number
    category: string
    categoryId: BoardCategory
    boardType: BoardType
}

class Boards extends React.Component<IProps, IStatus> {
    constructor(props: IProps) {
        super(props);

        const { keyword, userNo, category, boardType } = this.props.match.params as IProps;

        const categoryId = getBoardCategoryId(category);

        this.state = {
            keyword: keyword || "",
            userNo: userNo || -1,
            category,
            categoryId,
            boardType: boardType || BoardType.NONE
        }
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IStatus>, nextContext: any): boolean {
        const { keyword, userNo, category, boardType } = nextProps.match.params as IProps;

        if(category !== this.state.category) {
            const categoryId = getBoardCategoryId(category);

            this.setState({
                keyword: keyword || "",
                userNo: userNo || -1,
                category,
                categoryId,
                boardType: boardType || BoardType.NONE
            });

            return true;
        }

        return false;
    }

    public render() {
        return (
            <div className="boards-container">
                <Helmet>
                    <title>{ this.state.category + ", " + SITE_TITLE }</title>
                    <link rel="canonical" href={ window.location.href } />
                    <meta name="description" content={ this.state.category} />
                    <meta property="og:url" content={ window.location.href } />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={ this.state.category + ", " + SITE_TITLE } />
                    <meta property="og:description" content={ this.state.category} />
                </Helmet>
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="header-wrapper-1">
                            <H5 className="title capitalize">{ this.state.category }</H5>
                        </div>
                    </div>
                </div>
                <div className="grid-container pad-t-2">
                    <div className="default-grid-container search-wrapper center">
                        <SearchWrapper searchHandler={this.changeSearch} />
                    </div>
                </div>
                <div className="grid-container pad-t-2">
                    <div className="default-grid-container">
                        <div className="boards-wrapper pad-t-1">
                            <BoardsItems keyword={this.state.keyword} category={this.state.category} categoryId={this.state.categoryId} boardType={this.state.boardType} userNo={this.state.userNo} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private changeSearch = (keyword: string) => {
        this.setState({
            keyword
        });
    }
}

export default Boards;

interface IBoardItems {
    keyword: string
    category: string
    categoryId: BoardCategory
    boardType: BoardType
    userNo?: number
}

export const BoardsItems = (props: IBoardItems) => {
    const { keyword, category, categoryId, boardType, userNo } = props;

    const { loading, error, data, fetchMore, networkStatus } = useQuery(BOARDS_QUERY, {
        variables: {
            keyword,
            categoryId,
            boardType,
            userNo,
            offset: 0,
            limit: LOAD_DATA_COUNT
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: false
    });

    if(loading || networkStatus === 4) {
        return <LoadingBox key="0" />;
    }

    if(error) {
        return <ErrorPage />;
    }

    const { boards } = data || { boards: null };

    if(!boards || boards.length === 0) {
        const emptyData = {
            id: "0",
            link: {
                path: ""
            },
            title: "No results"
        } as ICardData;

        return (
            <>
                <VCard key={emptyData.id} data={emptyData} large={true} width={CARD_WIDTH} interactive={true} isEmpty={true} />
                <div style={{clear: "left"}} />
            </>
        );
    }

    const offset = boards ? boards.length : 0;
    const hasMore = (offset !== 0 && offset % LOAD_DATA_COUNT === 0) ? true : false;

    const loadMoreHandler = () => {
        fetchMore({
            variables: {
                offset
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                if(!fetchMoreResult) {
                    return prev;
                }

                return Object.assign({}, prev, {
                    boards: [ ...prev.boards, ...fetchMoreResult.boards ]
                });
            }
        });
    };

    return (
        <>
            <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={loadMoreHandler}
                hasMore={hasMore}
                loader={<LoadingBox key="0" />}>
                {
                    (boards || []).map((board: IBoard) => {
                        const images = board.boardImages.filter((boardImage: IBoardImage) => {
                            return boardImage.isDefault;
                        })
                            .map((boardImage: IBoardImage) => {
                                return boardImage.image;
                            });

                        const boxData = {
                            id: board.boardId + "",
                            link: {
                                path: "/board/view/" + category + "/" + board.boardId,
                                localParams: {
                                    keyword,
                                    categoryId,
                                    boardType,
                                    userNo,
                                    offset: 0,
                                    limit: LOAD_DATA_COUNT
                                }
                            },
                            images,
                            date: board.createdDate,
                            title: board.title,
                            subTitle: "",
                            subSubTitle: "",
                            description: board.description,
                            author: board.author.displayName,
                            tags: getTags(BOARD_ENV_METAS, board.boardEnv)
                        } as ICardData;

                        return <HBox key={boxData.id} data={boxData} large={false} align={CardAlign.LEFT} />
                    })
                }
                <div style={{clear: "left"}} />
            </InfiniteScroll>
            <LoadMore loadMoreHandler={loadMoreHandler} />
        </>
    );
};

const LoadMore = ({ loadMoreHandler }: any) => {
    return (
        <div className="group-center pad-h-3">
            <Button minimal={true} onClick={loadMoreHandler} >Load more</Button>
        </div>
    );
};

export const LoadingBox = () => {
    return (
        <>
            {[...Array(LOAD_EMPTY_DATA_COUNT)].fill(0).map((_: any, index: number) => {
                const testData = {
                    id: index + "",
                    link: {
                        path: ""
                    },
                    title: "No results"
                } as ICardData;

                return <LoadVBox key={testData.id} data={testData} width={CARD_WIDTH} />;
            })}
            <div style={{clear: "left"}} />
        </>
    );
};