import {Popover, PopoverInteractionKind} from "@blueprintjs/core";
import SocialShare, {SocialShareType} from "./SocialShare";
import * as React from "react";

interface ISharePopoverProps {
    url: string
    title: string
}

export const SharePopover = (props: ISharePopoverProps) => {
    const { url, title } = props;

    return (
        <Popover
            interactionKind={PopoverInteractionKind.CLICK}
        >
            <span className="share">Share</span>
            <div>
                <ul className="bp3-menu">
                    <li>
                        <SocialShare className="bp3-menu-item"
                                     type={ SocialShareType.FACEBOOK }
                                     url={ url }
                                     title={ title }
                                     buttonText="Facebook" />
                    </li>
                    <li>
                        <SocialShare className="bp3-menu-item"
                                     type={ SocialShareType.NAVER }
                                     url={ url }
                                     title={ title }
                                     buttonText="Naver" />

                    </li>
                    <li>
                        <SocialShare className="bp3-menu-item"
                                     type={ SocialShareType.LINE }
                                     url={ url }
                                     title={ title }
                                     buttonText="Line" />
                    </li>
                </ul>
            </div>
        </Popover>
    );
};