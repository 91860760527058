import gql from "graphql-tag";
import { defaultDataIdFromObject } from 'apollo-cache-inmemory';
import {IBoardImage, IItemImage, IShopImage} from "../../components/common/Domains";
import {SHOP_IMAGES_IN_LIBRARY_QUERY} from "../../components/shop/image/ImageLibrary";
import {BOARD_IMAGES_IN_LIBRARY_QUERY} from "../../components/board/image/ImageLibrary";
import {ITEM_IMAGES_IN_LIBRARY_QUERY} from "../../components/item/image/ImageLibrary";

export const typeDefs = gql `
    directive @client(always: Boolean) on FIELD
    
#    extend type Query {
#        searchOptionsItems: SearchOptions
#    }

    extend type Mutation {
#        addSearchOptionsItems(keyword: String): SearchOptions

#        updateLocalItemComment(itemComment: InputItemComment): Boolean

        toggleDefaultBoardImage(boardImageId: Int): Boolean
        toggleDefaultItemImage(itemImageId: Int): Boolean
        toggleDefaultGardenImage(gardenImageId: Int): Boolean
        toggleDefaultShopImage(shopImageId: Int): Boolean

        deleteBoardImageInCache(boardImage: InputBoardImage): Boolean
        deleteItemImageInCache(itemImage: InputItemImage): Boolean
        deleteGardenImageInCache(gardenImage: InputGardenImage): Boolean
        deleteShopImageInCache(shopImage: InputShopImage): Boolean
    }
    
    type SearchOptions {
        keyword: String
    }
`;

export const resolvers = {
    // Query: {
    //     searchOptionsItems: (root: any, variables: any, { cache, getCacheKey }: any) => {
    //         console.log("test");
    //
    //         return {
    //             keyword: "eeee",
    //             __typename: "SearchOptions"
    //         };
    //     }
    // },
    Mutation: {
        // addSearchOptionsItems: (root: any, variables: any, { cache, getCacheKey }: any) => {
        //     console.log("sdd");
        //
        //     return null;
        // },
        // deleteItem: (root: any, variables: any, {cache, getCacheKey}: any) => {
        //   const id = getCacheKey({__typename: "Item", itemId: variables.itemId});
        //
        //   console.log(id);
        //   console.log(cache);
        // },
        // updateLocalItemComment: (root: any, variables: any, { cache, getCacheKey }: any) => {
        //     const id = getCacheKey({  __typename: 'ItemComment', commentId: variables.itemComment.commentId });
        //     const fragment = gql `
        //         fragment updateItemCommentContent on ItemComment {
        //             content
        //         }
        //     `;
        //
        //     const itemComment = cache.readFragment({ fragment, id});
        //
        //     if(itemComment) {
        //         cache.writeData({ id, data: { ...itemComment, content: variables.itemComment.content} });
        //     }
        //
        //     return true;
        // },

        toggleDefaultBoardImage: (root: any, variables: any, { cache, getCacheKey }: any) => {
            const id = getCacheKey({ __typename: 'BoardImage', boardImageId: variables.boardImageId});
            const fragment = gql `
                fragment defaultBoardImage on BoardImage {
                    isDefault
                }
            `;

            const boardImage = cache.readFragment({ fragment, id });

            if(boardImage) {
                cache.writeData({ id, data: { ...boardImage, isDefault: !boardImage.isDefault } });
            }

            return true;
        },
        toggleDefaultItemImage: (root: any, variables: any, { cache, getCacheKey }: any) => {
            const id = getCacheKey({ __typename: 'ItemImage', itemImageId: variables.itemImageId});
            const fragment = gql `
                fragment defaultItemImage on ItemImage {
                    isDefault
                }
            `;

            const itemImage = cache.readFragment({ fragment, id });

            if(itemImage) {
                cache.writeData({ id, data: { ...itemImage, isDefault: !itemImage.isDefault } });
            }

            return true;
        },
        toggleDefaultGardenImage: (root: any, variables: any, { cache, getCacheKey }: any) => {
            const id = getCacheKey({ __typename: 'GardenImage', gardenImageId: variables.gardenImageId});
            const fragment = gql `
                fragment defaultGardenImage on GardenImage {
                    isDefault
                }
            `;

            const gardenImage = cache.readFragment({ fragment, id });

            if(gardenImage) {
                cache.writeData({ id, data: { ...gardenImage, isDefault: !gardenImage.isDefault } });
            }

            return true;
        },
        toggleDefaultShopImage: (root: any, variables: any, { cache, getCacheKey }: any) => {
            const id = getCacheKey({ __typename: 'ShopImage', shopImageId: variables.shopImageId});
            const fragment = gql `
                fragment defaultShopImage on ShopImage {
                    isDefault
                }
            `;

            const shopImage = cache.readFragment({ fragment, id });

            if(shopImage) {
                cache.writeData({ id, data: { ...shopImage, isDefault: !shopImage.isDefault } });
            }

            return true;
        },
        deleteBoardImageInCache: (root: any, variables: any, { cache, getCacheKey }: any) => {
            const boardImages = cache.readQuery({
                query: BOARD_IMAGES_IN_LIBRARY_QUERY,
                variables: {
                    boardId: variables.boardImage.board.boardId
                }
            }) || [];

            cache.writeQuery({
                query: BOARD_IMAGES_IN_LIBRARY_QUERY,
                variables: {
                    boardId: variables.boardImage.board.boardId
                },
                data: {
                    boardImages: boardImages.boardImages.filter((boardImage: IBoardImage) => {
                        return boardImage.boardImageId !== variables.boardImage.boardImageId;
                    })
                }
            });

            return true;
        },
        deleteItemImageInCache: (root: any, variables: any, { cache, getCacheKey }: any) => {
            const itemImages = cache.readQuery({
                query: ITEM_IMAGES_IN_LIBRARY_QUERY,
                variables: {
                    itemId: variables.itemImage.item.itemId
                }
            }) || [];

            cache.writeQuery({
                query: ITEM_IMAGES_IN_LIBRARY_QUERY,
                variables: {
                    itemId: variables.itemImage.item.itemId
                },
                data: {
                    itemImages: itemImages.itemImages.filter((itemImage: IItemImage) => {
                        return itemImage.itemImageId !== variables.itemImage.itemImageId;
                    })
                }
            });

            return true;
        },
        deleteShopImageInCache: (root: any, variables: any, { cache, getCacheKey }: any) => {
            const shopImages = cache.readQuery({
                query: SHOP_IMAGES_IN_LIBRARY_QUERY,
                variables: {
                    shopId: variables.shopImage.shop.shopId
                }
            }) || [];

            cache.writeQuery({
                query: SHOP_IMAGES_IN_LIBRARY_QUERY,
                variables: {
                    shopId: variables.shopImage.shop.shopId
                },
                data: {
                    shopImages: shopImages.shopImages.filter((shopImage: IShopImage) => {
                        return shopImage.shopImageId !== variables.shopImage.shopImageId;
                    })
                }
            });

            return true;
        }
    }
};

export const dataIdFromObject = (object: any) =>  {
    switch (object.__typename) {
        case 'Board': return "Board." + object.boardId;
        case 'BoardComment': return "BoardComment." + object.commentId;
        case 'BoardImage': return "BoardImage." + object.boardImageId;
        case 'Item': return "Item." + object.itemId;
        case 'ItemComment': return "ItemComment." + object.commentId;
        case 'ItemImage': return "ItemImage." + object.itemImageId;
        case 'Shop': return "Shop." + object.shopId;
        case 'ShopComment': return "ShopComment." + object.commentId;
        case 'ShopImage': return "ShopImage." + object.shopImageId;
        case 'Image': return "Image." + object.imageId;

        default: return defaultDataIdFromObject(object);
    }
};