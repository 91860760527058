import * as React from "react";
import {Mutation} from "react-apollo";
import {Button, ButtonGroup, H5, Intent, Popover, TextArea} from "@blueprintjs/core";
import {getUserInfo, isUserLogin} from "../../../../common/UserInfo";
import {address} from "ip";
import {useMutation} from "react-apollo-hooks";
import {PopoverInteractionKind} from "@blueprintjs/core/lib/esm/components/popover/popover";
import {IItemComment} from "../../../../common/Domains";
import {gql} from "apollo-boost";
import {ITEM_COMMENTS_QUERY} from "./Comments";
import {useForm} from "react-hook-form";

const ADD_ITEM_COMMENT_QUERY = gql `
    mutation AddItemComment(
        $itemComment: InputItemComment
    ) {
        addItemComment(
            itemComment: $itemComment
        ) {
            commentId
            author {
                no
                imageUrl
                displayName
                description
            }
            authorIp
            createdDate
            content
            commentType
            item {
                itemId
            }
        }
    }
`;

const UPDATE_ITEM_COMMENT_QUERY = gql `
    mutation UpdateItemComment(
        $itemComment: InputItemComment
    ) {
        updateItemComment(
            itemComment: $itemComment
        ) {
            commentId
            content
        }
    }
`;

const DELETE_ITEM_COMMENT_QUERY = gql `
    mutation DeleteItemComment(
        $itemComment: InputItemComment
    ) {
        deleteItemComment(
            itemComment: $itemComment
        ) {
            commentId
            item {
                itemId
            }
        }
    }
`;

interface IEditableCommentProps {
    commentId: number
    content: string
    userNo: number
    isEditable: boolean
    onChangeEditable?: (isEditable: boolean) => void
    onChangeContent?: (value: string) => void
}

interface IEditableCommentState {
    editContent: string
}

class EditableComment extends React.Component<IEditableCommentProps, IEditableCommentState> {
    constructor(props: IEditableCommentProps) {
        super(props);

        this.state = {
            editContent: this.props.content
        };

        this.cancelSave = this.cancelSave.bind(this);
    }

    public render() {
        const { commentId, content, userNo, isEditable } = this.props;

        if(!isEditable) {
            return <div className="content" dangerouslySetInnerHTML={{__html: content}} />;
        }

        return (
            <Mutation
                mutation={ UPDATE_ITEM_COMMENT_QUERY }
                onCompleted={(data: any) => {
                    this.disableEditable();
                }}
            >
                {(updateItemComment: any) => (
                    <div>
                        <div className="content modifier" contentEditable={true} onInput={this.changeEditContent}
                             dangerouslySetInnerHTML={{__html: content}} />
                        <div className="operations">
                            <ButtonGroup minimal={true}>
                                <Button onClick={this.cancelSave}>Cancel</Button>
                                <Button onClick={() => {
                                    updateItemComment({
                                        variables: {
                                            itemComment: {
                                                commentId,
                                                author: {
                                                    no: userNo
                                                },
                                                content: this.state.editContent,
                                                commentType: "item"
                                            }
                                        },
                                        optimisticResponse: {
                                            __typename: "Mutation",
                                            updateItemComment: {
                                                commentId,
                                                content: this.state.editContent,
                                                __typename: "ItemComment"
                                            }
                                        }
                                    });
                                }}>Save</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                )}
            </Mutation>
        );
    }

    private disableEditable = () => {
        this.changeEditable(false);
    };

    private changeEditable = (isEditable: boolean) => {
        if(this.props.onChangeEditable) {
            this.props.onChangeEditable(isEditable);
        }
    };

    private cancelSave = () => {
        this.disableEditable();
    }

    private changeEditContent = (event: React.SyntheticEvent<HTMLDivElement>) => {
        this.setState({
            editContent: event.currentTarget.innerHTML
        });
    };
};

export default EditableComment;

interface IAddCommentProps {
    itemId: number
}

export const AddComment = (props: IAddCommentProps) => {
    const { itemId } = props;

    const currentUser = getUserInfo();
    const isLogined = isUserLogin(currentUser);

    // METHOD
    const { register, handleSubmit, errors, setValue} = useForm();

    // ADD
    const [ addItemComment ] = useMutation(
        ADD_ITEM_COMMENT_QUERY,
        {
            update: (cache: any, { data }: any) => {
                const cacheData = cache.readQuery({
                    query: ITEM_COMMENTS_QUERY,
                    variables: {
                        itemId,
                        offset: 0,
                        limit: 10
                    }
                });

                const comments = cacheData.itemComments || [];

                cache.writeQuery({
                    query: ITEM_COMMENTS_QUERY,
                    variables: {
                        itemId,
                        offset: 0,
                        limit: 10
                    },
                    data: {
                        itemComments: [data.addItemComment, ...comments]
                    }
                });
            },
        });

    const addItemCommentSubmit = (formData: any) => {
        if(!formData.content || !currentUser || !currentUser.no) {
            return ;
        }

        const authorIp = address();

        addItemComment({
            variables: {
                itemComment: {
                    itemId,
                    author: {
                        no: currentUser.no
                    },
                    authorIp,
                    content: formData.content,
                    commentType: "item"
                }
            }
        });

        // CLEAR CONTENT
        setValue("content", "");
    };

    return (
        <form onSubmit={handleSubmit(addItemCommentSubmit)}>
            <TextArea name="content" className="editor"
                      fill={true} large={false} growVertically={true}
                      inputRef={register({required: true, minLength: 1})}
            />
            <div className="valid-error-wrapper">
                {errors.content && errors.content.type === "required" && (
                    <p className="valid-error">리뷰을 입력해주세요.</p>
                )}
                {errors.content && errors.content.type === "minLength" && (
                    <p className="valid-error">리뷰을 3자 이상 입력해주세요.</p>
                )}
            </div>
            <div className="group-right">
                <Button type="submit" className="margin-h-1" minimal={true} disabled={!isLogined}>Post Review</Button>
            </div>
        </form>
    );
};

interface IModifyButtonProps {
    commentId: number
    itemId: number
    changeModifyState: (isEnable: boolean) => void
}

export const ModifyButton = (props: IModifyButtonProps) => {
    return (
        <Button minimal={true} onClick={() => {
            if(props.changeModifyState) {
                props.changeModifyState(true);
            }
        }}>
            Modify
        </Button>
    );
};

interface IDeleteButtonProps {
    commentId: number
    itemId: number
}

export const DeleteButton = (props: IDeleteButtonProps) => {
    const { commentId, itemId } = props;

    const currentUser = getUserInfo();

    return (
        <Popover
            interactionKind={ PopoverInteractionKind.CLICK }
            popoverClassName="bp3-popover-content-sizing"
        >
            <Button className="delete">Delete</Button>
            <div>
                <H5>삭제</H5>
                <p>삭제하시겠습니까?</p>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
                    <Button className="bp3-popover-dismiss" style={{ marginRight: 10 }}>
                        Cancel
                    </Button>
                    <Mutation
                        mutation={ DELETE_ITEM_COMMENT_QUERY }
                        update={(cache: any, {data}: any) => {
                            const cacheData = cache.readQuery({
                                query: ITEM_COMMENTS_QUERY,
                                variables: {
                                    itemId,
                                    offset: 0,
                                    limit: 10
                                }
                            });

                            const comments = cacheData.itemComments || [];

                            cache.writeQuery({
                                query: ITEM_COMMENTS_QUERY,
                                variables: {
                                    itemId,
                                    offset: 0,
                                    limit: 10
                                },
                                data: {
                                    itemComments: comments.filter((comment: IItemComment) => {
                                        return comment.commentId !== data.deleteItemComment.commentId;
                                    })
                                }
                            });
                        }}
                    >
                        {(deleteItemComment: any) =>(
                            <Button intent={Intent.PRIMARY} className="bp3-popover-dismiss"
                                    onClick={() => {
                                        if(!currentUser || !currentUser.no) {
                                            return ;
                                        }

                                        deleteItemComment({
                                            variables: {
                                                itemComment: {
                                                    commentId,
                                                    itemId,
                                                    author: {
                                                        no: currentUser.no
                                                    },
                                                    commentType: "item"
                                                }
                                            }
                                        });
                                    }}>
                                Delete
                            </Button>
                        )}
                    </Mutation>
                </div>
            </div>
        </Popover>
    );
};