import * as React from "react";
import {Card, Elevation, H5, H6, Icon, NonIdealState} from "@blueprintjs/core";
import {distanceInWordsToNow} from "date-fns";
import {Link} from "react-router-dom";
import Dotdotdot from "react-dotdotdot";
import {ChromePicker, ColorResult} from 'react-color';

import './SmallBrandCard.css';
import './BrandCard.css';
import ContentLoader from "react-content-loader";
import {ICardData, ICardTags} from "../../../card/CardDomains";
import {useState} from "react";
import {useMutation, useQuery} from "react-apollo-hooks";
import {gql} from "apollo-boost";
import {ErrorPage, LoadingPage} from "../../../utils/LoadingAndError";
import {ITotalBrandColor} from "../../../common/Domains";
import {getUserInfo, isUserLogin} from "../../../common/UserInfo";

interface ICardProps {
    data: ICardData
    large?: boolean
    titleLine?: number
    descriptionLine?: number
    interactive?: boolean
    width?: number
    height?: number
    isEmpty?: boolean
}

interface ICardState {
    data: ICardData
    large: boolean
    titleLine: number
    descriptionLine: number
    interactive: boolean
}

class BrandCard extends React.Component<ICardProps, ICardState> {
    constructor(props: ICardProps) {
        super(props);

        this.state = {
            data: props.data || {},
            large: props.large || false,
            titleLine: props.titleLine || 1,
            descriptionLine: props.descriptionLine || 1,
            interactive: props.interactive || true
        }
    }

    public render() {
        const {
            data,
            large,
            titleLine,
            descriptionLine,
            interactive
        } = this.state;

        const {
            isEmpty,
            width
        } = this.props;

        const distanceCreatedDate = distanceInWordsToNow(data.date);

        if (isEmpty) {
            return <EmptyBrandCard data={data} large={large} titleLine={titleLine} descriptionLine={descriptionLine}
                                      interactive={interactive} width={width}/>;
        }

        return (
            <Card className={large ? 'brand-card' : 'small-brand-card'}
                  interactive={interactive} elevation={Elevation.ZERO}>
                <div className="brand-colors">
                    <BrandColors totalBrandId={Number(data.id)} userNo={data.extra.author.no} />
                </div>
                <div className="summary">
                    {(data.subTitle || data.subSubTitle) && (
                        <H6 className="name">
                            <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                            <span className="sub-title">
                                <Dotdotdot clamp={titleLine}>{data.subTitle + ' | ' + data.subSubTitle}</Dotdotdot>
                            </span>
                            </Link>
                        </H6>
                    )}
                    <H5 className="title">
                        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                            <Dotdotdot clamp={titleLine}>{data.title}</Dotdotdot>
                        </Link>
                    </H5>
                    {/*<div className="description">*/}
                    {/*    <Link to={{pathname: data.link.path, state: data.link.localParams}}>*/}
                    {/*        <EditableDescription descriptionLine={descriptionLine} description={data.description} />*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                    <div className="value">
                        {data.value}
                    </div>
                    <ICardTags tags={data.tags}/>
                    <div className="like">
                        <Icon icon="heart" iconSize={Icon.SIZE_LARGE}/>
                    </div>
                    <div className="author">{data.author}</div>
                    <div className="date">{distanceCreatedDate}</div>
                </div>
            </Card>
        );
    }
}

const EmptyBrandCard = ({data, large, interactive, width}: ICardProps) => {
    return (
        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
            <Card className={large ? 'brand-card' : 'small-brand-card'} style={{width: width ? width + "%" : "100%"}} interactive={interactive} elevation={Elevation.ZERO}>
                <NonIdealState
                    title={data.title}
                    description={data.description}
                />
            </Card>
        </Link>
    );
};

export default BrandCard;

export const LoadBrandCard = ({large, width}: ICardProps) => {
    return (
        <div className={large ? 'brand-card' : 'small-brand-card'}>
            <ContentLoader width={400} height={280}>
                <rect x={0} y={15} rx={5} ry={10} width={400} height={20} />
                <rect x={0} y={55} rx={5} ry={10} width={250} height={30} />
                <rect x={0} y={105} rx={5} ry={10} width={400} height={20} />
                <rect x={0} y={130} rx={5} ry={5} width={280} height={20} />
                <rect x={0} y={160} rx={5} ry={5} width={100} height={20} />
            </ContentLoader>
        </div>
    );
};
//
// interface IDescriptionProps {
//     descriptionLine: number
//     description: string
// }
//
// const EditableDescription = (props: IDescriptionProps) => {
//     const { descriptionLine, description } = props;
//
//     const [isEditable, setIsEditable ] = useState(false);
//     const [content, setContent] = useState(description);
//
//     const openEditableHandler = () => {
//         setIsEditable(true);
//     };
//
//     const closeEditableHandler = () => {
//         setIsEditable(false);
//     };
//
//     if(!isEditable) {
//         return (
//             <Dotdotdot clamp={descriptionLine}><span onDoubleClick={openEditableHandler} dangerouslySetInnerHTML={{__html: content}} /></Dotdotdot>
//         );
//     }
//
//     const changeHandler = (event: React.FormEvent<HTMLDivElement>) => {
//         setContent((event.currentTarget.innerHTML));
//     };
//
//     const saveHandler = () => {
//         console.log(content);
//
//         closeEditableHandler();
//     };
//
//     return (
//         <div className="editable-description">
//             <div className="content modifier" contentEditable={true} onInput={changeHandler} dangerouslySetInnerHTML={{__html: description }}/>
//             <div className="group-center">
//                 <Button minimal={true} onClick={closeEditableHandler}>Cancel</Button>
//                 <Button minimal={true} onClick={saveHandler}>Save</Button>
//             </div>
//         </div>
//     );
// };

const GET_TOTAL_BRAND_COLOR_QUERY = gql `
    query TotalBrandColors($totalBrandId: Int) {
        totalBrandColors(totalBrandId: $totalBrandId) {
            brandColorId
            colorType
            color
        }
    }
`;

const UPDATE_TOTAL_BRAND_COLOR_QUERY = gql `
    mutation UpdateTotalBrandColor($totalBrandId: Int, $colorType: TotalBrandColorType, $color: String) {
        updateTotalBrandColor(totalBrandId: $totalBrandId, colorType: $colorType, color: $color) {
            colorType
            color
        }
    }
`;

interface IBrandColorsProps {
    totalBrandId: number
    userNo: number
}

const BrandColors = (props: IBrandColorsProps) => {
    const { loading, errors, data, networkStatus} = useQuery(GET_TOTAL_BRAND_COLOR_QUERY, {
        variables: {
            totalBrandId: props.totalBrandId
        }
    });

    if(loading || networkStatus === 4) {
        return <LoadingPage isOnlyMessage={true} />;
    }

    if(errors) {
        return <ErrorPage isOnlyMessage={true} />;
    }

    const { totalBrandColors } = data || { totalBrandColors: null };

    if(!totalBrandColors) {
        return <ErrorPage isOnlyMessage={true} />
    }

    return (
        <>
            { (totalBrandColors || []).map((totalBrandColor: ITotalBrandColor) => {
                return (
                    <BrandColor key={totalBrandColor.brandColorId}
                                      totalBrandId={props.totalBrandId}
                                      colorType={totalBrandColor.colorType}
                                      defaultColor={totalBrandColor.color}
                                   userNo={props.userNo}
                    />
                );
            })}
        </>
    );
}

interface IBrandColorProps {
    totalBrandId: number
    colorType: string
    defaultColor: string
    colorHandler?: (color: string) => void
    userNo: number
}

const BrandColor = (props: IBrandColorProps) => {
    const { defaultColor, colorHandler, userNo } = props;

    const currentUser = getUserInfo();
    const isLogined = isUserLogin(currentUser);

    const isUpdateable = isLogined && (userNo === currentUser.no);

    const [ isOpenColorPicker, setOpenColorPicker ] = useState(false);
    const [ color, setColor] = useState(defaultColor);

    const [ changeTotalBrandColor ] = useMutation(UPDATE_TOTAL_BRAND_COLOR_QUERY);

    const changeColorHandler = (tempColor: ColorResult) => {
        setColor(tempColor.hex);

        if(colorHandler) {
            colorHandler(tempColor.hex);
        }
    };

    const openColorPickerHandler = () => {
        if(!isOpenColorPicker) {
            setOpenColorPicker(true);
        }
    };

    const closeColorPickerHandler = () => {
        if(isOpenColorPicker) {
            setOpenColorPicker(false);
        }

        if(isUpdateable) {
            changeTotalBrandColor({
                variables: {
                    totalBrandId: props.totalBrandId,
                    colorType: props.colorType,
                    color
                }
            });
        }
    };

    if(!isUpdateable) {
        return <div className="brand-color brand-color-1" style={{backgroundColor: color}} />;
    }
    else {
        return (
            <div className="brand-color brand-color-1" style={{backgroundColor: color}} onClick={openColorPickerHandler}>
                {isOpenColorPicker && (
                    <div className="brand-color-picker">
                        <div className="brand-color-picker-cover" onClick={closeColorPickerHandler}/>
                        <ChromePicker color={color} onChange={changeColorHandler}/>
                    </div>
                )}
            </div>
        );
    }
};
