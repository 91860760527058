import {gql} from "apollo-boost";
import {IShop, IShopLike, IUser} from "../common/Domains";
import {getUserInfo, isUserLogin} from "../common/UserInfo";
import {useMutation, useQuery}from "react-apollo-hooks";
import {Icon} from "@blueprintjs/core";
import * as React from "react";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";

const SHOP_LIKES_QUERY = gql `
    query ShopLikes($shopId: Int) {
        shopLikes(shopId: $shopId) {
            likeId
            shop {
                shopId
            }
            userNo
            createdDate
        }
    }
`;

const TOGGLE_SHOP_LIKE_QUERY = gql `
    mutation ToggleShopLike($shopLike: InputShopLike) {
        toggleShopLike(shopLike: $shopLike) {
            likeId
            shop {
                shopId
            }
            userNo
            createdDate
        }
    }
`;

interface IToggleShopLikeProps {
    shop: IShop
}

export const ToggleShopLikeWrapper = (props: IToggleShopLikeProps) => {
    const currentUser = getUserInfo();

    const isExistsMyLike = (likes: IShopLike[]) => {
        const myLike = likes.filter((like: IShopLike) => {
            return like.userNo === currentUser.no;
        });

        return (myLike && myLike.length > 0 ? true : false);
    }

    const { toggleShopLike } = doToggleShopLike(currentUser, props.shop);

    const {loading, error, data } = useQuery(SHOP_LIKES_QUERY, { variables: { shopId: props.shop.shopId }});

    if(loading) {
        return <LoadingPage isOnlyMessage={true} />;
    }

    if(error) {
        return <ErrorPage isOnlyMessage={true} />;
    }

    const {shopLikes} = data || { shopLikes: null };

    if(!shopLikes) {
        return <ErrorPage isOnlyMessage={true} />;
    }

    const isMarked = isExistsMyLike(shopLikes);

    return (
        <>
            { isUserLogin(currentUser) && <Icon icon="heart" iconSize={15} className={isMarked ? "marked" : ""} onClick={toggleShopLike} /> }
            { !isUserLogin(currentUser) && <Icon icon="heart" iconSize={15} /> }
            &nbsp;
            {shopLikes.length} Like
            {/*{shopLikes.map((shopLike: IShopLike) => {*/}
            {/*    return <span key={shopLike.userNo}>{shopLike.userNo}</span>;*/}
            {/*})}*/}
        </>
    );
};

const doToggleShopLike = (user: IUser, shop: IShop) => {
    const [ toggleShopLike ]: any = useMutation(TOGGLE_SHOP_LIKE_QUERY, {
        variables: {
            shopLike: {
                shop: {
                    shopId: shop.shopId
                },
                userNo: user.no
            }
        },
        update: (proxy, { data }: any) => {
            const { shopLikes }: any = proxy.readQuery({
                query: SHOP_LIKES_QUERY,
                variables: {
                    shopId: shop.shopId
                }
            });

            if(data.toggleShopLike) {
                // ADD
                proxy.writeQuery({
                    query: SHOP_LIKES_QUERY,
                    variables: { shopId: shop.shopId },
                    data: { shopLikes: shopLikes.concat([ data.toggleShopLike ]) }
                });
            }
            else {
                // DELETE
                const newShopLikes = shopLikes.filter((shopLike: IShopLike) => {
                    return shopLike.userNo !== user.no;
                });

                proxy.writeQuery({
                    query: SHOP_LIKES_QUERY,
                    variables: { shopId: shop.shopId },
                    data: { shopLikes: newShopLikes }
                });
            }
        }
    });

    return { toggleShopLike };
};