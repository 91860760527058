import * as React from "react";

interface IProps {
    mapId: string
    latitude: number
    longitude: number
    level: number
    title: string
    width: number
    widthUnit: string
    height: number
    heightUnit: string
    mapStyle: GoogleMapStyle
}

export enum GoogleMapStyle {
    DEFAULT = "default",
    SILVER = "silver"
}

class GoogleMap extends React.Component<IProps, {}> {
    public componentDidMount(): void {
        const googleMap = (window as any).google;

        const position = {
            lat: this.props.latitude,
            lng: this.props.longitude
        };

        const map = new googleMap.maps.Map(document.getElementById('map_' + this.props.mapId), {
                center: position,
                zoom: this.props.level,
                mapTypeControlOptions: {
                    mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain',
                        'styled_map']
                }
            });

        if(this.props.mapStyle && this.props.mapStyle !== GoogleMapStyle.DEFAULT) {
            const styledMapType = new googleMap.maps.StyledMapType(MapStyles[this.props.mapStyle]);

            map.mapTypes.set('styled_map', styledMapType);
            map.setMapTypeId('styled_map');
        }

        const marker = new googleMap.maps.Marker({
                            position,
                            map,
                            // icon: {
                            //     path: googleMap.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                            //     scale: 5
                            // },
                            title: this.props.title
        });

        if(marker) {
            // DO-NOTHING
        }

        const infoWindow = new googleMap.maps.InfoWindow({
            content: this.props.title,
            maxWidth: 200
        });

        infoWindow.open(map, marker);
    }

    public render() {
        const { mapId, width, widthUnit, height, heightUnit } = this.props;

        return (
            <div id={"map_" + mapId} style={{width: width + widthUnit, height: height + heightUnit }} />
        );
    }
}

const MapStyles = {
    silver:
        [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            }
        ]
}

export default GoogleMap;