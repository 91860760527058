import {gql} from "apollo-boost";
import {IItem, IItemLike, IUser} from "../common/Domains";
import {getUserInfo, isUserLogin} from "../common/UserInfo";
import {useMutation, useQuery}from "react-apollo-hooks";
import {Icon} from "@blueprintjs/core";
import * as React from "react";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";

const ITEM_LIKES_QUERY = gql `
    query ItemLikes($itemId: Int) {
        itemLikes(itemId: $itemId) {
            likeId
            item {
                itemId
            }
            userNo
            createdDate
        }
    }
`;

const TOGGLE_ITEM_LIKE_QUERY = gql `
    mutation ToggleItemLike($itemLike: InputItemLike) {
        toggleItemLike(itemLike: $itemLike) {
            likeId
            item {
                itemId
            }
            userNo
            createdDate
        }
    }
`;

interface IToggleItemLikeProps {
    item: IItem
}

export const ToggleItemLikeWrapper = (props: IToggleItemLikeProps) => {
    const currentUser = getUserInfo();

    const isExistsMyLike = (likes: IItemLike[]) => {
        const myLike = likes.filter((like: IItemLike) => {
            return like.userNo === currentUser.no;
        });

        return (myLike && myLike.length > 0 ? true : false);
    }

    const { toggleItemLike } = doToggleItemLike(currentUser, props.item);

    const {loading, error, data } = useQuery(ITEM_LIKES_QUERY, { variables: { itemId: props.item.itemId }});

    if(loading) {
        return <LoadingPage isOnlyMessage={true} />;
    }

    if(error) {
        return <ErrorPage isOnlyMessage={true} />;
    }

    const {itemLikes} = data || { itemLikes: null };

    if(!itemLikes) {
        return <ErrorPage isOnlyMessage={true} />;
    }

    const isMarked = isExistsMyLike(itemLikes);

    return (
        <>
            { isUserLogin(currentUser) && <Icon icon="heart" iconSize={15} className={isMarked ? "marked" : ""} onClick={toggleItemLike} /> }
            { !isUserLogin(currentUser) && <Icon icon="heart" iconSize={15} /> }
            &nbsp;
            {itemLikes.length}
            {/*{itemLikes.map((itemLike: IItemLike) => {*/}
            {/*    return <span key={itemLike.userNo}>{itemLike.userNo}</span>;*/}
            {/*})}*/}
        </>
    );
}

const doToggleItemLike = (user: IUser, item: IItem) => {
    const [ toggleItemLike ]: any = useMutation(TOGGLE_ITEM_LIKE_QUERY, {
        variables: {
            itemLike: {
                item: {
                    itemId: item.itemId
                },
                userNo: user.no
            }
        },
        update: (proxy, { data }: any) => {
            const { itemLikes }: any = proxy.readQuery({
                query: ITEM_LIKES_QUERY,
                variables: {
                    itemId: item.itemId
                }
            });

            if(data.toggleItemLike) {
                // ADD
                proxy.writeQuery({
                    query: ITEM_LIKES_QUERY,
                    variables: { itemId: item.itemId },
                    data: { itemLikes: itemLikes.concat([ data.toggleItemLike ]) }
                });
            }
            else {
                // DELETE
                const newItemLikes = itemLikes.filter((itemLike: IItemLike) => {
                    return itemLike.userNo !== user.no;
                });

                proxy.writeQuery({
                    query: ITEM_LIKES_QUERY,
                    variables: { itemId: item.itemId },
                    data: { itemLikes: newItemLikes }
                });
            }
        }
    });

    return { toggleItemLike };
};