import * as React from "react";
import {Query} from "react-apollo";
import Items from "./view/Items";
import {Tab, TabId, Tabs} from "@blueprintjs/core";
import {Profile, ProfileType} from "./Profile";
import {RouteComponentProps} from "react-router-dom";
import {gql} from "apollo-boost";
import {ErrorPage, LoadingPage} from "../utils/LoadingAndError";
import {Summary} from "./view/Summary";

import "./IAm.css";

const USER_QUERY = gql `
    query User($userNo: Int) {
        user(userNo: $userNo) {
            no
            id
            name
            displayName
            description
            email
            emailVerified
            imageUrl
            provider
            providerId
            registeredDate
        }
    }
`

interface IProps extends RouteComponentProps {
    userNo?: number
}

interface IState {
    selectedSubTabId: TabId
}

class Follow extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            selectedSubTabId: "summary"
        }
    }

    public render() {
        const { userNo } = this.props.match.params as IProps;

        if(!userNo) {
            return <div>error...</div>;
        }

        return (
            <Query
                query = {USER_QUERY}
                variables={{
                    userNo
                }}
                fetchPolicy="network-only"
            >
                {({loading, error, data}: any) => {
                    if(loading) {
                        return <LoadingPage />;
                    }

                    if(error) {
                        return <ErrorPage />;
                    }

                    if(!data || !data.user) {
                        return <div>not login</div>
                    }

                    const { user } = data || { user: null };

                    return (
                        <div>
                            <div className="grid-container pad-t-3">
                                <div className="default-grid-container">
                                    <Profile user={user} profileType={ProfileType.DEFAULT} large={true} />
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="default-grid-container pad-t-2">
                                    <Tabs id="user-info-tabs" large={true} animate={true} renderActiveTabPanelOnly={true} selectedTabId={this.state.selectedSubTabId} onChange={this.setSelectedSubTabId}>
                                        <Tab id="summary" title="Summary" panel={<Summary user={user} />} />
                                        <Tab id="items" title="Items" panel={<Items user={user} />} />
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    }

    private setSelectedSubTabId = (selectedSubTabId: TabId) => {
        this.setState({
            selectedSubTabId
        });
    }
}

export default Follow;