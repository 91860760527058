import * as React from "react";


export enum SocialShareType {
    FACEBOOK = "FACEBOOK",
    NAVER = "NAVER",
    LINE = "LINE"
}

interface ISocialShareProps {
    className: string
    type: SocialShareType
    url: string
    title: string
    buttonText: string
}

class SocialShare extends React.Component<ISocialShareProps, any> {
    public render() {
        const { className, type, url, title, buttonText } = this.props;

        const encodedUrl = encodeURIComponent(url);
        const encodedTitle = encodeURIComponent(title);

        if(type === SocialShareType.FACEBOOK) {
            return <FacebookShare className={className} url={encodedUrl} title={encodedTitle} buttonText={buttonText} />
        }
        else if(type === SocialShareType.NAVER) {
            return <NaverShare className={className}  url={encodedUrl} title={encodedTitle} buttonText={buttonText} />
        }
        else if(type === SocialShareType.LINE) {
            return <LineShare className={className}  url={encodedUrl} title={encodedTitle} buttonText={buttonText} />
        }

        return null;
    }
}

export default SocialShare;

interface ISocialShareItemProps {
    className: string
    url: string
    title: string
    buttonText: string
}

const FacebookShare = (props: ISocialShareItemProps) => {
    const { className, url, buttonText} = props;

    return (
        <a target="_blank"
           className={className}
           href={ "https://www.facebook.com/sharer/sharer.php?u=" + url + "&src=sdkpreparse" }
        >{ buttonText }</a>
    );
};

const NaverShare = (props: ISocialShareItemProps) => {
    const { className, url, title, buttonText } = props;

    return (
        <a target="_blank"
           className={className}
           href={ "https://share.naver.com/web/shareView.nhn?url=" + url + "&title=" + title }
        >{ buttonText }</a>
    );
};

const LineShare = (props: ISocialShareItemProps) => {
    const { className, url, buttonText } = props;

    return (
        <a target="_blank"
           className={className}
           href={ "https://social-plugins.line.me/lineit/share?url=" + url }
        >{ buttonText }</a>
    );
};
