import * as React from "react";
import {gql} from "apollo-boost";
import {
    getShopCategoryId,
    getTags, IShop, IShopImage,
    SHOP_ENV_METAS, ShopCategory,
    ShopType
} from "../common/Domains";
import {Button, H5} from "@blueprintjs/core";
import {RouteComponentProps} from "react-router-dom";
import {ICardData} from "../card/CardDomains";
import VBox from "../card/VBox";
import {useQuery} from "react-apollo-hooks";
import {CARD_WIDTH, LOAD_DATA_COUNT} from "../common/Constant";
import * as InfiniteScroll from "react-infinite-scroller";
import {LoadingBox} from "../item/Items";
import {ErrorPage} from "../utils/LoadingAndError";
import {getUserInfo} from "../common/UserInfo";

import "./Shops.css";

const TEMPORARY_SHOPS_QUERY = gql `
    query TemporaryShops (
        $keyword: String,
        $categoryId: Int,
        $userNo: Int,
        $shopType: ShopType,
        $offset: Int,
        $limit: Int
    ) {
      temporaryShops(
        keyword: $keyword, categoryId: $categoryId, userNo: $userNo, shopType: $shopType, offset: $offset, limit: $limit
      ) {
          shopId
          category {
              categoryId
          }
          author {
              no
              imageUrl
              displayName
              description
          }
          authorIp
          createdDate
          modifiedDate
          name
          description
          content
          type
          status
          isDelete
          shopEnv {
              envId
              location
              latitude
              longitude
              country
              isClosed
              openingTime
              closingTime
              homePageUrl
              telNumber
              keywords
          }
          shopImages {
              shopImageId
              image {
                  imageId
                  realName
              }
              isDefault
              createdDate
          }
          counter {
              counterId
              count
          }
      }
    }
`;

interface IProps extends RouteComponentProps {
    keyword?: string
    category?: string
    shopType?: ShopType
}

interface IStatus {
    keyword: string
    categoryId: ShopCategory
    shopType: ShopType
}

class TemporaryShops extends React.Component<IProps, IStatus> {
    constructor(props: IProps) {
        super(props);

        const { keyword, category, shopType } = this.props.match.params as IProps;

        const categoryId = getShopCategoryId(category);

        this.state = {
            keyword: keyword || "",
            categoryId: categoryId || ShopCategory.ALL,
            shopType: shopType || ShopType.ALL
        }
    }

    public componentDidMount() {
        window.scrollTo(0,0);
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStatus>, snapshot?: any): void {
        const prevData = prevProps.match.params as IProps;
        const currData = this.props.match.params as IProps;

        if(prevData.category !== currData.category) {
            this.setState({
                categoryId: getShopCategoryId(currData.category)
            });
        }
    }

    public render() {
        const currentUser = getUserInfo();

        return (
            <div className="shop-container">
                <div className="grid-container">
                    <div className="default-grid-container">
                        <div className="header-wrapper-2">
                            <H5 className="title">Shops(Draft)</H5>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                <div className="default-grid-container">
                    <div className="shops-wrapper pad-t-1">
                        <ShopItems keyword={this.state.keyword} categoryId={this.state.categoryId} shopType={this.state.shopType} userNo={currentUser.no} />
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default TemporaryShops;

interface IShopItems {
    keyword: string
    categoryId: ShopCategory
    shopType: ShopType
    userNo?: number
}

const ShopItems = (props: IShopItems) => {
    const { keyword, categoryId, shopType, userNo } = props;

    const { loading, error, data, fetchMore, networkStatus } = useQuery(TEMPORARY_SHOPS_QUERY, {
        variables: {
            keyword,
            categoryId,
            shopType,
            userNo,
            offset: 0,
            limit: LOAD_DATA_COUNT
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: false
    });

    if(loading || networkStatus === 4) {
        return <LoadingBox key="0" />;
    }

    if(error) {
        return <ErrorPage />;
    }

    const { temporaryShops } = data || { temporaryShops: null };

    if(!temporaryShops || temporaryShops.length === 0) {
        const emptyData = {
            id: "0",
            link: {
                path: ""
            },
            title: "No results"
        } as ICardData;

        return (
            <>
                <VBox key={emptyData.id} data={emptyData} large={true} width={CARD_WIDTH} isEmpty={true} />
                <div style={{clear: "left"}} />
            </>
        );
    }

    const offset = temporaryShops ? temporaryShops.length : 0;
    const hasMore = (offset !== 0 && offset % LOAD_DATA_COUNT === 0) ? true : false;

    const loadMoreHandler = () => {
        fetchMore({
            variables: {
                offset
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                if(!fetchMoreResult) {
                    return prev;
                }

                return Object.assign({}, prev, {
                    temporaryShops: [...prev.temporaryShops, ...fetchMoreResult.temporaryShops]
                });
            }
        });
    };

    return (
        <>
            <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={loadMoreHandler}
                hasMore={hasMore}
                loader={<LoadingBox key="0" />}>
                {
                    (temporaryShops || []).map((shop: IShop) => {
                        const images = shop.shopImages.filter((shopImage: IShopImage) => {
                            return shopImage.isDefault;
                        })
                            .map((shopImage: IShopImage) => {
                                return shopImage.image;
                            });

                        const boxData = {
                            id: shop.shopId + "",
                            link: {
                                path: "/temporaryShop/" + shop.shopId,
                                localParams: {
                                    keyword,
                                    categoryId,
                                    shopType,
                                    userNo,
                                    offset: 0,
                                    limit: LOAD_DATA_COUNT
                                }
                            },
                            images,
                            date: shop.createdDate,
                            title: shop.name,
                            subTitle: shop.name,
                            subSubTitle: shop.name,
                            description: shop.description,
                            author: shop.author.displayName,
                            tags: getTags(SHOP_ENV_METAS, shop.shopEnv)
                        } as ICardData;

                        return <VBox key={boxData.id} data={boxData} large={true} width={CARD_WIDTH} />
                    })
                }
                <div style={{clear: "left"}} />
            </InfiniteScroll>
            <LoadMore loadMoreHandler={loadMoreHandler} />
        </>
    );
};

const LoadMore = ({ loadMoreHandler }: any) => {
    return (
        <div className="group-center pad-h-3">
            <Button minimal={true} onClick={loadMoreHandler} >Load more</Button>
        </div>
    );
};