import * as React from "react";

import "./Environment.css"
import {IItemEnv} from "../common/Domains";
import {ErrorPage} from "../utils/LoadingAndError";
import {H5} from "@blueprintjs/core";

interface IProps {
    env: IItemEnv
}

class Environment extends React.Component<IProps, {}> {
    public render() {
        const { env } = this.props;

        if(env) {
            return (
                <div className="environment pad-t-3">
                    <div className="main-title pad-b-1">Detail</div>
                    <div className="info-box">
                        <H5 className="title">원산지</H5>
                        <p className="value">{env.origin}</p>
                    </div>
                    <div className="info-box">
                        <H5 className="title">모델</H5>
                        <p className="value">{env.model}</p>
                    </div>
                    <div className="info-box">
                        <H5 className="title">소재</H5>
                        <p className="value">{env.material}</p>
                    </div>
                    <div className="info-box">
                        <H5 className="title">사이</H5>
                        <p className="value">{env.size}</p>
                    </div>
                    <div className="info-box">
                        <H5 className="title">구성</H5>
                        <p className="value">{env.composition}</p>
                    </div>
                    <div className="info-box">
                        <H5 className="title">제조국</H5>
                        <p className="value">{env.manufacturer}</p>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div>
                    <ErrorPage isOnlyMessage={true} />
                </div>
            )
        }
    }
}

export default Environment;