import * as React from "react";
import {H5, H6, Icon, NonIdealState} from "@blueprintjs/core";
import {distanceInWordsToNow} from "date-fns";
import {Link} from "react-router-dom";
import {Box} from "grommet";
import ContentLoader from "react-content-loader";
import Dotdotdot from "react-dotdotdot";
import {getPreviewImageIds, ICardData, ICardTags, Preview} from "./CardDomains";

import './SmallVBox.css';
import './VBox.css';
import {THUMBNAIL_SIZES} from "../common/Constant";

interface ICardProps {
    data: ICardData
    large?: boolean
    titleLine?: number
    descriptionLine?: number
    width?: number
    height?: number
    isEmpty?: boolean
}

interface ICardState {
    data: ICardData,
    large: boolean,
    titleLine: number,
    descriptionLine: number
}

class VBox extends React.Component<ICardProps, ICardState> {
    constructor(props: ICardProps) {
        super(props);

        this.state = {
            data: props.data || {},
            large: props.large || false,
            titleLine: props.titleLine || 1,
            descriptionLine: props.descriptionLine || 1
        }
    }

    public render() {
        const {
            data,
            large,
            titleLine,
            descriptionLine
        } = this.state;

        const {
            isEmpty,
            width
        } = this.props;

        if(isEmpty) {
            return <EmptyVBox data={data} large={large} titleLine={titleLine} descriptionLine={descriptionLine} width={width} />;
        }

        const images = getPreviewImageIds(data.images);
        const distanceCreatedDate = distanceInWordsToNow(data.date);

        return (
            <div className={large ? 'v-box' : 'small-v-box'}>
                <div className="img">
                    <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                        <Box className="box">
                            {!large && <Preview images={images} width={THUMBNAIL_SIZES.BASIC.WIDTH} height={THUMBNAIL_SIZES.BASIC.HEIGHT} /> }
                            {large && <Preview images={images} width={THUMBNAIL_SIZES.MIDDLE.WIDTH} height={THUMBNAIL_SIZES.MIDDLE.HEIGHT} /> }
                        </Box>
                    </Link>
                </div>
                <div className="summary">
                    {(data.subTitle || data.subSubTitle) && (
                    <H6 className="name">
                        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                            <span className="sub-title">
                                <Dotdotdot clamp={titleLine}>{data.subTitle + ' | ' + data.subSubTitle}</Dotdotdot>
                            </span>
                        </Link>
                    </H6>
                    )}
                    <H5 className="title">
                        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                            <Dotdotdot clamp={titleLine}>{data.title}</Dotdotdot>
                        </Link>
                    </H5>
                    <div className="description">
                        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
                            <Dotdotdot clamp={descriptionLine}>{data.description}</Dotdotdot>
                        </Link>
                    </div>
                    {this.props.children}
                    <ICardTags tags={data.tags} />
                    <div className="like">
                        <Icon icon="heart" iconSize={Icon.SIZE_LARGE}/>
                    </div>
                    <div className="author">by {data.author}</div>
                    <div className="date">{distanceCreatedDate}</div>
                </div>
            </div>
        );
    }
}

export default VBox;

const EmptyVBox = ({data, large, width}: ICardProps) => {
    return (
        <Link to={{pathname: data.link.path, state: data.link.localParams}}>
            <div className={large ? 'v-box' : 'small-v-box'}>
                <NonIdealState
                    title={data.title}
                    description={data.description}
                />
            </div>
        </Link>
    );
};

export const LoadVBox = ({large, width}: ICardProps) => {
    return (
        <div className={large ? 'v-box' : 'small-v-box'}>
            <ContentLoader width={400} height={595}>
                <rect x={0} y={0} rx={5} ry={5} width={400} height={320} />
                <rect x={0} y={340} rx={5} ry={5} width={250} height={15} />
                <rect x={0} y={370} rx={5} ry={5} width={400} height={30} />
                <rect x={0} y={415} rx={5} ry={5} width={280} height={20} />
                <rect x={0} y={450} rx={5} ry={5} width={100} height={20} />
                <rect x={0} y={480} rx={5} ry={5} width={80} height={20} />
                <rect x={0} y={510} rx={5} ry={5} width={120} height={20} />
                <rect x={0} y={560} rx={5} ry={5} width={100} height={20} />
            </ContentLoader>
        </div>
    );
}